import { Fragment, useEffect, useState, useMemo } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { useNavigate, useParams } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { ArrowBackIos } from "@mui/icons-material";

type Props = {};

const ScheduleDetails = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [schedule, setScheduleDetails] = useState<any>();
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);

  const [rowCount, setRowCount] = useState(0);
  const [passengersTableData, setPassengersTableData] = useState([]);
  const [isFetchingPassengers, setIsFetchingPassengers] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => `${row?.first_name} ${row?.last_name}`,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.phone_number,
        id: "number",
        header: "Phone",
      },
      {
        accessorFn: (row: any) => row?.ice_contact,
        id: "emergency",
        header: "Primary Emergency Contact",
      },
      {
        accessorFn: (row: any) => row?.minors,
        id: "minors",
        header: "Minors",
      },
      {
        accessorFn: (row: any) => row?.payment_method,
        id: "method",
        header: "Payment Method",
      },
      {
        accessorFn: (row: any) => row?.seat?.number,
        id: "set",
        header: "Seat No.",
        size: 50,
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY hh:mm a"),
        id: "enrolled",
        header: "Enrolled On",
      },
    ],
    []
  );

  const fetchDetails = async () => {
    setIsFetchingDetails(true);
    try {
      const response = await base_api_request(
        `${RouteConstants.SCHEDULES}/${id}/details`
      );
      setScheduleDetails(response?.data?.payload);

      setIsFetchingDetails(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPassengers = async () => {
    const offset = pagination.pageIndex * pagination.pageSize;
    setIsFetchingPassengers(true);
    try {
      const response = await base_api_request.get(
        `${RouteConstants.SCHEDULES}/${id}/passengers?limit=${pagination.pageSize}&offset=${offset}`
      );

      setPassengersTableData(response?.data?.payload?.items);
      setRowCount(response?.data?.payload?.total);
      setIsFetchingPassengers(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchPassengers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pagination.pageIndex, pagination.pageSize]);

  return (
    <Fragment>
      <PageLayout>
        <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
          <div className="h-full overflow-hidden">
            <div className="h-full overflow-y-auto p-[20px]">
              <button
                data-testid="backToPrev
                                "
                onClick={() => navigate(-1)}
              >
                <div className="flex gap-2 text-oya-ghana-red mb-6">
                  <ArrowBackIos />
                  <div className="text-[16px]">Back</div>
                </div>
              </button>
              <div>
                <h3 className="font-bold py-2 text-2xl">Schedule</h3>
              </div>

              {isFetchingDetails ? (
                <div className=" animate-pulse">
                  <div className="font-medium text-transparent bg-black bg-opacity-[50%] h-fit w-fit rounded-lg">
                    *********************
                  </div>
                  <div className="font-medium text-transparent bg-black bg-opacity-[50%] h-fit w-fit rounded-lg mt-2">
                    *********************
                  </div>
                </div>
              ) : (
                <div className="mt-6">
                  <div className="text-[18px]">
                    {schedule?.route?.from?.name} - {schedule?.route?.to?.name}
                  </div>
                  <div className="py-2">{schedule?.station?.name}</div>
                  <div>{schedule?.onboard_passengers} Passenger(s)</div>
                </div>
              )}
              <div className="mt-4 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={passengersTableData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  enableClickToCopy={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showColumnFilters: false,
                    showProgressBars: isFetchingPassengers,
                    pagination,
                    density: "compact",
                    columnPinning: {
                      left: ["mrt-row-expand", "mrt-row-select"],
                      right: ["mrt-row-actions"],
                    },
                    isLoading: isFetchingPassengers,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </Fragment>
  );
};

export default ScheduleDetails;
