import { useState, useEffect, useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import PageLayout from "../components/layouts/PageLayout";
import { base_api_request } from "../utils/api";
import CustomButton from "../components/CustomButton";
import { Routes } from "../types";
import { AddOutlined } from "@mui/icons-material";
import CreateRoute from "../components/TripRoute/CreateRoute";
import moment from "moment";
import { RouteConstants } from "../utils/helpers/RouteConstants";

const TripRoutes = () => {
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [routes, setRoutes] = useState<Routes[]>([]);
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const columns = useMemo<MRT_ColumnDef<Routes>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.id}`,

        header: "ID",
      },
      {
        accessorFn: (row) => `${row?.from?.name}`,

        header: "Origin",
      },
      {
        accessorFn: (row) => `${row?.from?.region?.name}`,
        header: "Origin region",
      },
      {
        accessorFn: (row) => `${row?.to?.name}`,

        header: "Destination",
      },
      {
        accessorFn: (row) => `${row?.to?.region?.name}`,
        header: "Destination region",
      },
      {
        accessorFn: (row) =>
          `${moment(row?.created_at).format("ddd MMM, YYYY - h:mm A")}`,
        header: "Created On",
      },
    ],
    []
  );

  //handleReFetch
  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  useEffect(() => {
    const getAllRoutes = async () => {
      setLoading(true);

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ROUTES}?sorting=created_at:desc&limit=${
            pagination.pageSize
          }&offset=${Number(pagination.pageIndex * pagination.pageSize)}`
        );
        // console.log(res.data?.payload?.items);
        setRoutes(res.data?.payload?.items);
        setMeta((prevData) => ({
          ...prevData,
          total: res.data?.payload?.total,
        }));
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    getAllRoutes();
  }, [pagination.pageIndex, pagination.pageSize, reFetch]);

  return (
    <PageLayout>
      <CreateRoute
        isOpen={open}
        handleClosed={() => setOpen(false)}
        handleReFetch={handleReFetch}
      />
      <div className="w-full overflow-auto mb-20 relative">
        <div className="col-span-3 mx-4 my-6 h-full">
          <div className="flex justify-between items-center mb-5">
            <h3 className="font-bold py-2 text-2xl">Trip Routes</h3>
            <CustomButton
              data-testid="createRoute"
              onClick={() => setOpen(true)}
            >
              <div className="flex gap-1 text-white items-center">
                <AddOutlined
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                <div>Create a Route</div>
              </div>
            </CustomButton>
          </div>
          <div className="grid grid-cols-1">
            <div className="w-full h-full mb-8">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "0",
                  },
                }}
                data={routes}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                manualPagination
                enableSorting={false}
                columns={columns}
                rowCount={meta?.total}
                state={{ pagination, isLoading: loading }}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                }}
                enableColumnResizing
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    console.log(row.original);
                    // func.redirect(`/hsh/${row.original?.id}`);
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                onPaginationChange={setPagination}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default TripRoutes;
