import { useEffect, useState } from "react";

import { Dropdown, notification } from "antd";

import { getCookie, logout } from "../utils/functions";
import type { MenuProps } from "antd";
import Skeleton from "react-loading-skeleton";
import { ACCOUNTS_MS_URL } from "../utils/constants";
import axios from "axios";

const UserDropdown = () => {
    const user = getCookie("oya_user_info");
    const parsedUser = user ? JSON.parse(user) : null;
    const token = getCookie("oya_token");
    const [visible, setVisible] = useState(false);

    const [data, setData] = useState({
        name: "",
        image: "",
        phone: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const items: MenuProps["items"] = [
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                >
                    <path
                        d="M1 20C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V4H14V2H2V18H14V16H16V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1ZM14 14V11H7V9H14V6L19 10L14 14Z"
                        fill="#7C0000"
                    />
                </svg>
            ),
            onClick: () => {
                // handleLogout();
                logout();
                // dispatch(logout());
            },
            label: (
                <button
                    data-testid="logout"
                    className="pl-4 text-oya-ghana-header-h7 font-medium text-black"
                >
                    Logout
                </button>
            ),
            key: "1",
        },
    ];

    //get user data
    const getUserData = async () => {
        setIsLoading(true);
        try {
            const res = await axios.get(`${ACCOUNTS_MS_URL}/v1/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setData(res.data?.payload);
            setIsLoading(false);
            //return res;
        } catch (e) {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (!parsedUser) {
            notification.error({
                message: "User info not found. Please log in",
            });
            logout();
        } else {
            getUserData();
        }
        // eslint-disable-next-line
    }, []);
    return (
        <div className="flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl">
            <Dropdown
                trigger={["click"]}
                menu={{ items }}
                overlayStyle={{
                    boxShadow: "#1d2a9f 0px 1px 4px",
                    position: "relative",
                    width: 256,
                    borderRadius: 8,
                }}
                onOpenChange={() => setVisible(!visible)}
                placement="bottomRight"
                arrow
            >
                {isLoading ? (
                    <Skeleton circle={true} width={30} height={30} />
                ) : (
                    <button
                        data-testid="user-profile-button"
                        type="button"
                        className="flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl"
                    >
                        <div className="flex items-center user">
                            {data?.image ? (
                                <img
                                    src={data?.image}
                                    className="appear rounded-full object-cover w-12 h-12 md:w-14 md:h-14"
                                    alt="User"
                                />
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-12 h-12 md:w-14 md:h-14 text-oya-ghana-green"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            )}

                            <div className="text-left pl-2 pr-4 leading-snug hidden md:block">
                                <h5
                                    data-testid="user-name"
                                    className=" font-medium"
                                >
                                    {data?.name || (
                                        <div className="appear animate-pulse h-5 w-40 bg-white rounded" />
                                    )}
                                </h5>
                                <div
                                    data-testid="user-phone"
                                    className=" font-normal"
                                >
                                    {data?.phone || (
                                        <div className="appear animate-pulse h-5 mt-2 w-24 bg-white rounded" />
                                    )}
                                </div>
                            </div>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="10"
                                viewBox="0 0 18 10"
                                stroke="currentColor"
                                className="w-4 h-4 text-oya-ghana-green md:mb-4"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 6.18373L16.0003 0.661255L18 2.23878L9 9.33879L0 2.23878L1.99969 0.661255L9 6.18373Z"
                                />
                            </svg>
                        </div>
                    </button>
                )}
            </Dropdown>
        </div>
    );
};

export default UserDropdown;
