import { useState, useEffect, useMemo } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
// import { Input } from 'antd';
import { base_api_request } from "../../utils/api";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import LoadingPointBookingFilter from "../../components/Branches/LoadingPointBookingFilter";
import { LoadingPointBookingType } from "./branch";

interface Filter {
  f: string;
  o: string;
  p: string[] | number[] | boolean[];
  r?: string;
}

const LoadingPointBookings = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [bookings, setBookings] = useState<LoadingPointBookingType[]>([]);
  const [loading, setLoading] = useState(false);
  const [ticketOwner, setTicketOwner] = useState<number | undefined>();

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  //for filter drawer
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const handleOpenFilter = () => {
    setOpenFilter(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(true);
  };

  const [defaultDates, setDefaultDates] = useState<any>([
    startOfDay(new Date()),
    endOfDay(new Date()),
  ]);

  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;
      const adjustedEndDate = endOfDay(endDate);
      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);
      setDefaultDates([startDate, endDate]);
      setPagination({ pageIndex: 0, pageSize: 10 });
    }
  };

  const columns = useMemo<MRT_ColumnDef<LoadingPointBookingType>[]>(
    () => [
      {
        accessorFn: (row) =>
          `${row?.route?.from?.name} - ${row?.route?.to?.name} `,

        header: "Route",
      },
      {
        accessorFn: (row) =>
          `${row?.loading_point?.from?.name} - ${
            row?.loading_point?.to?.name
          } ${
            row?.loading_point?.label ? `(${row?.loading_point?.label})` : ""
          }`,

        header: "Loading point",
      },
      {
        accessorFn: (row) => `${row?.branch?.name}`,
        size: 80,
        header: "Branch",
      },
      {
        accessorFn: (row) => `${row?.creator?.name}`,
        size: 80,
        header: "Booked by",
      },
      {
        accessorFn: (row) => `${row?.currency}${row?.amount_payable}`,
        size: 80,
        header: "Amount payable",
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Booked On",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell.getValue()).format("LLL")}</Box>
        ),
      },
    ],
    []
  );

  const getBookings = async () => {
    setLoading(true);
    let defaultFilters: Filter[] = [];

    try {
      if (defaultDates) {
        defaultFilters = [
          ...defaultFilters,
          {
            f: "created_at",
            o: "between",
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          },
        ];
      }

      if (selectedBranch) {
        defaultFilters.push({
          f: "branch.id",
          o: "=",
          p: [Number(selectedBranch)],
        });
      }

      if (ticketOwner) {
        defaultFilters.push({
          f: "creator.id",
          o: "=",
          p: [ticketOwner],
        });
      }
      const res = await base_api_request.get(
        `${
          RouteConstants.LOADING_POINT_BOOKINGS
        }?&sorting=created_at:desc&limit=${pagination.pageSize}&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&filters=${JSON.stringify(defaultFilters)}`
      );
      setBookings(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBookings();

    // eslint-disable-next-line
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    ticketOwner,
    selectedBranch,
    defaultDates,
  ]);
  return (
    <PageLayout>
      <div className="overflow-hidden h-screen">
        <div
          className={`${
            openFilter ? "" : "grid grid-cols-4"
          }  h-full overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="my-4">
              <div className="flex justify-between items-center mb-5">
                <h3 className="font-bold py-2 text-2xl">
                  Loading point bookings
                </h3>
                <ChevronDoubleLeftIcon
                  className={`${
                    openFilter ? "block" : "block lg:hidden"
                  } h-5 w-5 cursor-pointer`}
                  onClick={handleOpenFilter}
                />
              </div>
            </div>

            <div className="grid grid-cols-1">
              <div className="w-full h-full mb-8">
                <MaterialReactTable
                  muiTablePaperProps={{
                    sx: {
                      borderRadius: "0",
                    },
                  }}
                  data={bookings}
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableMultiRowSelection={false}
                  enableRowSelection={false}
                  manualPagination
                  enableSorting={false}
                  columns={columns}
                  rowCount={meta?.total}
                  state={{
                    pagination,
                    isLoading: loading,
                  }}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  enableColumnResizing
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      console.log(row.original);
                      // func.redirect(`/hsh/${row.original?.id}`);
                      //redirect(`/branch/${row.original?.id}`);
                    },
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  onPaginationChange={setPagination}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              openFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full overflow-y-auto`}
          >
            <LoadingPointBookingFilter
              onHide={handleCloseFilter}
              handleBranchChange={(value) => {
                setSelectedBranch(value);
              }}
              onCreatorChange={(value) => {
                setTicketOwner(value);
              }}
              listType={1}
              handleStatusChange={() => {}}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default LoadingPointBookings;
