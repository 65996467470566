import { useState, useEffect, useMemo } from "react";
import PageLayout from "../components/layouts/PageLayout";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
// import { Input } from 'antd';
import { base_api_request } from "../utils/api";
//import { redirect } from '../utils/functions';
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from "date-fns";
import { Ticket } from "../types";
import moment from "moment";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import TicketsFilter from "../components/shared/ticketsFilter";

interface Filter {
  f: string;
  o: string;
  p: string[] | number[] | boolean[];
  r?: string;
}

const Tickets = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [ticket, setTicket] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(false);
  const [paymentStat, setPaymentStat] = useState<string | undefined>("");
  const [paymentMethod, setPaymentMethod] = useState<string | undefined>("");
  const [ticketOwner, setTicketOwner] = useState<number | undefined>();
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfWeek(new Date()),
    endOfWeek(new Date()),
  ]);
  const [isMidRoute, setMidRoute] = useState<boolean>(true);

  const [searchRoute, setSearchRoute] = useState("");

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  //for filter drawer
  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter = () => {
    setOpenFilter(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(true);
  };

  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates(null);
    } else if (selectedDate instanceof Array && selectedDate?.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  const columns = useMemo<MRT_ColumnDef<Ticket>[]>(
    () => [
      //   {
      //     id: 'icons',
      //     header: '',
      //     Cell: () => (
      //       <Box>
      //         <LaunchOutlined
      //           sx={{
      //             width: 20,
      //             height: 20,
      //             color: '#7C0000',
      //           }}
      //         />
      //       </Box>
      //     ),
      //     size: 50,
      //   },
      {
        accessorFn: (row) => `${row?.from?.name} - ${row?.to?.name} `,

        header: "Route",
      },

      {
        accessorFn: (row) => `${row?.bus.reg_number}`,
        size: 100,
        header: "Bus Reg. Number",
      },
      {
        accessorFn: (row) => `${row?.station?.name}`,
        size: 80,
        header: "Branch",
      },
      {
        accessorFn: (row) => `${row?.schedule?.mid_route}`,
        size: 80,
        header: "Mid-Route",
        Cell: ({ cell }: any) => (
          <Box>
            {cell.getValue() === "false" ? (
              <div className="px-3 py-1 w-fit rounded-[30px] text-blue-800 bg-blue-500 bg-opacity-[10%]">
                No
              </div>
            ) : (
              <div className="px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]">
                Yes
              </div>
            )}
          </Box>
        ),
      },
      {
        accessorFn: (row) =>
          `${
            row?.owner?.name === undefined
              ? row?.special_user?.name
              : row?.owner?.name
          }`,
        header: "Owner",
      },
      {
        accessorFn: (row) => `${row?.amount_payable?.amount}`,
        size: 80,
        Cell: ({ cell }: any) => {
          return (
            <Box>
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "GHS",
              }).format(Number(cell?.getValue()))}
            </Box>
          );
        },
        header: "Amount Payable",
      },
      {
        accessorFn: (row) => `${row?.manifest?.payment_method}`,
        size: 100,
        header: "Payment Method",
        Cell: ({ cell }: any) => (
          <Box>{cell.getValue() === "null" ? "N/A" : cell.getValue()}</Box>
        ),
      },
      {
        accessorFn: (row: any) =>
          row?.payment_status?.name === "PAID" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]">
              {row?.payment_status?.name}
            </div>
          ) : row?.payment_status?.name === "PENDING" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-blue-800 bg-blue-500 bg-opacity-[10%]">
              {row?.payment_status?.name}
            </div>
          ) : (
            <div className="px-3 py-1 w-fit rounded-[30px] text-red-800 bg-red-500 bg-opacity-[10%]">
              {row?.payment_status?.name}
            </div>
          ),
        size: 80,
        header: "Payment Status",
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Booked On",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell.getValue()).format("LLL")}</Box>
        ),
      },
    ],
    []
  );

  const getTickets = async () => {
    setLoading(true);
    let defaultFilters: Filter[] = [];

    defaultFilters.push({
      f: "schedule.mid_route",
      o: "=",
      p: [isMidRoute],
    });

    if (defaultDates) {
      defaultFilters.push({
        f: "created_at",
        o: "between",
        p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
      });
    }

    try {
      let value: string[] = [];

      switch (paymentMethod) {
        case "MOBILE_MONEY":
          value = ["VODAFONE_GH", "MTN_GH", "TIGO_GH"];
          break;
        case "CASH":
          value = ["CASH_GH"];
          break;
        default:
          break;
      }
      if (value.length) {
        defaultFilters.push({
          f: "manifest.payment_method",
          o: "in",
          p: value,
        });
      }

      if (paymentStat) {
        defaultFilters.push({
          f: "payment_status.code",
          o: "contains",
          p: [`${paymentStat}`],
        });
      }
      if (searchRoute) {
        defaultFilters.push(
          {
            f: "from.id",
            o: "=",
            p: [`${searchRoute}`],
            r: "OR",
          },
          {
            f: "to.id",
            o: "=",
            p: [`${searchRoute}`],
          }
        );
      }
      if (ticketOwner) {
        defaultFilters.push({
          f: "owner.id",
          o: "=",
          p: [ticketOwner],
        });
      }
      const res = await base_api_request.get(
        `${RouteConstants.TICKETS}?limit=${pagination.pageSize}&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&filters=${JSON.stringify(defaultFilters)}&sorting=created_at:desc`
      );
      setTicket(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  const resetFilters = async () => {
    setPaymentStat(undefined);
    setPaymentMethod(undefined);
    setDefaultDates([startOfDay(new Date()), endOfDay(new Date())]);
    setSearchRoute("");
    setTicketOwner(undefined);
    setPagination({
      pageIndex: 0,
      pageSize: 12,
    });
    //setMidRoute(true);
    setMeta({
      offset: 0,
      page: 1,
      limit: 12,
      total_pages: null,
      total: 0,
    });
  };

  useEffect(() => {
    getTickets();

    // eslint-disable-next-line
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    paymentStat,
    paymentMethod,
    defaultDates,
    searchRoute,
    isMidRoute,
    ticketOwner,
  ]);
  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            openFilter ? "" : "grid grid-cols-4"
          }  h-full overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center mb-5">
              <h3 className="font-bold py-2 text-2xl">Tickets</h3>
              <div>
                <button onClick={() => handleOpenFilter()}>
                  <ChevronDoubleLeftIcon
                    className={`${
                      openFilter ? "block" : "block lg:hidden"
                    } h-5 w-5 cursor-pointer`}
                  />
                </button>
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div className="w-full h-full mb-8">
                <MaterialReactTable
                  muiTablePaperProps={{
                    sx: {
                      borderRadius: "0",
                    },
                  }}
                  data={ticket}
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableMultiRowSelection={false}
                  enableRowSelection={false}
                  manualPagination
                  enableSorting={false}
                  columns={columns}
                  rowCount={meta?.total}
                  state={{ pagination, isLoading: loading }}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  enableColumnResizing
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      console.log(row.original);
                      // func.redirect(`/hsh/${row.original?.id}`);
                      //redirect(`/branch/${row.original?.id}`);
                    },
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  onPaginationChange={setPagination}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              openFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-100 overflow-y-auto pb-[4rem]`}
          >
            <TicketsFilter
              handleFilterClose={handleCloseFilter}
              defaultDates={defaultDates}
              handleCalendarChange={handleCalendarChange}
              isMidRoute={isMidRoute}
              onMidRouteChange={() => setMidRoute(!isMidRoute)}
              onTicketOwnerChnage={(e) => setTicketOwner(e?.value)}
              onPayMethodChange={(e) => setPaymentMethod(e?.value)}
              onPayStatsChange={(e) => setPaymentStat(e?.value)}
              onRouteChange={(e: any) => setSearchRoute(e?.value)}
              handleReset={resetFilters}
            />
          </div>
        </div>
      </div>

      {/* filter drawer */}
    </PageLayout>
  );
};

export default Tickets;
