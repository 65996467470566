import React, { memo } from 'react';

const Loader = ({
  float = false,
  ready = false,
  bgColor = 'bg-black/5',
  width = 'w-10',
  height = 'h-10',
}: {
  float?: boolean;
  ready: boolean;
  bgColor?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <div
      className={`text-oya-ghana-red flex justify-center items-center w-full h-full ${
        float ? 'absolute inset-0' : ''
      } ${bgColor} ${ready ? 'disappear' : ''}`}
    >
      <div
        className={`${width} ${height} animate-spin appear border-2 border-[#7c0000] border-t-gray-100 rounded-full ${
          ready && 'disappear'
        }`}
      />
    </div>
  );
};

export default memo(Loader);
