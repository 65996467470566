import { useEffect, useState } from "react";
import { Drawer, Form } from "antd";
import Select from "react-select";
import CustomButton from "../CustomButton";
import { Midroute } from "../../types";
import {
    getPickupPoints,
    getLoadingPoints,
} from "../../utils/helpers/commonApiCalls";
// import AsyncSelect from 'react-select/async';
import { Option, Pickup, LoadingPoint } from "../../types";

type Props = {
    midroute: Midroute | any;
    isOpen: boolean;
    handleClosed: () => void;
    handleSubmitting: (value: boolean) => void;
    updateMidroute: (values: any) => void;
};

const EditMidroute = (props: Props) => {
    const [form] = Form.useForm();
    const [routeOptions, setRouteOptions] = useState<Option[]>([]);
    const [loadingPointOptions, setLoadingPointOptions] = useState<Option[]>(
        []
    );

    const submit = async () => {
        const values = form.getFieldsValue();

        props.updateMidroute(values);
    };

    useEffect(() => {
        form.setFieldsValue(props.midroute);
        form.setFieldValue("route_id", {
            value: props.midroute?.route?.id,
            label: `${props.midroute?.route?.source?.name} - ${props.midroute?.route?.destination?.name}`,
        });

        form.setFieldValue("pickup_id", {
            value: props.midroute?.route?.id,
            label: `${props.midroute?.pickup?.name}`,
        });
    }, [props.midroute, form]);

    useEffect(() => {
        //console.log(props.midroute);
        getLoadingPoints()
            .then((res) => {
                setLoadingPointOptions(
                    res.payload?.items?.map((item: LoadingPoint) => ({
                        label: `${item?.source?.name} - ${item?.destination?.name}`,
                        value: item?.id,
                    }))
                );
            })
            .catch((e) => console.log(e));

        getPickupPoints()
            .then((res) => {
                setRouteOptions(
                    res.payload?.items?.map((item: Pickup) => ({
                        label: item?.name,
                        value: item?.id,
                    }))
                );
            })
            .catch((e) => console.log(e));
    }, []);

    return (
        <div>
            <Drawer
                title="Edit Midroute"
                placement="right"
                onClose={props.handleClosed}
                open={props.isOpen}
                zIndex={100}
            >
                <div>
                    <Form form={form} layout="vertical" autoComplete="off">
                        <Form.Item
                            className="w-full"
                            label="Select Station Route"
                            name="route_id"
                        >
                            <Select
                                //loadOptions={loadRouteOptions}
                                defaultValue={{
                                    value: props.midroute?.route?.id,
                                    label: `${props.midroute?.route?.source?.name} - ${props.midroute?.route?.destination?.name}`,
                                }}
                                isSearchable
                                isClearable
                                options={loadingPointOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            className="w-full"
                            label="Select Pickup Point"
                            name="pickup_id"
                        >
                            <Select
                                //loadOptions={loadPickupOptions}
                                defaultValue={{
                                    value: props.midroute?.pickup?.id,
                                    label: props.midroute?.pickup?.name,
                                }}
                                isSearchable
                                isClearable
                                options={routeOptions}
                            />
                        </Form.Item>

                        <div className="flex gap-2 justify-end items-end w-full">
                            <CustomButton
                                data-testid="updateMidRoute"
                                onClick={submit}
                            >
                                <div>Update</div>
                            </CustomButton>
                            <CustomButton
                                data-testid="cancelNewMidRoute"
                                onClick={props.handleClosed}
                                variant="secondary"
                            >
                                <div>Cancel</div>
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </Drawer>
        </div>
    );
};

export default EditMidroute;
