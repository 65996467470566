import React, { useState, useMemo, useEffect } from "react";
import PageLayout from "../components/layouts/PageLayout";
import CustomButton from "../components/CustomButton";
// import { FunnelIcon } from "@heroicons/react/24/outline";
import {
  AddOutlined,
  EditNoteOutlined,
  DeleteForever,
} from "@mui/icons-material";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { base_api_request } from "../utils/api";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import { notification } from "antd";
import CreateLuggageSize from "../components/createLuggageSize";
import EditLuggageSize from "../components/editLuggageSize";

type LuggageType = {
  code: string;
  name: string;
  description: string;
};

const LuggageSizes = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [editLuggage, setEditLuggage] = useState<boolean>(false);

  const [reload, setReload] = useState(false);

  const [luggageSizes, setLuggageSizes] = useState<LuggageType[]>([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const luggageColumns = useMemo<MRT_ColumnDef<LuggageType>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.code}`,
        size: 100,
        header: "Luggage size Code",
      },
      {
        accessorFn: (row) => `${row?.name}`,

        header: "Name",
      },
      {
        accessorFn: (row) => `${row?.description}`,

        header: "Description",
      },
    ],

    []
  );

  const getLuggageSizes = async () => {
    setLoading(true);
    try {
      const { data } = await base_api_request.get(RouteConstants.LUGGAGE_SIZE);

      setLuggageSizes(data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: data?.payload?.total,
      }));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteLuggageSizes = async (code: string) => {
    setLoading(true);
    try {
      await base_api_request.delete(`${RouteConstants.LUGGAGE_SIZE}/${code}`);

      setReload(!reload);

      notification.success({
        message: "Luggage size deleted",
      });
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLuggageSizes();

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, reload]);

  return (
    <PageLayout>
      <div className="col-span-3 mx-4 my-6 h-full">
        <div className="w-full overflow-auto mb-8 relative">
          <div className="flex justify-between items-center my-4">
            <h3 className="font-bold py-2 text-2xl">Luggage sizes</h3>

            <div className="flex gap-3">
              {/* <CustomButton onClick={() => {}}>
                <div className="flex gap-1 text-white items-center">
                  <FunnelIcon className="h-5 w-5" />
                  Filter
                </div>
              </CustomButton> */}

              <CustomButton
                data-testid="createLuggage"
                onClick={() => setOpen(true)}
              >
                <div className="flex gap-1 text-white items-center">
                  <AddOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <div>Create luggage size</div>
                </div>
              </CustomButton>
            </div>
          </div>

          <div className="grid grid-cols-1">
            <MaterialReactTable
              muiTablePaperProps={{
                sx: {
                  borderRadius: "0",
                },
              }}
              enableRowActions={true}
              renderRowActionMenuItems={({ closeMenu, row }) => [
                <MenuItem
                  // disabled={
                  //   row.original?.status === "SCALED" ||
                  //   row.original?.status === "LOADING" ||
                  //   row.original?.status === "LOADED"
                  // }
                  key={1}
                  onClick={() => {
                    //setSelectedLuggageSize(row.original);
                    localStorage.setItem("lsz", JSON.stringify(row.original));

                    setEditLuggage(true);
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <EditNoteOutlined
                      sx={{
                        color: "blue",
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "blue",
                    }}
                  >
                    Edit
                  </Typography>
                </MenuItem>,
                <MenuItem
                  key={2}
                  onClick={() => {
                    deleteLuggageSizes(row.original?.code);

                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <DeleteForever
                      sx={{
                        color: "red",
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "red",
                    }}
                  >
                    Delete
                  </Typography>
                </MenuItem>,
              ]}
              data={luggageSizes}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              enableTopToolbar={false}
              manualPagination
              enableSorting={false}
              columns={luggageColumns}
              rowCount={meta?.total}
              state={{ pagination, isLoading: loading }}
              initialState={{
                showColumnFilters: false,
                density: "compact",
                columnPinning: {
                  left: ["mrt-row-expand", "mrt-row-select"],
                  right: ["mrt-row-actions"],
                },
              }}
              enableColumnResizing
              onPaginationChange={setPagination}
            />
          </div>
        </div>
      </div>
      <CreateLuggageSize
        isOpen={open}
        handleClosed={() => {
          setOpen(false);
          setReload(!reload);
        }}
      />
      <EditLuggageSize
        isOpen={editLuggage}
        handleClosed={() => {
          setEditLuggage(false);
          setReload(!reload);
        }}
      />
    </PageLayout>
  );
};

export default LuggageSizes;
