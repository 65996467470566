import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import CustomButton from "../CustomButton";
import { Input, Form, Drawer, notification, Spin, message } from "antd";
import AsyncSelect from "react-select/async";
import PhoneInput, { Value } from "react-phone-number-input";
import { Company, Option, Region } from "../../types";
import { base_api_request } from "../../utils/api";
import Select from "react-select";
import { getCompanies, getRegions } from "../../utils/helpers/commonApiCalls";
import { ProviderOptions } from "../../utils/constants";
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";
import { GOOGLE_KEY } from "../../utils/constants";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { getBase64 } from "../../utils/functions";
import axios from "axios";
import { debounce } from "lodash";

interface modalProps {
    isEditOpened: boolean;
    handleClosed: () => void;
    dataToEdit: any;
    handleReFetch: () => void;
}

const EditBranch = ({
    isEditOpened,
    handleClosed,
    dataToEdit,
    handleReFetch,
}: modalProps) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [branchName, setBranchName] = useState("");
    const [branchCode, setBranchCode] = useState("");
    const [branchEmail, setBranchEmail] = useState("");
    const [phone, setPhone] = useState<Value | undefined>("");
    const [momo, setMomoNumber] = useState<Value | undefined>("");
    const [location, setLocation] = useState("");
    const [lat, setLatitude] = useState<any>("");
    const [lng, setLongitude] = useState<any>("");
    const [regions, setRegions] = useState<Option[]>([]);
    const [companies, setCompanies] = useState<Option[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<number>(0);
    const [selectedRegion, setSelectedRegion] = useState<number>(0);
    const [network, setNetwork] = useState("");
    const [branchAlias, setBranchAlias] = useState("");
    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const [img, setImg] = useState<any>();

    //on page load
    useEffect(() => {
        setPhone(dataToEdit?.phone);
        setMomoNumber(dataToEdit?.momo);
        setBranchName(dataToEdit?.name);
        setBranchCode(dataToEdit?.code);
        setBranchEmail(dataToEdit?.email);
        setLocation(dataToEdit?.location);
        setLatitude(dataToEdit?.latitude);
        setLongitude(dataToEdit?.longitude);
        setNetwork(dataToEdit?.network || "");
        setBranchAlias(dataToEdit?.alias || "");
        setSelectedCompany(dataToEdit?.bus_company?.id);
        setSelectedRegion(dataToEdit?.region?.id);
        getRegions()
            .then((res) => {
                setRegions(
                    res.payload?.items?.map((items: Region) => ({
                        value: items?.id,
                        label: items?.name,
                    }))
                );
            })
            .catch((e) => console.log(e));
        getCompanies()
            .then((res) => {
                setCompanies(
                    res.payload?.items?.map((items: Company) => ({
                        value: items?.id,
                        label: items?.name,
                    }))
                );
            })
            .catch((e) => console.log(e));
    }, [dataToEdit]);

    const handlePlaceSelect = (place: any) => {
        setLocation(place?.label);
        geocodeByAddress(place?.label)
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                // console.log('Successfully got latitude and longitude', { lat, lng });
                setLatitude(lat.toString());
                setLongitude(lng.toString());
            });
    };

    const loadRegionOptions = debounce(
        async (inputValue: string, callback: (options: Option[]) => void) => {
            let filterData = [
                {
                    f: "name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_REGIONS}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const searchOptions = res.data?.payload?.items?.map(
                    (item: Region) => ({
                        label: item?.name,
                        value: item?.id,
                    })
                );

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    const loadCompanyOptions = debounce(
        async (inputValue: string, callback: (options: Option[]) => void) => {
            let filterData = [
                {
                    f: "name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_COMPANIES}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const searchOptions = res.data?.payload?.items?.map(
                    (item: Company) => ({
                        label: item?.name,
                        value: item?.id,
                    })
                );

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    //editBranch
    const submit = async () => {
        try {
            setLoading(true);
            if (img) {
                try {
                    const response = await base_api_request.post(
                        `${RouteConstants.ALL_BRANCHES}/${dataToEdit?.id}/images/signed-backdrop-upload`,
                        {
                            mime: img?.type,
                        }
                    );
                    await axios.put(response.data?.payload?.upload_url, img, {
                        headers: {
                            "Content-Type": img.type,
                        },
                    });
                } catch (err) {}
            }
            await base_api_request.put(
                `${RouteConstants.STATION}/${dataToEdit?.id}`,
                {
                    // ...values,
                    bus_company_id: selectedCompany,
                    region_id: selectedRegion,
                    phone: phone,
                    momo_number: momo,
                    location: location,
                    longitude: lng,
                    latitude: lat,
                    code: branchCode,
                    network: network,
                    name: branchName,
                    alias: branchAlias,
                    email: branchEmail,
                }
            );
            setLoading(false);
            notification.success({
                message: "Branch edited successfully",
            });
            handleClosed();
            handleReFetch();
        } catch (e: any) {
            notification.error({
                message: e?.response?.data?.message,
            });
            setLoading(false);
            console.log(e);
        }
    };

    const handleBackdropImageChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (allowedTypes.includes(file.type)) {
                if (isLt2M) {
                    setImg(file);
                    getBase64(file, (imgUrl: string) => {
                        setImageUrl(imgUrl);
                    });
                } else {
                    message.error("Image must be smaller than 2MB!");
                }
            } else {
                message.error(
                    "Please select a valid image file (jpg, jpeg, or png)."
                );
            }
        }
    };

    return (
        <>
            <Drawer
                title="Edit Branch"
                placement="right"
                onClose={handleClosed}
                open={isEditOpened}
                zIndex={100}
                size="default"
            >
                <Spin spinning={loading}>
                    <div className="">
                        <div className="h-screen">
                            <Form
                                form={form}
                                layout="vertical"
                                autoComplete="none"
                            >
                                <div>
                                    {imageUrl ? (
                                        <img src={imageUrl} alt="" />
                                    ) : (
                                        <>
                                            {dataToEdit?.backdrop_image_url ? (
                                                <img
                                                    src={
                                                        dataToEdit?.backdrop_image_url
                                                    }
                                                    alt=""
                                                />
                                            ) : (
                                                <div className="img-upload">
                                                    <div className="text-center flex justify-center py-5">
                                                        <img
                                                            src={require("../../images/Vector.png")}
                                                            alt="Upload"
                                                        />
                                                    </div>

                                                    <div className="text-center">
                                                        <p className="pb-2 text-oya-ghana-header-h6 font-semibold">
                                                            Drop your file here
                                                            or <br /> click to
                                                            upload
                                                        </p>
                                                        <p className="text-oya-ghana-header-h7 font-bold">
                                                            PNG, JPEG format
                                                            only. <br /> Maximum
                                                            file size 2MB.
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <input
                                        onChange={handleBackdropImageChange}
                                        type="file"
                                        className="absolute inset-0 w-full h-full cursor-pointer"
                                        style={{ opacity: 0 }}
                                    />
                                    <Form.Item
                                        className="w-full"
                                        label="Bus Company"
                                        name="bus_company_id"
                                        required
                                    >
                                        <AsyncSelect
                                            loadOptions={(
                                                inputValue,
                                                callback
                                            ) => {
                                                loadCompanyOptions(
                                                    inputValue,
                                                    callback
                                                );
                                            }}
                                            onChange={(e: any) => {
                                                setSelectedCompany(e?.value);
                                            }}
                                            isSearchable
                                            isClearable
                                            defaultOptions={companies}
                                            defaultValue={{
                                                label: dataToEdit?.bus_company
                                                    ?.name,
                                                value: dataToEdit?.bus_company
                                                    ?.id,
                                            }}
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        required
                                        className="w-full"
                                        label="Region"
                                        name="region_id"
                                    >
                                        <AsyncSelect
                                            loadOptions={(
                                                inputValue,
                                                callback
                                            ) => {
                                                loadRegionOptions(
                                                    inputValue,
                                                    callback
                                                );
                                            }}
                                            onChange={(e: any) => {
                                                setSelectedRegion(e?.value);
                                            }}
                                            isSearchable
                                            isClearable
                                            defaultOptions={regions}
                                            defaultValue={{
                                                label: dataToEdit?.region?.name,
                                                value: dataToEdit?.region?.id,
                                            }}
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        required
                                        className="w-full"
                                        label="Branch Name"
                                    >
                                        <Input
                                            size="large"
                                            placeholder="name"
                                            value={branchName}
                                            onChange={(e) =>
                                                setBranchName(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        required
                                        className="w-full"
                                        label="Branch Alias"
                                    >
                                        <Input
                                            size="large"
                                            placeholder="alias"
                                            value={branchAlias}
                                            onChange={(e) =>
                                                setBranchAlias(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        required
                                        className="w-full"
                                        label="Specify the Branch code"
                                    >
                                        <Input
                                            size="large"
                                            placeholder="code"
                                            value={branchCode}
                                            onChange={(e) =>
                                                setBranchCode(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        required
                                        className="w-full"
                                        label="Branch Phone"
                                    >
                                        <PhoneInput
                                            name="phone"
                                            className="phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                                            defaultCountry="GH"
                                            value={phone}
                                            onChange={(e) => {
                                                setPhone(e);
                                            }}
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        required
                                        className="w-full"
                                        name="network"
                                        label="MOMO Provider"
                                    >
                                        <Select
                                            options={ProviderOptions}
                                            onChange={(e: any) =>
                                                setNetwork(e?.value)
                                            }
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        className="w-full"
                                        label="Branch Momo"
                                        required
                                    >
                                        <PhoneInput
                                            name="phone"
                                            className="phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                                            defaultCountry="GH"
                                            value={momo}
                                            onChange={(e) => {
                                                setMomoNumber(e);
                                            }}
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        required
                                        className="w-full"
                                        label="Branch Email"
                                    >
                                        <Input
                                            size="large"
                                            placeholder="email@domain.com"
                                            value={branchEmail}
                                            onChange={(e) =>
                                                setBranchEmail(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        className="w-full"
                                        name="location"
                                        required
                                        label="Branch Location"
                                    >
                                        <GooglePlacesAutocomplete
                                            apiKey={GOOGLE_KEY}
                                            selectProps={{
                                                onChange: handlePlaceSelect,
                                                defaultValue: {
                                                    label: dataToEdit?.location,
                                                    value: dataToEdit?.location,
                                                },
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </Form>
                            <div className="flex w-full justify-end items-end mt-5 pb-5">
                                <div className="flex items-center gap-3">
                                    <CustomButton
                                        data-testid="submitNewBranch"
                                        disabled={loading}
                                        onClick={submit}
                                    >
                                        Submit
                                    </CustomButton>
                                    <Button
                                        data-testid="closeNewBranch"
                                        disabled={loading}
                                        variant="outlined"
                                        size="sm"
                                        onClick={handleClosed}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Drawer>
        </>
    );
};

export default EditBranch;
