import { useState, useEffect, useMemo } from "react";
import PageLayout from "../components/layouts/PageLayout";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { type Branch } from "./branches/branch";
import { Box } from "@mui/material";
import { base_api_request } from "../utils/api";
import { redirect } from "../utils/functions";
import AsyncSelect from "react-select/async";
import { LaunchOutlined, AddOutlined, EditOutlined } from "@mui/icons-material";
import CustomButton from "../components/CustomButton";
import CreateBranch from "../components/Branches/CreateBranch";
import { Company, Option } from "../types";
import { debounce } from "lodash";
import EditBranch from "../components/Branches/EditBranch";
import { Input } from "antd";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import { getCompanies } from "../utils/helpers/commonApiCalls";

const Branches = () => {
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [branches, setBranches] = useState<Branch[]>([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Option[]>([]);
  //const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [selectCompany, setSelectedCompany] = useState("");
  const [reFetch, setReFetch] = useState(false);
  const [branchInput, setBranchInput] = useState("");

  //for edit modal
  const [openEdit, setOpenEdit] = useState(false);
  const [dataToEdit, setDataToEdit] = useState<any>(null);
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  //table column
  const columns = useMemo<MRT_ColumnDef<Branch>[]>(
    () => [
      {
        id: "icons",
        header: "",
        accessorFn: (row) => (
          <button
            data-testid="viewDetails"
            onClick={() => handleViewDetails(row)}
          >
            <LaunchOutlined
              sx={{
                width: 20,
                height: 20,
                color: "#7C0000",
              }}
            />
          </button>
        ),
        size: 50,
      },
      {
        accessorFn: (row) => `${row?.code}`,
        size: 80,
        header: "Branch code",
      },
      {
        accessorFn: (row) => `${row?.name}`,

        header: "Branch name",
      },
      {
        accessorFn: (row) => `${row?.location}`,
        header: "Location",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{cell?.getValue() === "null" ? "N/A" : cell?.getValue()}</Box>
        ),
      },

      {
        accessorFn: (row) => `${row?.bus_company?.name}`,

        header: "Bus company",
      },
      {
        accessorFn: (row) => `${row?.momo ?? ""}`,

        header: "Branch momo number",
      },
      {
        accessorFn: (row) => `${row?.phone}`,

        header: "Branch mobile number",
      },
      {
        header: "Action",
        accessorFn: (row) => (
          <button
            data-testid="editBranch"
            onClick={() => handleEditBranch(row)}
          >
            <EditOutlined
              sx={{
                width: 20,
                height: 20,
                color: "#7C0000",
              }}
            />
          </button>
        ),
        size: 50,
      },
    ],
    []
  );

  //getBranches
  const getBranches = async () => {
    setLoading(true);

    try {
      const defaultFilter = [];
      if (selectCompany) {
        defaultFilter.push({
          f: "company.id",
          o: "=",
          p: [Number(selectCompany)],
        });
      }

      if (branchInput) {
        defaultFilter.push({
          f: "name",
          o: "contains",
          p: [branchInput],
        });
      }

      const res = await base_api_request.get(
        `${RouteConstants.ALL_BRANCHES}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&filters=${JSON.stringify(defaultFilter)}&sorting=updated_at:desc`
      );
      setBranches(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  //search branch by company
  const loadCompanyOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ALL_COMPANIES}?filters=${JSON.stringify(
            filterData
          )}`
        );

        const searchOptions = res.data?.payload?.items?.map(
          (item: Company) => ({
            label: item?.name,
            value: item?.id,
          })
        );

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  useEffect(() => {
    getBranches();
    getCompanies()
      .then((res) => {
        setCompanies(
          res.payload?.items?.map((items: Company) => ({
            value: items?.id,
            label: items?.name,
          }))
        );
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    selectCompany,
    reFetch,
    branchInput,
  ]);

  //handleViewDetails
  const handleViewDetails = (data: any) => {
    redirect(`/branch/${data?.id}`);
  };

  //handleEditBranch
  const handleEditBranch = (data: any) => {
    // console.log(data)
    setOpenEdit(true);
    setDataToEdit(data);
  };

  return (
    <>
      <PageLayout>
        <CreateBranch
          isOpen={open}
          handleClosed={() => setOpen(false)}
          handleReFetch={handleReFetch}
        />
        <div className="w-full overflow-auto mb-8 relative">
          <div className="col-span-3 mx-4 my-6 h-full">
            <div className="flex justify-between items-center mb-5">
              <h3 className="font-bold py-2 text-2xl">Branches</h3>
              <CustomButton
                data-testid="createBranch"
                onClick={() => setOpen(true)}
              >
                <div className="flex gap-1 text-white items-center">
                  <AddOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <div>Create branch</div>
                </div>
              </CustomButton>
            </div>
            <div className="my-4 shadow p-2 rounded-lg w-full bg-white flex gap-x-[30px]">
              <div className="w-[250px]">
                <small>Filter branches by name</small>
                <div className="w-full">
                  <Input
                    className="h-[40px]"
                    placeholder="Type to search"
                    value={branchInput}
                    onChange={(e) => setBranchInput(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[250px]">
                <small>Filter branches by company</small>
                <div className="w-full">
                  <AsyncSelect
                    loadOptions={(inputValue, callback) => {
                      loadCompanyOptions(inputValue, callback);
                    }}
                    onChange={(e: any) => {
                      setSelectedCompany(e?.value);
                    }}
                    isSearchable
                    isClearable
                    defaultOptions={companies}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div className="w-full h-full mb-14">
                <MaterialReactTable
                  muiTablePaperProps={{
                    sx: {
                      borderRadius: "0",
                    },
                  }}
                  data={branches}
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableMultiRowSelection={false}
                  enableRowSelection={false}
                  manualPagination
                  enableSorting={false}
                  columns={columns}
                  rowCount={meta?.total}
                  state={{ pagination, isLoading: loading }}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  enableColumnResizing
                  // muiTableBodyRowProps={({ row }) => ({
                  //   onClick: (event) => {
                  //     console.log(row.original);
                  //     // func.redirect(`/hsh/${row.original?.id}`);
                  //     redirect(`/branch/${row.original?.id}`);
                  //   },
                  //   sx: {
                  //     cursor: 'pointer',
                  //   },
                  // })}
                  onPaginationChange={setPagination}
                />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>

      {/* edit modal */}
      {openEdit ? (
        <EditBranch
          isEditOpened={openEdit}
          handleClosed={handleCloseEdit}
          dataToEdit={dataToEdit}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Branches;
