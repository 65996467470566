import { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import PageLayout from '../components/layouts/PageLayout';
import { Branch } from './branches/branch';
import { base_api_request } from '../utils/api';
import { RouteConstants } from '../utils/helpers/RouteConstants';
import BranchLoadingPoints from './BranchLoadingPoints';
import BranchStaff from './BranchStaff';
import BranchMigrations from './BranchMigrations';

const BranchDetails = () => {
  const [branch, setBranch] = useState<Branch>();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0)

  const { id } = useParams();

  //get branch details
  const getDetails = async () => {
    setLoading(true);
    try {
      const res = await base_api_request.get(
        `${RouteConstants.ALL_BRANCHES}/${id}/details`
      );
      setBranch(res.data?.payload);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  //onload
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  const tabComponents = [
    <BranchLoadingPoints />, <BranchStaff />, <BranchMigrations />
  ]

  return (
    <PageLayout>
      <div className='w-full overflow-auto mb-8 relative'>
        <div className='col-span-3 mx-4 my-6'>
          <Breadcrumb
            separator='>'
            items={[
              {
                title: 'Home',
                href: '/',
              },
              {
                title: 'Branches',
                href: '/branches',
              },
              {
                title: 'Details',
              },
            ]}
          />
          <div className='mt-4'>
            <h3 className='font-bold py-2 text-2xl'>Branch details</h3>
            {/* <Space>
              <FunnelIcon className='w-6 h-6 text-gray-500' />
              <Select onChange={handleFilterChange} className='w-[157px]'>
                <Select.Option value={'staff'}>Staff</Select.Option>
                <Select.Option value={'schedules'}>Schedules</Select.Option>
              </Select>
            </Space> */}
          </div>

          {loading ? (
            // shimmer
            <div className='mt-4 rounded-md shadow-lg bg-white w-full p-5 border border-gray-400 animate-pulse'>
              <div className='lg:grid lg:grid-cols-3 gap-x-[30px]'>
                <div className='mb-5 lg:mb-0 w-full'>
                  <p className='font-semibold text-lg'>Basic Info</p>

                  <div className='mt-2'>
                    <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                    <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                  </div>
                  <div className='mt-3'>
                    <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                    <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                  </div>
                  <div className='mt-3'>
                    <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                    <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                  </div>
                </div>

                <div className='w-full col-span-2'>
                  <p className='font-semibold text-lg'>Other Info</p>

                  <div className='lg:grid lg:grid-cols-2 gap-x-[30px] mt-2'>
                    <div>
                      <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                      <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                    </div>
                    <div>
                      <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                      <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                    </div>
                    <div className='mt-3'>
                      <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                      <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                    </div>
                    <div className='mt-3'>
                      <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                      <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                    </div>
                    <div className='mt-3'>
                      <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                      <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                    </div>
                    <div className='mt-3'>
                      <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                      <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                    </div>
                    <div className='mt-3'>
                      <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                      <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                    </div>
                    <div className='mt-3'>
                      <div className='bg-gray-200 text-transparent w-[150px] h-4 rounded-md'></div>
                      <div className='bg-gray-200 text-transparent mt-2 h-4 rounded-md'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='mt-4 rounded-md shadow-lg bg-white w-full p-5 border border-gray-400'>
              <div className='lg:grid lg:grid-cols-3 gap-x-[30px]'>
                <div className='mb-5 lg:mb-0 w-full'>
                  <p className='font-semibold text-lg'>Basic Info</p>

                  <div className='mt-2'>
                    <h5 className='text-gray-400'>Branch name:</h5>
                    <h5>{branch?.name}</h5>
                  </div>
                  <div className='mt-3'>
                    <h5 className='text-gray-400'>Email:</h5>
                    <h5>{branch?.email}</h5>
                  </div>
                  <div className='mt-3'>
                    <h5 className='text-gray-400'>Phone number</h5>
                    <h5>{branch?.phone}</h5>
                  </div>
                </div>

                <div className='w-full col-span-2'>
                  <p className='font-semibold text-lg'>Other Info</p>

                  <div className='lg:grid lg:grid-cols-2 gap-x-[30px] mt-2'>
                    <div>
                      <h5 className='text-gray-400'>Branch code</h5>
                      <h5>{branch?.code}</h5>
                    </div>
                    <div>
                      <h5 className='text-gray-400'>Branch location</h5>
                      <h5>{branch?.location}</h5>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-gray-400'>Mobile money number</h5>
                      <h5>{branch?.momo}</h5>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-gray-400'>Branch region</h5>
                      <h5>{branch?.region?.name}</h5>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-gray-400'>Bus company</h5>
                      <h5>{branch?.bus_company?.name}</h5>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-gray-400'>Bus company phone</h5>
                      <h5>{branch?.bus_company?.phone}</h5>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-gray-400'>Contact person</h5>
                      <h5>{branch?.bus_company?.contact_name}</h5>
                    </div>
                    <div className='mt-3'>
                      <h5 className='text-gray-400'>Contact person's phone</h5>
                      <h5>{branch?.bus_company?.contact_phone}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}


          {/* tabs */}
          <div className="mt-[30px] h-full">
            <div className='flex gap-4 flex-wrap'>
              {
                ["Loading Points", "Branch Staff", "Migrations"].map((tab, index) => (
                  <div key={index} className={`${activeTab === index ? 'border-b-[2px] border-b-oya-ghana-red' : ''} cursor-pointer`} onClick={() => setActiveTab(index)}>
                    <p className='font-semibold text-lg px-2'>{tab}</p>
                  </div>
                ))
              }
            </div>
          </div>

          {/* loadingPoints */}
          <div className="mt-5 mb-20 h-full">
            {tabComponents[activeTab]}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default BranchDetails;
