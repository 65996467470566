import { useState, useEffect } from "react";
import {
    Input,
    Form,
    Modal,
    notification,
    Spin,
    DatePicker,
    InputNumber,
} from "antd";
import { Button, Typography } from "@material-tailwind/react";
import CustomButton from "../CustomButton";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

import { base_api_request } from "../../utils/api";
import { getCompanies } from "../../utils/helpers/commonApiCalls";
import { disabledDate, getBase64 } from "../../utils/functions";
import { BusType } from "../../types";

import { Company } from "../../types";
import { debounce } from "lodash";

interface Option {
    label: string;
    value: number;
}

const CreateBus = ({
    handleClosed,
    isOpen,
    refresh,
}: {
    handleClosed: () => void;
    isOpen: boolean;
    refresh: () => void;
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    //const [company, setCompany] = useState<Company[]>([]);
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<
        number | undefined
    >();

    const [imageUrl, setImageUrl] = useState<string>();
    const [imgFile, setFile] = useState<any>();
    const [busTypes, setBusTypes] = useState<BusType[]>([]);
    const [loadingBusTypes, setLoadingBusTypes] = useState(false);

    const loadOptions = debounce(
        async (inputValue: string, callback: (options: Option[]) => void) => {
            let filterData = [
                {
                    f: "name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_COMPANIES}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const searchOptions = res.data?.payload?.items?.map(
                    (item: Company) => ({
                        label: item?.name,
                        value: item?.id,
                    })
                );

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    const loadBusTypes = debounce(
        async (inputValue: string, callback: (option: any) => void) => {
            try {
                const filter = [
                    {
                        f: "name",
                        o: "contains",
                        p: [`${inputValue}`],
                    },
                ];
                const { data } = await base_api_request(
                    `${RouteConstants.BUS_TYPES}?filters=${JSON.stringify(
                        filter
                    )}`
                );
                const options = data?.payload?.items?.map((item: BusType) => ({
                    label: item?.name,
                    value: item?.id,
                }));

                callback(options);
            } catch (e) {
                console.log(e);
            }
        },
        1000
    );

    const getBusTypes = async () => {
        setLoadingBusTypes(true);
        try {
            const { data } = await base_api_request(RouteConstants.BUS_TYPES);
            setBusTypes(
                data?.payload?.items?.map((item: BusType) => ({
                    label: item?.name,
                    value: item?.id,
                }))
            );
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingBusTypes(false);
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (allowedTypes.includes(file.type)) {
                if (isLt2M) {
                    setFile(file);
                    getBase64(file, (imgUrl: string) => {
                        setImageUrl(imgUrl);
                        //uploadImage(imgUrl, file);
                    });
                } else {
                    notification.error({
                        message: "Image size must be less than 2MB",
                    });
                }
            } else {
                notification.error({
                    message:
                        "Please select a valid image file (jpg, jpeg, or png).",
                });
            }
        }
    };

    const submitSignUpload = async (bus: number) => {
        try {
            const response = await base_api_request.post(
                `${RouteConstants.BUSES}/${bus}/images/signed-upload`,
                {
                    mime: imgFile?.type,
                }
            );

            axios
                .put(response.data?.payload?.upload_url, imgFile, {
                    headers: {
                        "Content-Type": imgFile.type,
                    },
                })
                .then(function () {
                    notification.success({
                        message: "Bus has been created",
                    });
                    //setSigning(false);
                    setLoading(false);
                    handleClosed();
                    refresh();
                    setFile(undefined);
                    setImageUrl(undefined);
                })
                .catch(function (error: any) {
                    console.log(error);
                    notification.error({
                        message: error?.response?.data?.message,
                    });
                    setLoading(false);
                });
        } catch (e: any) {
            notification.error({
                message: e?.response?.data?.message,
            });
            setLoading(false);
            console.log(e);
        }
    };

    const submit = async () => {
        if (imgFile) {
            form.validateFields([
                "company_id",
                "type_id",
                "model",
                "reg_number",
                "insurance_expires_at",
                "roadworthy_expires_at",
                "passenger_capacity",
                "capacity",
            ])
                .then(async (values) => {
                    try {
                        setLoading(true);
                        // const values = form.getFieldsValue();
                        // console.log(values);

                        const res = await base_api_request.post(
                            `${RouteConstants.ALL_COMPANIES}/${selectedCompany}/buses`,
                            {
                                ...values,
                                type_id: values?.type_id?.value,
                                company_id: values?.company_id?.value,
                            }
                        );
                        form.resetFields();

                        submitSignUpload(res.data?.payload?.id);
                    } catch (e: any) {
                        notification.error({
                            message: e?.response?.data?.message,
                        });
                        setLoading(false);
                        console.log(e);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            notification.info({
                message: "Please upload a vehicle image",
            });
        }
    };

    useEffect(() => {
        getBusTypes();

        getCompanies()
            .then((res) => {
                setOptions(
                    res.payload?.items?.map((item: Company) => ({
                        label: item?.name,
                        value: item?.id,
                    }))
                );
            })
            .catch((e) => console.log(e));
    }, []);

    return (
        <Modal
            open={isOpen}
            onCancel={handleClosed}
            footer={null}
            className="min-w-[700px]"
        >
            <Spin spinning={loading}>
                <div>
                    <Typography variant="h5">Create a Bus</Typography>
                    <div className="sm:flex gap-2">
                        <div className="flex-[0.4]">
                            <div className="relative border border-solid border-oya-ghana-border mt-2 p-6 rounded-lg">
                                {imageUrl ? (
                                    <img src={imageUrl} alt="" />
                                ) : (
                                    <div className="img-upload">
                                        <div className="text-center flex justify-center py-5">
                                            <img
                                                src={require("../../images/Vector.png")}
                                                alt="Upload"
                                            />
                                        </div>

                                        <div className="text-center">
                                            <p className="pb-2 text-oya-ghana-header-h6 font-semibold">
                                                Drop your file here or <br />{" "}
                                                click to upload
                                            </p>
                                            <p className="text-oya-ghana-header-h7 font-bold">
                                                PNG, JPEG format only. <br />{" "}
                                                Maximum file size 2MB.
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <input
                                    onChange={handleImageChange}
                                    data-testid="verification-front-image-input"
                                    type="file"
                                    className="absolute inset-0 w-full h-full cursor-pointer"
                                    style={{ opacity: 0 }}
                                />
                            </div>
                        </div>
                        <Form
                            form={form}
                            className="flex-[0.6]"
                            layout="vertical"
                            autoComplete="none"
                        >
                            <Form.Item
                                className="w-full"
                                label="Select Bus Company"
                                name="company_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <AsyncSelect
                                    loadOptions={(inputValue, callback) => {
                                        loadOptions(inputValue, callback);
                                    }}
                                    onChange={(e) => {
                                        setSelectedCompany(e?.value);
                                    }}
                                    isSearchable
                                    isClearable
                                    defaultOptions={options}
                                />
                            </Form.Item>
                            <div className="mt-3">
                                <Form.Item
                                    label="Bus type"
                                    name="type_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required",
                                        },
                                    ]}
                                >
                                    <AsyncSelect
                                        defaultOptions={busTypes}
                                        isLoading={loadingBusTypes}
                                        loadOptions={(inputValue, callback) => {
                                            loadBusTypes(inputValue, callback);
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="flex gap-3">
                                <Form.Item
                                    className="w-1/2"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required",
                                        },
                                    ]}
                                    label="Registration"
                                    name="reg_number"
                                >
                                    <Input
                                        placeholder="AS 1234-12"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="w-1/2"
                                    label="Model Name"
                                    name="model"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Scania" size="large" />
                                </Form.Item>
                            </div>
                            <div className="flex gap-3">
                                <Form.Item
                                    className="w-1/2"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required",
                                        },
                                    ]}
                                    label="Insurance Expiry Date"
                                    name="insurance_expires_at"
                                >
                                    <DatePicker
                                        size="large"
                                        className="w-full"
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="w-1/2"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required",
                                        },
                                    ]}
                                    label="Roadworthy Expiry Date"
                                    name="roadworthy_expires_at"
                                >
                                    <DatePicker
                                        size="large"
                                        className="w-full"
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                            </div>
                            <div className="flex gap-3">
                                <Form.Item
                                    className="w-1/2"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required",
                                        },
                                    ]}
                                    label="Capacity"
                                    name="capacity"
                                >
                                    <InputNumber
                                        type="number"
                                        min={10}
                                        className="w-full"
                                        value={1}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="w-1/2"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required",
                                        },
                                    ]}
                                    label="Passenger Capacity"
                                    name="passenger_capacity"
                                >
                                    <InputNumber
                                        type="number"
                                        min={10}
                                        className="w-full"
                                        value={1}
                                        size="large"
                                    />
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="flex w-full justify-end items-end mt-10">
                        <div className="flex items-center gap-3">
                            <CustomButton onClick={submit}>
                                <div>Submit</div>
                            </CustomButton>
                            <Button
                                disabled={loading}
                                variant="outlined"
                                size="sm"
                                onClick={handleClosed}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default CreateBus;
