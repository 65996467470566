import React, { useState, useMemo, useEffect } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import CustomButton from "../../components/CustomButton";
// import { FunnelIcon } from "@heroicons/react/24/outline";
import { AddOutlined, DeleteForever, EditOutlined } from "@mui/icons-material";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { ListItemIcon, MenuItem, Typography, Box } from "@mui/material";
import { notification } from "antd";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import CreateLoadingPointTime from "../../components/Branches/CreateLoadingPointTime";
import { LoadingPointTravelTime } from "../../types";
import moment from "moment";
import EditLoadingPointTime from "../../components/Branches/EditLoadingPointTime";

const LoadingPointTime = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const [reload, setReload] = useState(false);

  const [selectedLoadingPointTime, setSelectedLoadingPointTime] = useState<
    LoadingPointTravelTime | undefined
  >(undefined);
  const [loadingPointTimes, setLoadingPointTimes] = useState<
    LoadingPointTravelTime[]
  >([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const loadingPointTimeColumns = useMemo<
    MRT_ColumnDef<LoadingPointTravelTime>[]
  >(
    () => [
      {
        accessorFn: (row) => `${row?.name}`,
        header: "Name",
      },
      {
        accessorFn: (row) => `${row?.start_time}`,
        Cell: ({ cell }: { cell: any }) => {
          const value = cell?.getValue();
          const date = new Date(`1970-01-01T${value}Z`);

          return <Box>{moment(date).format("LT")}</Box>;
        },
        header: "Start Travel time",
      },
      {
        accessorFn: (row) => `${row?.end_time}`,
        Cell: ({ cell }: { cell: any }) => {
          const value = cell?.getValue();
          const date = new Date(`1970-01-01T${value}Z`);

          return <Box>{moment(date).format("LT")}</Box>;
        },
        header: "End Travel time",
      },
      {
        accessorKey: "creator",
        header: "Created By",
        accessorFn: (row) => `${row?.creator?.name ?? ""}`,
      },
      {
        accessorKey: "creator",
        header: "Updated By",
        accessorFn: (row) => `${row?.updater?.name ?? ""}`,
      },
    ],

    []
  );

  const getLoadingPointTimes = async () => {
    setLoading(true);
    try {
      const { data } = await base_api_request.get(
        `${RouteConstants.LOADING_POINT_TRAVEL_TIME}?sorting=created_at:desc`
      );

      setLoadingPointTimes(data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: data?.payload?.total,
      }));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteLuggageSizes = async (id: string) => {
    setLoading(true);
    try {
      await base_api_request.delete(
        `${RouteConstants.LOADING_POINT_TRAVEL_TIME}/${id}`
      );

      setReload(!reload);

      notification.success({
        message: "Loading point travel time deleted",
      });
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLoadingPointTimes();
    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, reload]);

  return (
    <PageLayout>
      <div className="col-span-3 mx-4 my-6 h-full">
        <div className="w-full overflow-auto mb-8 relative">
          <div className="flex justify-between items-center my-4">
            <h3 className="font-bold py-2 text-2xl">
              Loading Point Travel Times
            </h3>

            <div className="flex gap-3">
              {/* <CustomButton onClick={() => {}}>
                <div className="flex gap-1 text-white items-center">
                  <FunnelIcon className="h-5 w-5" />
                  Filter
                </div>
              </CustomButton> */}

              <CustomButton
                data-testid="createLoadongPointTime"
                onClick={() => setOpen(true)}
              >
                <div className="flex gap-1 text-white items-center">
                  <AddOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <div>Create loading point time</div>
                </div>
              </CustomButton>
            </div>
          </div>

          <div className="grid grid-cols-1">
            <MaterialReactTable
              muiTablePaperProps={{
                sx: {
                  borderRadius: "0",
                },
              }}
              enableRowActions={true}
              renderRowActionMenuItems={({ closeMenu, row }) => [
                <MenuItem
                  key={1}
                  onClick={() => {
                    deleteLuggageSizes(row.original?.id);

                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <DeleteForever
                      sx={{
                        color: "red",
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "red",
                    }}
                  >
                    Delete
                  </Typography>
                </MenuItem>,
                <MenuItem
                  key={1}
                  onClick={() => {
                    setSelectedLoadingPointTime(row.original);
                    setShowEdit(true);
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <EditOutlined
                      sx={{
                        color: "red",
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "red",
                    }}
                  >
                    Edit
                  </Typography>
                </MenuItem>,
              ]}
              data={loadingPointTimes}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              enableTopToolbar={false}
              manualPagination
              enableSorting={false}
              columns={loadingPointTimeColumns}
              rowCount={meta?.total}
              state={{ pagination, isLoading: loading }}
              initialState={{
                showColumnFilters: false,
                density: "compact",
                columnPinning: {
                  left: ["mrt-row-expand", "mrt-row-select"],
                  right: ["mrt-row-actions"],
                },
              }}
              enableColumnResizing
              onPaginationChange={setPagination}
            />
          </div>
        </div>
      </div>
      <CreateLoadingPointTime
        isOpen={open}
        handleClosed={() => setOpen(false)}
        handleReFetch={() => {
          setReload(!reload);
        }}
      />
      <EditLoadingPointTime
        isEditOpened={showEdit}
        dataToEdit={selectedLoadingPointTime}
        handleClosed={() => setShowEdit(false)}
        handleReFetch={() => {
          getLoadingPointTimes();
        }}
      />
    </PageLayout>
  );
};

export default LoadingPointTime;
