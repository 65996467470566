import React, { useState } from "react";
import { Modal, Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import moment from "moment";
import CustomButton from "../../components/CustomButton";
import EnrolAdvanceBooking from "./EnrolAdvanceBooking";

interface AdvanceBookingModalProps {
  isOpen: boolean;
  onClose: () => void;
  rowData: any;
  currentIndex: any;
  onPrev: () => void;
  onNext: () => void;
  disableNext: any;
  disablePrev: any;
}

const AdvanceBookingModal: React.FC<AdvanceBookingModalProps> = ({
  isOpen,
  onClose,
  rowData,
  onPrev,
  onNext,
  disableNext,
  disablePrev,
}) => {
  const [showEnrol, setShowEnrol] = useState(false);

  return (
    <React.Fragment>
      <Modal open={isOpen}>
        <>
          <Box sx={style}>
            <div className="flex flex-row justify-between mb-1 mt-3 mr-1.5 ml-16 items-center sm:w-">
              <div className="font-extrabold text-lg">
                <div>Booking Details</div>
              </div>

              <div className="flex flex-row ">
                <div className="font-bold">ID:&nbsp; </div>
                {"  "}
                <div> {rowData?.id}</div>
              </div>
              <div className="flex flex-row ">
                <div className="font-bold">STATUS:&nbsp;</div>
                <div>{rowData?.status?.code}</div>
              </div>
              <IconButton
                data-testid="closeAdvanceModal"
                className=" cursor-pointer "
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </div>
            <div className="flex flex-row mb-5">
              <div className="mx-auto my-auto ">
                {disablePrev ? (
                  <div className="w-16"></div>
                ) : (
                  <div className="px-3  ">
                    <IconButton data-testid="previousAdvance" onClick={onPrev}>
                      <ChevronLeft />
                    </IconButton>
                  </div>
                )}
              </div>
              <div className="">
                <div className="font-bold text-md my-1">Trip details</div>
                {/* {rowData ? JSON.stringify(rowData, null, 2) : "N/A"} */}
                <div>
                  {rowData ? (
                    <div className="pr-12 my-3">
                      <div>
                        <div className="flex flex-row justify-between gap-10">
                          <div className="">
                            <div>
                              <div className="font-medium text-sm">Route</div>
                              <div>
                                {rowData?.route?.from.name ?? "N/A"} -{" "}
                                {rowData?.route?.to?.name ?? "N/A"}
                              </div>
                            </div>

                            <div>
                              <div className="text-sm font-medium my-2">
                                Number of minors
                              </div>
                              <div>{rowData?.minors ?? "N/A"}</div>
                            </div>
                          </div>
                          <div className="">
                            <div className="">
                              <div className="text-sm font-medium ">
                                Travel date & time
                              </div>
                              <div>
                                {moment(rowData?.max_travel_time).format(
                                  "Do MMM YYYY hh:mm a"
                                )}
                              </div>
                            </div>

                            <div className="text-sm font-medium my-2">
                              Additional information
                            </div>
                            <div>{rowData?.message ?? "N/A"}</div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between mt-4">
                        <div>
                          <div className="text-md font-bold my-1">
                            Personal Details
                          </div>
                          <div className="my-2">
                            <div className="text-sm font-medium">Name</div>
                            <div>{rowData?.user?.name ?? "N/A"}</div>
                          </div>
                          <div className="my-2">
                            <div className="text-sm font-medium">
                              Phone number
                            </div>
                            <div>{rowData?.user?.phone ?? "N/A"}</div>
                          </div>
                        </div>
                        <div>
                          <div className="text-sm font-medium  mt-8">
                            Emergency Phone number
                          </div>
                          <div>{rowData?.user?.ice_phone ?? "N/A"}</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
              <div className="content-center ">
                {disableNext ? (
                  <div className="w-16"></div>
                ) : (
                  <div className="px-3">
                    <IconButton onClick={onNext}>
                      <ChevronRight />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>

            <div className="m-7">
              {rowData?.status?.code === "WAITING" && (
                <CustomButton
                  data-testid="enrolUser"
                  onClick={() => {
                    onClose();
                    setShowEnrol(true);
                    localStorage.setItem("bookingId", rowData?.id);
                  }}
                >
                  <div>Enrol user</div>
                </CustomButton>
              )}
            </div>
          </Box>
        </>
      </Modal>
      <EnrolAdvanceBooking
        isOpen={showEnrol}
        handleClose={() => setShowEnrol(false)}
      />
    </React.Fragment>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflow: "auto",
};

export default AdvanceBookingModal;
