import { Fragment, useState, useEffect } from "react";
import { Modal, Form, Spin, notification, Card } from "antd";
import CustomButton from "../../components/CustomButton";
import PhoneInput, { Value } from "react-phone-number-input";
import { auth_api_call, base_api_request } from "../../utils/api";
import { User } from "../../types";
import { getBranches } from "../../utils/helpers/commonApiCalls";

import { Branch } from "./branch";
import AsyncSelect from "react-select/async";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { debounce } from "lodash";

interface Option {
    label: string;
    value: number;
}

const AddStaff = ({
    onCancel,
    isOpen,
    handleReload,
}: {
    onCancel: () => void;
    handleReload: () => void;
    isOpen: boolean;
}) => {
    const [form] = Form.useForm();
    const [step, setStep] = useState(1);
    const [phone, setPhone] = useState<Value | undefined>("");
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<string | any>();
    const [data, setData] = useState<User | undefined>();
    // const [fetchingTypes, setFetchingTypes] = useState(false);
    const [account_type, setAccountType] = useState<Option[]>([]);
    const [branchId, setBranchId] = useState("");
    const [branchOptions, setBranchOptions] = useState<Option[]>([]);
    const [accountId, setAccountId] = useState<number | undefined>();

    const loadBranchOptions = debounce(
        async (inputValue: string, callback: (options: Option[]) => void) => {
            let filterData = [
                {
                    f: "name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_BRANCHES}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const searchOptions = res.data?.payload?.items?.map(
                    (item: Branch) => ({
                        label: item?.name,
                        value: item?.id,
                    })
                );

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    const loadAccountOptions = debounce(
        async (inputValue: string, callback: (options: Option[]) => void) => {
            let filterData = [
                {
                    f: "name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const res = await auth_api_call.get(
                    `${RouteConstants.ACCOUNT_TYPES}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const searchOptions = res.data?.payload?.items?.map(
                    (item: Branch) => ({
                        label: item?.name,
                        value: item?.id,
                    })
                );

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    const submit = async () => {
        setLoading(true);
        if (branchId) {
            // const accountType_toInteger = Number(
            //   form.getFieldValue('account_type_id')
            // );
            try {
                await base_api_request.post(
                    `${RouteConstants.ALL_BRANCHES}/${branchId}/staff`,
                    {
                        user_id: user,
                        account_type_id: accountId,
                    }
                );
                notification.success({
                    message: "New staff member added successfully",
                });
                setLoading(false);
                onCancel();
                //window.location.reload();
                handleReload();
            } catch (e: any) {
                notification.error({
                    message: e?.response?.data?.message,
                });
                console.log(e);
                setLoading(false);
            }
        } else {
            notification.error({
                message:
                    "Cannot perform this task at the moment. Try again later",
                duration: 10,
            });
        }
    };

    const getPhone = async () => {
        setLoading(true);
        try {
            const res = await auth_api_call.post(`${RouteConstants.PHONE}`, {
                phone,
            });
            setData(res.data?.payload);

            console.log(res.data);
            setLoading(false);
        } catch (e) {
            notification.error({
                message: "Access denied. Account activation required",
            });
            console.log(e);
            setLoading(false);
        }
    };

    const getAccountTypes = async () => {
        //setFetchingTypes(true);
        try {
            const res = await auth_api_call.get(
                `${RouteConstants.ACCOUNT_TYPES}`
            );
            setAccountType(
                res.data?.payload?.items?.map((item: Branch) => ({
                    label: item?.name,
                    value: item?.id,
                }))
            );
            setStep(2);
        } catch (e) {
            notification.error({
                message: "Access denied. Account activation required",
            });
            console.log(e);
        }
    };

    const handleChosenUser = () => {
        setUser(data?.id);

        getAccountTypes();
    };

    useEffect(() => {
        getBranches().then((res) => {
            setBranchOptions(
                res.payload?.items?.map((item: Branch) => ({
                    label: item?.name,
                    value: item?.id,
                }))
            );
        });
    }, []);

    return (
        <Fragment>
            <Modal
                title="Add a new staff member"
                open={isOpen}
                onCancel={onCancel}
                footer={null}
                maskClosable={false}
            >
                <Spin spinning={loading}>
                    <div className="my-4">
                        <Form form={form} layout="vertical">
                            {step === 1 && (
                                <Form.Item
                                    name="user_id"
                                    label="Search Phone Number Associated with Staff"
                                >
                                    <PhoneInput
                                        className="phone w-full pl-4 mt-2 mb-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                                        defaultCountry="GH"
                                        data-testid="search-phone-input"
                                        onChange={(e) => {
                                            setPhone(e);
                                        }}
                                    />
                                </Form.Item>
                            )}
                            {data && (
                                <Card className="mb-3">
                                    <div className="py-3 flex gap-6 items-center w-full">
                                        <div>
                                            <div>{data?.name}</div>
                                            <div>{data?.phone}</div>
                                        </div>
                                        {step === 1 && (
                                            <CustomButton
                                                data-testid="confirm"
                                                onClick={handleChosenUser}
                                                variant="secondary"
                                            >
                                                Confirm
                                            </CustomButton>
                                        )}
                                        {step === 2 && (
                                            <CustomButton
                                                data-testid="change"
                                                onClick={() => setStep(1)}
                                                variant="secondary"
                                            >
                                                Change
                                            </CustomButton>
                                        )}
                                    </div>
                                </Card>
                            )}
                            {step === 2 && (
                                <>
                                    <Form.Item
                                        label="Select Staff Type"
                                        name="account_type_id"
                                        required
                                    >
                                        <AsyncSelect
                                            loadOptions={(
                                                inputValue,
                                                callback
                                            ) => {
                                                loadAccountOptions(
                                                    inputValue,
                                                    callback
                                                );
                                            }}
                                            onChange={(e: any) => {
                                                setAccountId(e?.value);
                                            }}
                                            isSearchable
                                            isClearable
                                            defaultOptions={account_type}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="w-full"
                                        label="Select Branch"
                                        name="branch_id"
                                        required
                                    >
                                        <AsyncSelect
                                            loadOptions={(
                                                inputValue,
                                                callback
                                            ) => {
                                                loadBranchOptions(
                                                    inputValue,
                                                    callback
                                                );
                                            }}
                                            onChange={(e: any) => {
                                                setBranchId(e?.value);
                                            }}
                                            isSearchable
                                            isClearable
                                            defaultOptions={branchOptions}
                                        />
                                    </Form.Item>
                                </>
                            )}
                        </Form>
                    </div>
                    <div className="flex gap-3 items-end justify-end w-full">
                        {step === 1 && (
                            <CustomButton
                                data-testid="searchStaff"
                                onClick={getPhone}
                            >
                                Search
                            </CustomButton>
                        )}
                        {step === 2 && (
                            <CustomButton
                                data-testid="submitStaff"
                                onClick={submit}
                            >
                                Submit
                            </CustomButton>
                        )}
                        <CustomButton
                            data-testid="cancelAddStaff"
                            onClick={onCancel}
                            variant="secondary"
                        >
                            Cancel
                        </CustomButton>
                    </div>
                </Spin>
            </Modal>
        </Fragment>
    );
};

export default AddStaff;
