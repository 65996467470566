import { useEffect, useMemo, useState } from "react";
import { base_api_request } from "../utils/api";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Staff } from "./branches/branch";
import AddBranchStaff from "./branches/AddBranchStaff";

const BranchStaff = () => {
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [staff, setStaff] = useState<Staff[]>([]);

  const [reFetch, setReFetch] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  //open Add modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //fetch staff
  const getStaff = async () => {
    setIsFetching(true);

    try {
      const res = await base_api_request.get(
        `${RouteConstants.ALL_BRANCHES}/${id}/staff?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc`
      );
      // console.log(res.data?.payload?.items);
      setStaff(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
      console.log(e);
    }
  };

  //table columns
  const staffColumn = useMemo<MRT_ColumnDef<Staff>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.user?.name}`,

        header: "Name",
      },
      {
        accessorFn: (row) => `${row?.user?.phone}`,

        header: "Phone",
      },
      {
        accessorFn: (row) => `${row?.account_type?.name}`,
        header: "User Type",
        size: 80,
      },
      {
        accessorFn: (row) => `${row?.branch?.name}`,
        header: "Associate Branch",
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Date Added",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell.getValue()).format("Do MMM YYYY - h:mma")}</Box>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getStaff();

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, reFetch]);

  //reFetch data
  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  return (
    <>
      <div className="p-5 rounded-[10px] bg-white shadow-md border border-gray-400">
        <div className="flex justify-end">
          <button
            data-testid="addStaff"
            className="px-3 py-1 bg-oya-ghana-red text-white rounded-[5px]"
            onClick={() => handleOpenModal()}
          >
            + Add Staff
          </button>
        </div>
      </div>

      <div className="mt-4">
        <MaterialReactTable
          muiTablePaperProps={{
            sx: {
              borderRadius: "0",
            },
          }}
          data={staff}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableMultiRowSelection={false}
          enableRowSelection={false}
          enableTopToolbar={false}
          manualPagination
          enableSorting={false}
          columns={staffColumn}
          rowCount={meta?.total}
          state={{ pagination, isLoading: isFetching }}
          initialState={{
            showColumnFilters: false,
            density: "compact",
          }}
          enableColumnResizing
          onPaginationChange={setPagination}
        />
      </div>

      {/* open modal for add staf */}
      {openModal ? (
        <AddBranchStaff
          isOpened={openModal}
          handleClose={handleCloseModal}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default BranchStaff;
