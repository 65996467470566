import { useEffect, useMemo, useState } from "react";
import { LoadingPoint } from "../types";
import { base_api_request } from "../utils/api";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";

const BranchLoadingPoints = () => {
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [loading_point, setLoadingPoint] = useState<LoadingPoint[]>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  //table columns
  const loadingPointColumn = useMemo<MRT_ColumnDef<LoadingPoint>[]>(
    () => [
      {
        accessorFn: (row) =>
          `${row?.source?.name} - ${row?.destination.name} ${
            row?.label ? `(${row?.label})` : ""
          }`,
        Cell: ({ cell }: { cell: any }) => (
          <Box>{cell.getValue() === "null" ? "N/A" : cell.getValue()}</Box>
        ),
        header: "Loading Point",
        size: 300,
      },
      {
        accessorFn: (row) => `${row?.station?.code}`,

        header: "Station Code ",
      },
      {
        accessorFn: (row) => `${row?.station?.name}`,

        header: "Station Name",
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Date Created",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell.getValue()).format("Do MMM YYYY - h:mma")}</Box>
        ),
      },
    ],
    []
  );

  //getLoadingPoints
  const getLoadingPoints = async () => {
    setIsFetching(true);
    try {
      const res = await base_api_request.get(
        `${RouteConstants.ALL_BRANCHES}/${id}/loading-points?limit=${
          pagination.pageSize
        }&offset=${Number(pagination.pageIndex * pagination.pageSize)}`
      );
      // console.log(res.data?.payload?.items);
      setLoadingPoint(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setIsFetching(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getLoadingPoints();

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          sx: {
            borderRadius: "0",
          },
        }}
        data={loading_point}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableMultiRowSelection={false}
        enableRowSelection={false}
        enableTopToolbar={false}
        manualPagination
        enableSorting={false}
        columns={loadingPointColumn}
        rowCount={meta?.total}
        state={{ pagination, isLoading: isFetching }}
        initialState={{
          showColumnFilters: false,
          density: "compact",
        }}
        enableColumnResizing
        onPaginationChange={setPagination}
      />
    </>
  );
};

export default BranchLoadingPoints;
