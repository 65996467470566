import { useState } from "react";
import {
    Input,
    Form,
    Modal,
    notification,
    Spin,
    Checkbox,
    message,
} from "antd";
import { Button, Typography } from "@material-tailwind/react";
import CustomButton from "../CustomButton";
import axios from "axios";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { base_api_request } from "../../utils/api";

import { getBase64 } from "../../utils/functions";

const CreateFeature = ({
    handleClosed,
    isOpen,
    refresh,
}: {
    handleClosed: () => void;
    isOpen: boolean;
    refresh: () => void;
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [special, setSpecial] = useState(false);

    const [imageUrl, setImageUrl] = useState<string>();
    const [imgFile, setFile] = useState<any>();

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (allowedTypes.includes(file.type)) {
                if (isLt2M) {
                    setFile(file);
                    getBase64(file, (imgUrl: string) => {
                        setImageUrl(imgUrl);
                        //uploadImage(imgUrl, file);
                    });
                } else {
                    message.error("Image must be smaller than 2MB!");
                }
            } else {
                message.error(
                    "Please select a valid image file (jpg, jpeg, or png)."
                );
            }
        }
    };

    const submitSignUpload = async (featureId: string | number) => {
        try {
            const response = await base_api_request.post(
                `${RouteConstants.FEATURES}/${featureId}/images/signed-upload`,
                {
                    mime: imgFile?.type,
                }
            );

            axios
                .put(response.data?.payload?.upload_url, imgFile, {
                    headers: {
                        "Content-Type": imgFile.type,
                    },
                })
                .then(function () {
                    notification.success({
                        message: "Feature created successfully",
                    });
                    //setSigning(false);
                    setLoading(false);
                    handleClosed();
                    refresh();
                })
                .catch(function (error: any) {
                    console.log(error);
                    notification.error({
                        message: error?.response?.data?.message,
                    });
                    setLoading(false);
                });
        } catch (e: any) {
            notification.error({
                message: e?.response?.data?.message,
            });
            setLoading(false);
            console.log(e);
        }
    };

    const submit = async () => {
        if (!imgFile) {
            notification.error({
                message: "Please select an image",
            });
        } else {
            form.validateFields(["name", "description"])
                .then(async (val) => {
                    try {
                        setLoading(true);
                        const { data } = await base_api_request.post(
                            RouteConstants.FEATURES,
                            {
                                special,
                                description: val?.description,
                                name: val?.name,
                            }
                        );

                        await submitSignUpload(data?.payload?.id);
                    } catch (e: any) {
                        notification.error({
                            message: e?.response?.data?.message || e?.message,
                        });
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    return (
        <Modal
            open={isOpen}
            onCancel={handleClosed}
            footer={null}
            className="min-w-[700px]"
        >
            <Spin spinning={loading}>
                <div>
                    <Typography variant="h5">Create a feature</Typography>
                    <div className="sm:flex gap-2">
                        <div className="flex-[0.4]">
                            <div className="relative border border-solid border-oya-ghana-border mt-2 p-6 rounded-lg">
                                {imageUrl ? (
                                    <img src={imageUrl} alt="" />
                                ) : (
                                    <div className="img-upload">
                                        <div className="text-center flex justify-center py-5">
                                            <img
                                                src={require("../../images/Vector.png")}
                                                alt="Upload"
                                            />
                                        </div>

                                        <div className="text-center">
                                            <p className="pb-2 text-oya-ghana-header-h6 font-semibold">
                                                Drop your file here or <br />{" "}
                                                click to upload
                                            </p>
                                            <p className="text-oya-ghana-header-h7 font-bold">
                                                PNG, JPEG format only. <br />{" "}
                                                Maximum file size 2MB.
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <input
                                    onChange={handleImageChange}
                                    data-testid="verification-front-image-input"
                                    type="file"
                                    className="absolute inset-0 w-full h-full cursor-pointer"
                                    style={{ opacity: 0 }}
                                />
                            </div>
                        </div>
                        <Form
                            form={form}
                            className="flex-[0.6]"
                            layout="vertical"
                            autoComplete="none"
                        >
                            <Form.Item
                                className="w-full"
                                label="Feature name"
                                name="name"
                                required
                            >
                                <Input placeholder="Enter feature name" />
                            </Form.Item>
                            <div className="flex gap-3">
                                <Form.Item
                                    className="w-full"
                                    required
                                    label="Description"
                                    name="description"
                                >
                                    <Input.TextArea placeholder="Enter description" />
                                </Form.Item>
                            </div>
                            <div>
                                <Checkbox
                                    onChange={(e) => {
                                        setSpecial(e.target.checked);
                                    }}
                                >
                                    <div>Special</div>
                                </Checkbox>
                            </div>
                        </Form>
                    </div>
                    <div className="flex w-full justify-end items-end mt-10">
                        <div className="flex items-center gap-3">
                            <CustomButton
                                data-testid="submitNewFeature"
                                onClick={submit}
                            >
                                <div>Submit</div>
                            </CustomButton>
                            <Button
                                data-testid="closeNewFetaure"
                                disabled={loading}
                                variant="outlined"
                                size="sm"
                                onClick={handleClosed}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default CreateFeature;
