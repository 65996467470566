import { useState, useEffect, useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import { Attendance } from "./branch";
import moment from "moment";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import { base_api_request } from "../../utils/api";
import { useNavigate, useParams } from "react-router";
import PageLayout from "../../components/layouts/PageLayout";
import { Skeleton } from "antd";
import { Branch } from "./branch";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

const AttendanceList = () => {
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState<Attendance[]>([]);
  const [branch, setBranch] = useState<Branch>();
  const { id } = useParams();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const attendanceColumns = useMemo<MRT_ColumnDef<Attendance>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.staff?.staff_id}`,
        Cell: ({ cell }: any) => (
          <>
            {cell.getValue() === "null" ? "N/A" : <Box>{cell.getValue()}</Box>}
          </>
        ),
        size: 80,
        header: "Staff ID",
      },
      {
        accessorFn: (row) => `${row?.staff?.name}`,
        Cell: ({ cell }: any) => (
          <>
            {cell.getValue() === "null" ? "N/A" : <Box>{cell.getValue()}</Box>}
          </>
        ),
        header: "Staff name",
      },
      {
        accessorFn: (row) => `${row?.staff?.phone}`,
        Cell: ({ cell }: any) => (
          <>
            {cell.getValue() === "null" ? "N/A" : <Box>{cell.getValue()}</Box>}
          </>
        ),
        header: "Phone",
      },
      {
        accessorFn: (row) => `${row?.supervisor?.name}`,
        Cell: ({ cell }: any) => (
          <>
            {cell.getValue() === "null" ? "N/A" : <Box>{cell.getValue()}</Box>}
          </>
        ),
        header: "Supervisor",
      },
      {
        accessorFn: (row) => `${row?.present}`,
        Cell: ({ cell }: { cell: any }) => (
          <Box>{cell.getValue() === "true" ? "Yes" : "No"}</Box>
        ),
        header: "Present",
        size: 80,
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Date",
        Cell: ({ cell }: { cell: any }) => (
          <>
            {cell.getValue() === "null" ? (
              "N/A"
            ) : (
              <Box>{moment(cell.getValue()).format("LLL")}</Box>
            )}
          </>
        ),
      },
    ],
    []
  );

  const getDetails = async () => {
    setLoading(true);
    try {
      const res = await base_api_request.get(
        `${RouteConstants.ALL_BRANCHES}/${id}/details`
      );
      setBranch(res.data?.payload);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getAttendance = async () => {
    setLoading(true);
    try {
      const res = await base_api_request.get(
        `${RouteConstants.ALL_BRANCHES}/${id}/staff-attendance?limit=${
          pagination.pageSize
        }&offset=${Number(pagination.pageIndex * pagination.pageSize)}`
      );
      setAttendance(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDetails();
    getAttendance();

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize]);
  return (
    <PageLayout>
      <div className="col-span-3 mx-4 my-6 h-full">
        <div className="w-full overflow-auto mb-8 relative">
          <button
            data-testid="goBack"
            className="flex text-gray-700 items-center gap-2 my-3 hover:text-black"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewOutlined
              sx={{
                width: 15,
                height: 15,
              }}
            />
            Back
          </button>

          <div className=" my-4">
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <div className="flex gap-3 items-center mb-2 text-lg font-semibold">
                <div>{branch?.name}</div>
                <div>-</div>
                <div>{branch?.bus_company?.name}</div>
              </div>
            )}
            <div className="font-semibold text-gray-700 text-sm">
              Branch Staff Attendance
            </div>
          </div>
          <div className="grid grid-cols-1">
            <MaterialReactTable
              muiTablePaperProps={{
                sx: {
                  borderRadius: "0",
                },
              }}
              data={attendance}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              enableTopToolbar={false}
              manualPagination
              rowCount={meta.total}
              enableSorting={false}
              columns={attendanceColumns}
              state={{ pagination, isLoading: loading }}
              initialState={{
                showColumnFilters: false,
                density: "compact",
              }}
              enableColumnResizing
              onPaginationChange={setPagination}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AttendanceList;
