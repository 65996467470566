import React, { useEffect, useState, useMemo } from "react";
import PageLayout from "../components/layouts/PageLayout";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import moment from "moment";
import { base_api_request } from "../utils/api";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import CustomButton from "../components/CustomButton";
import CreateFeature from "../components/Branches/CreateFeature";
import { FeatureType } from "../types";

const Features = () => {
  const [features, setFeatures] = useState<FeatureType[]>([]);
  const [createFeature, setCreateFeature] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [loading, setLoading] = useState(false);

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const columns = useMemo<MRT_ColumnDef<FeatureType>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.name}`,

        header: "Name",
      },
      {
        accessorFn: (row) => `${row?.special === true ? "Yes" : "No"} `,

        header: "Special",
      },
      {
        accessorFn: (row) => `${row?.description ? row?.description : ""} `,

        header: "Description",
      },

      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Created On",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell.getValue()).format("LLL")}</Box>
        ),
      },
    ],
    []
  );

  const getData = async () => {
    try {
      setLoading(true);
      const res = await base_api_request.get(
        `${RouteConstants.FEATURES}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc`
      );
      setFeatures(res.data?.payload?.items);

      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
    } catch (e) {
      //
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <PageLayout>
      <div className="overflow-hidden h-screen">
        <div className="col-span-3 h-full overflow-y-auto p-[20px]">
          <div className="my-4">
            <div className="flex justify-between items-center mb-5">
              <h3 className="font-bold py-2 text-2xl">Features</h3>
              <CustomButton
                data-testid="createFeature"
                onClick={() => setCreateFeature(true)}
              >
                Create feature
              </CustomButton>
            </div>
          </div>

          <div className="grid grid-cols-1">
            <div className="w-full h-full mb-8">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "0",
                  },
                }}
                data={features}
                enableTopToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                manualPagination
                enableSorting={false}
                columns={columns}
                rowCount={meta?.total}
                state={{ pagination, isLoading: loading }}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                }}
                enableColumnResizing
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    console.log(row.original);
                    // func.redirect(`/hsh/${row.original?.id}`);
                    //redirect(`/branch/${row.original?.id}`);
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                onPaginationChange={setPagination}
              />
            </div>
          </div>
        </div>
      </div>
      <CreateFeature
        isOpen={createFeature}
        handleClosed={() => setCreateFeature(false)}
        refresh={() => {
          getData();
        }}
      />
    </PageLayout>
  );
};

export default Features;
