import { useEffect, useState, ChangeEvent } from "react";
import { Drawer, Form, Input, notification, Select } from "antd";
import { Account } from "../../types";
import CustomButton from "../CustomButton";
import PhoneInput, { Value } from "react-phone-number-input";

type Props = {
    user: Account | any;
    isOpen: boolean;
    handleClosed: () => void;
    handleSubmitting: (value: boolean) => void;
    updateProfile: (
        values: any,
        ice1_phone: any,
        ice2_phone: any,
        birthDate: string,
        gender: string
    ) => void;
};

const EditProfile = (props: Props) => {
    const [form] = Form.useForm();
    const [ice1_phone, setIce1Phone] = useState<Value | undefined>("");
    const [ice2_phone, setIce2Phone] = useState<Value | undefined>("");
    const [gender, setGender] = useState("");
    const [birthDate, setBirthDate] = useState("");
    //const [user,setUser] = useState(props.user)

    const handleDateOfBirthChange = (event: ChangeEvent<HTMLInputElement>) => {
        const currentDate = new Date();
        const minAge = 12; // Minimum age required
        const maxAge = 100; // Maximum age allowed
        event.preventDefault();
        const selectedDate = new Date(event.target.value);
        if (isNaN(selectedDate.getTime())) {
            notification.warning({
                message: "Invalid date. Please select a valid date of birth.",
            });
            return;
        }
        const age = currentDate.getFullYear() - selectedDate.getFullYear();

        if (age < minAge || age > maxAge) {
            notification.warning({
                message: "Invalid age. Please select a valid date of birth.",
            });
        } else {
            const formatted = selectedDate.toISOString().split("T")[0];

            setBirthDate(formatted);
        }
    };

    const submit = async () => {
        const values = form.getFieldsValue();

        props.updateProfile(values, ice1_phone, ice2_phone, birthDate, gender);
    };

    useEffect(() => {
        form.setFieldsValue(props.user);
        setIce1Phone(props.user?.ice1_phone);
        setIce2Phone(props.user?.ice2_phone);
        const genderValue = props.user?.gender === "M" ? "M" : "F";
        setGender(genderValue);
        setBirthDate(props.user?.birthdate);
    }, [props.user, form]);

    return (
        <>
            <Drawer
                title="Edit User Profile"
                placement="right"
                onClose={props.handleClosed}
                open={props.isOpen}
                zIndex={100}
            >
                <div>
                    <Form form={form} layout="vertical" autoComplete="off">
                        <Form.Item label="First Name" name="first_name">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Last Name" name="last_name">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="middle_name"
                            label="Middle Name"
                            colon={false}
                        >
                            <Input />
                        </Form.Item>
                        <div className="flex items-center gap-3">
                            <Form.Item
                                colon={false}
                                label="Gender"
                                name="gender"
                                className="w-1/2 mb-2 pr-3"
                            >
                                <Select
                                    value={gender}
                                    onChange={(e) => {
                                        setGender(e);
                                    }}
                                    className="w-1/2 mb-2"
                                    options={[
                                        {
                                            value: "M",
                                            label: "Male",
                                        },
                                        {
                                            value: "F",
                                            label: "Female",
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                name="birthdate"
                                label="Date Of Birth"
                                className="w-1/2 mb-2"
                                colon={false}
                            >
                                <Input
                                    type="date"
                                    onChange={handleDateOfBirthChange}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item
                            label="Primary Emergency Number"
                            name="ice1_phone"
                            colon={false}
                        >
                            <PhoneInput
                                className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                                defaultCountry="GH"
                                name="ice1_phone"
                                value={ice1_phone}
                                onChange={(e) => setIce1Phone(e)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Secondary Emergency Number"
                            name="ice2_phone"
                            colon={false}
                        >
                            <PhoneInput
                                className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                                defaultCountry="GH"
                                name="ice2_phone"
                                value={ice2_phone}
                                onChange={(e) => setIce2Phone(e)}
                            />
                        </Form.Item>
                        <div className="flex gap-2 justify-end items-end w-full">
                            <CustomButton
                                data-testid="updateProfile"
                                onClick={submit}
                            >
                                <div>Update</div>
                            </CustomButton>
                            <CustomButton
                                data-testid="cancelEdit"
                                onClick={props.handleClosed}
                                variant="secondary"
                            >
                                <div>Cancel</div>
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </Drawer>
        </>
    );
};

export default EditProfile;
