import { useState, useEffect, useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { Box } from "@mui/material";
import { Pickup } from "../../types";
import CustomButton from "../../components/CustomButton";
import { AddOutlined } from "@mui/icons-material";
import { base_api_request } from "../../utils/api";
import PageLayout from "../../components/layouts/PageLayout";
// import { Branch } from './branch';
import CreatePickup from "./CreatePickup";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

const PickupPoints = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pickup_point, setPickupPoint] = useState<Pickup[]>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const loadingPointColumn = useMemo<MRT_ColumnDef<Pickup>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.name}`,

        header: "Pickup Point Name",
      },
      {
        accessorFn: (row) => `${row?.landmark}`,
        Cell: ({ cell }: { cell: any }) => (
          <Box>{cell.getValue() === "null" ? "N/A" : cell.getValue()}</Box>
        ),
        header: "Landmark",
      },
      {
        accessorFn: (row) => `${row?.location}`,
        Cell: ({ cell }: { cell: any }) => (
          <Box>{cell.getValue() === "null" ? "N/A" : cell.getValue()}</Box>
        ),
        header: "Location",
      },
      {
        accessorFn: (row) => `${row?.region?.name}`,

        header: "Region",
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Date Created",
        Cell: ({ cell }: { cell: any }) => (
          <Box>
            {cell.getValue() === "null"
              ? "N/A"
              : moment(cell.getValue()).format("LL")}
          </Box>
        ),
      },
    ],
    []
  );

  const getLoadingPoints = async () => {
    setLoading(true);
    try {
      const res = await base_api_request.get(
        `${RouteConstants.ALL_PICKUP_POINTS}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc`
      );
      setPickupPoint(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    // getDetails();
    getLoadingPoints();

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <PageLayout>
      <CreatePickup isOpen={open} handleClosed={() => setOpen(false)} />
      <div className="col-span-3 mx-4 my-6 h-full">
        <div className="w-full overflow-auto mb-8 relative">
          <div className=" my-4">
            <div className="flex justify-between items-center mb-5">
              <h3 className="font-bold py-2 text-2xl">Pickup Points</h3>
              <CustomButton
                data-testid="createPickUpPoints"
                onClick={() => setOpen(true)}
              >
                <div className="flex gap-1 text-white items-center">
                  <AddOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <div>Create Pickup Point</div>
                </div>
              </CustomButton>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-8">
            <MaterialReactTable
              muiTablePaperProps={{
                sx: {
                  borderRadius: "0",
                },
              }}
              data={pickup_point}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              enableTopToolbar={false}
              manualPagination
              enableSorting={false}
              columns={loadingPointColumn}
              rowCount={meta?.total}
              state={{ pagination, isLoading: loading }}
              initialState={{
                showColumnFilters: false,
                density: "compact",
              }}
              enableColumnResizing
              onPaginationChange={setPagination}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default PickupPoints;
