import { useState, useEffect } from "react";
import { getCookie } from "../utils/functions";
import { ACCOUNTS_URL } from "../utils/constants";
import { Spinner } from "@material-tailwind/react";
import UnAuthorizedAccess from "../pages/unAuthorizedAccess";
import { getBranchRoles } from "../functions/getBranchRoles";

const ProtectedRoute = ({ children }: any) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const token = getCookie("oya_token");

  useEffect(() => {
    getBranchRoles().then((assignedRoles) => {
      // console.log(assignedRoles)
      // Check if the user has an authorized role
      const authorizedRoles = ["OPERATIONS_OWNER", "OPERATIONS_EDITOR"];

      const hasAuthorizedRole = assignedRoles
        ?.map((user: any) => authorizedRoles.includes(user?.account_type?.code))
        .includes(true);

      if (hasAuthorizedRole) {
        //console.log("Authorized user ");
        setIsChecking(false);
        setIsAuthorized(true);
      } else {
        //console.log("Unauthorized user");
        setIsChecking(false);
        setIsAuthorized(false);
      }
    });

    // eslint-disable-next-line
  }, []);

  if (token) {
    if (isChecking) {
      return (
        <div className="flex justify-center items-center h-screen">
          <Spinner className="h-10 w-10" />
        </div>
      );
    } else {
      if (isAuthorized) {
        return children;
      } else {
        return <UnAuthorizedAccess />;
      }
    }
  }
  return (window.location.href = `${ACCOUNTS_URL}/login?appref=${window.location.href}`);
};

export default ProtectedRoute;
