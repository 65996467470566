import React, { useState } from "react";
import { Modal, Spin, notification } from "antd";
import CustomButton from "../CustomButton";
import axios from "axios";
import { getCookie } from "../../utils/functions";
import { AUTH_MS_URL } from "../../utils/constants";

const BlockUser = ({
    userId,
    userName,
    isOpen,
    handleClosed,
    isBlocked,
}: {
    userId: number;
    userName: string;
    isOpen: boolean;
    handleClosed: () => void;
    isBlocked: boolean;
}) => {
    const [loading, setLoading] = useState(false);
    const token = getCookie("oya_token");

    const submit = async () => {
        setLoading(true);
        try {
            //console.log(userId);
            await axios.put(
                `${
                    isBlocked
                        ? `${AUTH_MS_URL}/v2/unblock-aacount`
                        : `${AUTH_MS_URL}/v2/block-aacount`
                }`,
                { id: userId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setLoading(false);
            handleClosed();
            if (isBlocked) {
                notification.success({
                    message: `${userName} has been unblocked`,
                });
            } else {
                notification.success({
                    message: `${userName} has been blocked`,
                });
            }
        } catch (e: any) {
            setLoading(false);
            notification.error({
                message: e?.response?.data?.message,
            });
            console.log(e);
        }
    };

    return (
        <Modal
            title={`${isBlocked ? "Unblock Account" : "Block Account"}`}
            open={isOpen}
            onCancel={handleClosed}
            footer={null}
        >
            <Spin spinning={loading}>
                <div>
                    {isBlocked ? (
                        <div className="my-3 text-lg">
                            Are you sure you want to unblock{" "}
                            <span className="font-semibold">{userName}</span>
                        </div>
                    ) : (
                        <div className="my-3 text-lg">
                            Are you sure you want to block{" "}
                            <span className="font-semibold">{userName}</span>
                        </div>
                    )}
                    <div className="w-full mt-4 justify-end items-end flex gap-3">
                        <CustomButton
                            data-testid="confirmBlock"
                            onClick={() => submit()}
                        >
                            Confirm
                        </CustomButton>
                        <CustomButton
                            data-testid="cancelBlock"
                            variant="secondary"
                            onClick={() => handleClosed()}
                        >
                            Cancel
                        </CustomButton>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default BlockUser;
