import { useState, useEffect, useMemo, useCallback } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { Staff, Branch } from "./branch";
import { Box } from "@mui/material";

import { AddOutlined } from "@mui/icons-material";
import { base_api_request, auth_api_call } from "../../utils/api";
import PageLayout from "../../components/layouts/PageLayout";
import AddStaff from "./AddStaff";
import CustomButton from "../../components/CustomButton";

import { Option } from "../../types";

import AsyncSelect from "react-select/async";
import { Input } from "antd";
import PhoneInput, { Value } from "react-phone-number-input";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { debounce } from "lodash";

const StaffList = () => {
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState<Staff[]>([]);

  const [open, setOpen] = useState(false);
  const [accountId, setAccountId] = useState<number | undefined>();
  const [account_type, setAccountType] = useState<Option[]>([]);
  const [phone, setPhone] = useState<Value | undefined>("");
  const [staffName, setStaffName] = useState("");

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const loadAccountOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await auth_api_call.get(
          `${RouteConstants.ACCOUNT_TYPES}?filters=${JSON.stringify(
            filterData
          )}`
        );

        const searchOptions = res.data?.payload?.items?.map((item: Branch) => ({
          label: item?.name,
          value: item?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  const getAccountTypes = async () => {
    //setFetchingTypes(true);
    const includedNames = [
      "PARCEL_OFFICER",
      "FIELD_OFFICER",
      "BRANCH_SUPERVISOR",
      "CONDUCTOR",
      "DRIVER",
      "PORTER",
      "GENERAL_MANAGER",
      "STATION_MASTER",
      "TRUSTEE",
    ];
    try {
      const res = await auth_api_call.get(`${RouteConstants.ACCOUNT_TYPES}`);
      const rawData = res.data?.payload?.items;
      const filteredData = rawData.filter((item: Branch) =>
        includedNames.includes(item?.code)
      );
      // console.log(filteredData)
      setAccountType(
        filteredData.map((item: Branch) => ({
          label: item?.name,
          value: item?.id,
        }))
      );
    } catch (e) {
      // notification.error({
      //   message: 'Access denied. Account activation required',
      // });
      console.log(e);
    }
  };

  //fetch staff
  const getStaff = async () => {
    setLoading(true);
    let defaultFilter = [];
    try {
      if (accountId) {
        defaultFilter.push({
          f: "account_type.id",
          o: "=",
          p: [accountId],
        });
      }

      if (staffName) {
        defaultFilter.push({
          f: "user.name",
          o: "contains",
          p: [staffName],
        });
      }

      if (phone) {
        defaultFilter.push({
          f: "user.phone",
          o: "=",
          p: [encodeURIComponent(phone)],
        });
      }

      const res = await base_api_request.get(
        `${RouteConstants.ALL_STAFF}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&filters=${JSON.stringify(defaultFilter)}&sorting=created_at:desc`
      );
      // console.log(res.data?.payload?.items);
      setStaff(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleReload = useCallback(() => {
    getStaff();

    // eslint-disable-next-line
  }, [loading]);

  //table columns
  const staffColumn = useMemo<MRT_ColumnDef<Staff>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.user?.name}`,

        header: "Name",
      },
      {
        accessorFn: (row) => `${row?.user?.phone}`,

        header: "Phone",
      },
      {
        accessorFn: (row) => `${row?.account_type?.name}`,
        header: "User Type",
        size: 80,
      },
      {
        accessorFn: (row) => `${row?.branch?.name}`,
        header: "Associate Branch",
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Date Added",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell.getValue()).format("LL")}</Box>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getAccountTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getStaff();
    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, accountId, phone, staffName]);

  return (
    <PageLayout>
      <AddStaff
        isOpen={open}
        onCancel={() => setOpen(false)}
        handleReload={handleReload}
      />
      <div className="col-span-3 mx-4 my-6 h-full">
        <div className="w-full overflow-auto mb-8 relative">
          <div className="my-4">
            <div className="flex justify-between items-center w-full">
              <h3 className="font-bold py-2 text-2xl">Staff Members</h3>

              <CustomButton
                data-testid="addStaff"
                onClick={() => setOpen(true)}
                disabled={loading}
              >
                <div className="flex gap-2 items-center justify-center">
                  <div>Add staff</div>
                  <AddOutlined
                    sx={{
                      width: 20,
                      height: 20,
                      color: "white",
                    }}
                  />
                </div>
              </CustomButton>
            </div>
          </div>

          <div className="shadow border bg-white p-3 rounded-md w-full my-4 flex flex-wrap items-center gap-[30px]">
            <div className="w-[250px]">
              <small>Filter staff by role</small>
              <AsyncSelect
                loadOptions={(inputValue, callback) => {
                  loadAccountOptions(inputValue, callback);
                }}
                onChange={(e: any) => {
                  setAccountId(e?.value);
                }}
                isSearchable
                isClearable
                defaultOptions={account_type}
              />
            </div>
            <div>
              <small>Filter staff by name</small>
              <Input
                className="h-[40px]"
                value={staffName}
                onChange={(e) => setStaffName(e.target.value)}
                placeholder="Search name"
              />
            </div>
            <div>
              <small>Filter staff by phone</small>
              <PhoneInput
                name="phone"
                className="phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                defaultCountry="GH"
                onChange={(e) => {
                  setPhone(e);
                }}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 mb-[100px] mt-2">
            <MaterialReactTable
              muiTablePaperProps={{
                sx: {
                  borderRadius: "0",
                },
              }}
              data={staff}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              enableTopToolbar={false}
              manualPagination
              enableSorting={false}
              columns={staffColumn}
              rowCount={meta?.total}
              state={{ pagination, isLoading: loading }}
              initialState={{
                showColumnFilters: false,
                density: "compact",
              }}
              enableColumnResizing
              onPaginationChange={setPagination}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default StaffList;
