import { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { Branch } from "../../pages/branches/branch";
import { Option } from "../../types";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";

interface filterProps {
    onHide: () => void;

    handleBranchChange: (value: any) => void;
}

const LoadingPointFilter = ({
    onHide,

    handleBranchChange,
}: filterProps) => {
    const [branches, setBranches] = useState<Option[]>([]);

    const getBranches = async () => {
        try {
            const res = await base_api_request.get(RouteConstants.ALL_BRANCHES);
            setBranches(
                res.data?.payload?.items?.map((item: any) => ({
                    label: `${item?.name}`,
                    value: item?.id,
                }))
            );
        } catch (e) {
            console.log(e);
        }
    };

    const loadBranchOptions = debounce(
        async (inputValue: string, callback: (options: Option[]) => void) => {
            let filterData = [
                {
                    f: "name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_BRANCHES}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const searchOptions = res.data?.payload?.items?.map(
                    (item: Branch) => ({
                        label: item?.name,
                        value: item?.id,
                    })
                );

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    useEffect(() => {
        getBranches();
    }, []);

    return (
        <div className="px-3 py-[20px] bg-white border-l-[1px] border-gray-500 h-full overflow-y-auto">
            {/* header */}
            <div className="mb-2 flex justify-between items-center">
                <Typography variant="h5" color="blue-gray">
                    Filter
                </Typography>
                <ChevronDoubleRightIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => onHide()}
                />
            </div>

            {/* content */}

            <div className="mt-5 w-full">
                <p>Branch</p>
                <AsyncSelect
                    defaultOptions={branches}
                    placeholder="Select branch"
                    loadOptions={(inputValue, callback) => {
                        loadBranchOptions(inputValue, callback);
                    }}
                    onChange={(val) => {
                        handleBranchChange(val?.value);
                    }}
                    isClearable
                />
            </div>

            <div className="flex justify-end mt-5 lg:hidden">
                <button
                    data-testid="applyLPPriceFilter"
                    className="border-[1px] border-oya-ghana-red text-oya-ghana-red px-4 py-1 rounded-[5px] hover:bg-oya-ghana-red hover:text-white"
                    onClick={() => onHide()}
                >
                    Apply filter
                </button>
            </div>
        </div>
    );
};

export default LoadingPointFilter;
