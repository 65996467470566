import { useState, useEffect, useMemo } from "react";
import PageLayout from "../components/layouts/PageLayout";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box, ListItemIcon, MenuItem } from "@mui/material";
import { base_api_request } from "../utils/api";
import { PencilIcon } from "@heroicons/react/24/outline";
import { AddOutlined } from "@mui/icons-material";
import CustomButton from "../components/CustomButton";
import { Town } from "../types";
import CreateTown from "../components/Towns/CreateTown";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import EditTown from "../components/Towns/EditTowns";

const Towns = () => {
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [branches, setBranches] = useState<Town[]>([]);
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const columns = useMemo<MRT_ColumnDef<Town>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.id}`,

        header: "ID",
      },
      {
        accessorFn: (row) => `${row?.name}`,

        header: "Name",
      },
      {
        accessorFn: (row) => `${row?.region?.name}`,

        header: "Region name",
      },
      {
        accessorFn: (row) => `${row?.alias ?? "N/A"}`,

        header: "Alias",
      },
      {
        accessorFn: (row) => `${row?.supported}`,
        header: "Supported",
        Cell: ({ cell }: any) => (
          <Box>{cell.getValue() === "false" ? "No" : "Yes"}</Box>
        ),
      },
    ],
    []
  );

  const getTowns = async () => {
    setLoading(true);

    try {
      const res = await base_api_request.get(
        `${RouteConstants.TOWNS}?limit=${pagination.pageSize}&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc`
      );
      setBranches(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTowns();
    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize]);
  return (
    <PageLayout>
      <CreateTown
        refresh={() => getTowns()}
        isOpen={open}
        handleClosed={() => setOpen(false)}
      />
      <EditTown
        dataToEdit={selectedItem}
        handleClosed={() => setShowEdit(false)}
        isEditOpened={showEdit}
        handleReFetch={() => {
          getTowns();
        }}
      />
      <div className="w-full overflow-auto mb-8 relative">
        <div className="col-span-3 mx-4 my-6 h-full">
          <div className="flex justify-between items-center mb-5">
            <h3 className="font-bold py-2 text-2xl">Towns</h3>
            <CustomButton
              data-testid="createTown"
              onClick={() => setOpen(true)}
            >
              <div className="flex gap-1 text-white items-center">
                <AddOutlined
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                <div>Create a Town</div>
              </div>
            </CustomButton>
          </div>
          <div className="grid grid-cols-1">
            <div className="w-full h-full mb-8">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "0",
                  },
                }}
                enableRowActions={true}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <MenuItem
                    key={1}
                    onClick={() => {
                      //navigate(`/schedule/${row.original?.id}/details`);
                      setSelectedItem(row.original);
                      setShowEdit(true);
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <PencilIcon className="w-5 h-5 text-oya-ghana-red" />
                    </ListItemIcon>
                    Edit Town
                  </MenuItem>,
                ]}
                data={branches}
                enableTopToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                manualPagination
                enableSorting={false}
                columns={columns}
                rowCount={meta?.total}
                state={{ pagination, isLoading: loading }}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                  columnPinning: {
                    left: ["mrt-row-expand", "mrt-row-select"],
                    right: ["mrt-row-actions"],
                  },
                }}
                enableColumnResizing
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    console.log(row.original);
                    // func.redirect(`/hsh/${row.original?.id}`);
                    //redirect(`/branch/${row.original?.id}`);
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                onPaginationChange={setPagination}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Towns;
