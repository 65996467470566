import { useEffect, useState } from "react";
import { Input, Form, Modal, notification, Spin, Select } from "antd";
import { Button, Typography } from "@material-tailwind/react";
import PhoneInput, { Value } from "react-phone-number-input";
import CustomButton from "../CustomButton";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { getCompanyTypes } from "../../utils/helpers/commonApiCalls";
import { Company_types } from "../../types";

const CreateCompany = ({
    reload,
    handleClosed,
    isOpen,
}: {
    reload: () => void;
    handleClosed: () => void;
    isOpen: boolean;
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [loadCompanyTypes, setLoadCompanyTypes] = useState<boolean>(false);
    const [phone, setPhone] = useState<Value | undefined>("");
    const [contact_phone, setContactPhone] = useState<Value | undefined>("");
    const [companyTypes, setCompanyTypes] = useState<Company_types[]>([]);
    const [selectedCompanyType, setSelectedCompType] = useState<number>(0);

    const validateEmail = (
        rule: any,
        value: string,
        callback: (message?: string) => void
    ) => {
        // Regular expression for basic email validation
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        if (!value || emailRegex.test(value)) {
            callback();
        } else {
            callback("Please enter a valid email address");
        }
    };

    const submit = async () => {
        try {
            setLoading(true);
            const values = form.getFieldsValue();
            await base_api_request.post(RouteConstants.ALL_COMPANIES, {
                ...values,
                phone,
                contact_phone,
                company_type_id: selectedCompanyType,
            });
            notification.success({
                message: "Company has been created",
            });
            setLoading(false);
            handleClosed();
            reload();
        } catch (e: any) {
            notification.error({
                message: e?.response?.data?.message,
            });
            setLoading(false);
            console.log(e);
        }
    };

    useEffect(() => {
        setLoadCompanyTypes(true);
        getCompanyTypes()
            .then((res) => {
                setCompanyTypes(
                    res.payload?.items?.map((item: any) => ({
                        value: item?.id,
                        label: item?.name,
                    }))
                );
                setLoadCompanyTypes(false);
            })
            .catch((e) => {
                setLoadCompanyTypes(false);
                console.log(e);
            });
    }, []);

    return (
        <Modal open={isOpen} onCancel={handleClosed} footer={null}>
            <Spin spinning={loading}>
                <div>
                    <Typography variant="h5">Create a new Company</Typography>
                    <Form
                        form={form}
                        className="mt-5"
                        layout="vertical"
                        autoComplete="none"
                    >
                        <div className="flex gap-3 items-center">
                            <Form.Item
                                className="w-1/2"
                                required
                                label="Company Name"
                                name="name"
                            >
                                <Input size="large" placeholder="name" />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2"
                                required
                                name="code"
                                label="Company Code"
                            >
                                <Input size="large" placeholder="code" />
                            </Form.Item>
                        </div>

                        <Form.Item
                            required
                            name="phone"
                            label="Company Phone Number"
                        >
                            <PhoneInput
                                className="phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                                defaultCountry="GH"
                                onChange={(e) => {
                                    setPhone(e);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Email is required",
                                },
                                { validator: validateEmail },
                            ]}
                            name="email"
                            label="Company Email"
                        >
                            <Input
                                size="large"
                                placeholder="eg.admin@domain.com"
                            />
                        </Form.Item>
                        <div className="flex gap-2">
                            <Form.Item
                                label="Contact Person Name"
                                name="contact_name"
                                className="w-1/2"
                                required
                            >
                                <Input
                                    size="large"
                                    placeholder="Enter person name"
                                />
                            </Form.Item>
                            <Form.Item
                                required
                                label="Contact Person Mobile"
                                name="contact_phone"
                            >
                                <PhoneInput
                                    className="phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                                    defaultCountry="GH"
                                    onChange={(e) => {
                                        setContactPhone(e);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item
                            label="Company Operation Type"
                            required
                            name="company_type_id"
                            className="w-full"
                        >
                            <Select
                                size="large"
                                options={companyTypes}
                                onChange={(value) => {
                                    setSelectedCompType(value);
                                }}
                                loading={loadCompanyTypes}
                            />
                        </Form.Item>
                    </Form>
                    <div className="flex w-full justify-end items-end mt-10">
                        <div className="flex items-center gap-3">
                            <CustomButton
                                data-testid="submitNewCompany"
                                onClick={submit}
                            >
                                <div>Submit</div>
                            </CustomButton>
                            <Button
                                data-testid="closeNewCompany"
                                disabled={loading}
                                variant="outlined"
                                size="sm"
                                onClick={handleClosed}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default CreateCompany;
