import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import CustomButton from "../CustomButton";
import { Input, Form, Drawer, notification, Spin } from "antd";

import PhoneInput, { Value } from "react-phone-number-input";

import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

interface modalProps {
  isEditOpened: boolean;
  handleClosed: () => void;
  dataToEdit: any;
  handleReFetch: () => void;
}

const EditLoadingPoint = ({
  isEditOpened,
  handleClosed,
  dataToEdit,
  handleReFetch,
}: modalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [phone, setPhone] = useState<Value | undefined>("");

  const [label, setLabel] = useState("");

  //on page load
  useEffect(() => {
    setPhone(dataToEdit?.phone);
    setLabel(dataToEdit?.label);
    form.setFieldValue("label", dataToEdit?.label);
    form.setFieldValue("phone", dataToEdit?.phone);

    // eslint-disable-next-line
  }, [dataToEdit]);

  //editBranch
  const submit = async () => {
    try {
      setLoading(true);

      await base_api_request.put(
        `${RouteConstants.ALL_LOADING_POINTS}/${dataToEdit?.id}`,
        {
          // ...values,
          label,
          phone: phone,
          route_id: dataToEdit?.route_id,
          branch_id: dataToEdit?.station?.id,
        }
      );
      setLoading(false);
      notification.success({
        message: "Loading point edited successfully",
      });
      handleClosed();
      handleReFetch();
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message,
      });
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Drawer
        title="Edit Loading Point"
        placement="right"
        onClose={handleClosed}
        open={isEditOpened}
        zIndex={100}
        size="default"
      >
        <Spin spinning={loading}>
          <div className="">
            <div className="h-screen">
              <Form form={form} layout="vertical" autoComplete="none">
                <div>
                  <Form.Item
                    required
                    className="w-full"
                    name="label"
                    label="Loading Point Label"
                  >
                    <Input
                      size="large"
                      placeholder="alias"
                      onChange={(e) => setLabel(e.target.value)}
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    className="w-full"
                    label="Loading Point Contact Phone"
                    name={"phone"}
                    required
                  >
                    <PhoneInput
                      name="phone"
                      className="phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                      defaultCountry="GH"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e);
                      }}
                    />
                  </Form.Item>
                </div>
              </Form>
              <div className="flex w-full justify-end items-end mt-5 pb-5">
                <div className="flex items-center gap-3">
                  <CustomButton
                    data-testid="submitNewBranch"
                    disabled={loading}
                    onClick={submit}
                  >
                    Submit
                  </CustomButton>
                  <Button
                    data-testid="closeNewBranch"
                    disabled={loading}
                    variant="outlined"
                    size="sm"
                    onClick={handleClosed}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditLoadingPoint;
