interface statusProps {
    name: string
}

const StatusTag = ({ name }: statusProps) => {
    let bgColorClass;

    if (name === 'LOADING') {
        bgColorClass = 'bg-green-500';
    } else if (name === 'SCHEDULED') {
        bgColorClass = 'bg-yellow-500';
    } else {
        bgColorClass = 'bg-red-500';
    }

    return (
        <div className={`px-3 py-1 ${bgColorClass} text-white rounded-[30px] flex justify-center items-center w-fit`}>
            <p className="text-xs lg:text-md">{name}</p>
        </div>
    );
};

export default StatusTag
