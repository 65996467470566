import { useState, useEffect, useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { Box, ListItemIcon, MenuItem } from "@mui/material";
import { LoadingPoint } from "../../types";
import CustomButton from "../../components/CustomButton";
import { AddOutlined } from "@mui/icons-material";
import { base_api_request } from "../../utils/api";
import PageLayout from "../../components/layouts/PageLayout";
// import { Branch } from './branch';
import CreateLoadingPoint from "./CreateLoadingPoint";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { PencilIcon } from "@heroicons/react/24/outline";
import EditLoadingPoint from "../../components/Branches/EditLoadingPoint";

const LoadingPointsList = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading_point, setLoadingPoint] = useState<LoadingPoint[]>([]);
  const [reFetch, setReFetch] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState<LoadingPoint | null>();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const loadingPointColumn = useMemo<MRT_ColumnDef<LoadingPoint>[]>(
    () => [
      {
        accessorFn: (row) =>
          `${row?.source?.name} - ${row?.destination.name} ${
            row?.label ? `(${row?.label})` : ""
          }`,
        Cell: ({ cell }: { cell: any }) => (
          <Box>{cell.getValue() === "null" ? "N/A" : cell.getValue()}</Box>
        ),
        header: "Loading Point",
        size: 300,
      },
      {
        accessorFn: (row) => `${row?.station?.code}`,

        header: "Station Code ",
      },
      {
        accessorFn: (row) => `${row?.station?.name}`,

        header: "Station Name",
      },
      {
        accessorFn: (row) => `${row?.phone ?? ""}`,

        header: "Phone Number",
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Date Created",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell.getValue()).format("LL")}</Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.updated_at}`,
        header: "Date Modified",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell.getValue()).format("LL")}</Box>
        ),
      },
    ],
    []
  );

  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  const getLoadingPoints = async () => {
    setLoading(true);
    try {
      const res = await base_api_request.get(
        `${RouteConstants.ALL_LOADING_POINTS}?limit=${
          pagination.pageSize
        }&offset=${Number(pagination.pageIndex * pagination.pageSize)}`
      );
      console.log(res.data?.payload?.items);
      setLoadingPoint(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // getDetails();
    getLoadingPoints();

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, reFetch]);

  return (
    <PageLayout>
      <CreateLoadingPoint
        isOpen={open}
        handleClosed={() => setOpen(false)}
        handleReFetch={handleReFetch}
      />
      <EditLoadingPoint
        isEditOpened={showEdit}
        dataToEdit={selectedItem}
        handleClosed={() => {
          setShowEdit(false);
        }}
        handleReFetch={() => {
          getLoadingPoints();
        }}
      />
      <div className="col-span-3 mx-4 my-6 h-full">
        <div className="w-full overflow-auto mb-8 relative">
          {/* <button
            className='flex text-gray-700 items-center gap-2 my-3 hover:text-black'
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewOutlined
              sx={{
                width: 15,
                height: 15,
              }}
            />
            Back
          </button> */}

          <div className=" my-4">
            {/* {loading ? (
              <Skeleton.Input active />
            ) : (
              <div className='flex gap-3 items-center mb-2 text-lg font-semibold'>
                <div>{branch?.name}</div>
                <div>-</div>
                <div>{branch?.bus_company?.name}</div>
              </div>
            )} */}

            <div className="flex justify-between items-center mb-3">
              <h3 className="font-bold py-2 text-2xl">Loading Points</h3>
              <CustomButton
                data-testid="createLoadingPoint"
                onClick={() => setOpen(true)}
              >
                <div className="flex gap-1 text-white items-center">
                  <AddOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <div>Create Loading Point</div>
                </div>
              </CustomButton>
            </div>
          </div>
          <div className="grid grid-cols-1 mb-[100px]">
            <MaterialReactTable
              muiTablePaperProps={{
                sx: {
                  borderRadius: "0",
                },
              }}
              enableRowActions={true}
              renderRowActionMenuItems={({ closeMenu, row }) => [
                <MenuItem
                  key={1}
                  onClick={() => {
                    //navigate(`/schedule/${row.original?.id}/details`);
                    setSelectedItem(row.original);
                    setShowEdit(true);

                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <PencilIcon className="w-5 h-5 text-oya-ghana-red" />
                  </ListItemIcon>
                  Edit Loading Point
                </MenuItem>,
              ]}
              data={loading_point}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              enableTopToolbar={false}
              manualPagination
              enableSorting={false}
              columns={loadingPointColumn}
              rowCount={meta?.total}
              state={{ pagination, isLoading: loading }}
              initialState={{
                showColumnFilters: false,
                density: "compact",
                columnPinning: {
                  left: ["mrt-row-expand", "mrt-row-select"],
                  right: ["mrt-row-actions"],
                },
              }}
              enableColumnResizing
              onPaginationChange={setPagination}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default LoadingPointsList;
