import { useState, useEffect } from "react";
import { Input, Form, Modal, notification, Spin } from "antd";
import { Button, Typography } from "@material-tailwind/react";
import { base_api_request } from "../../utils/api";
import CustomButton from "../../components/CustomButton";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { Branch } from "./branch";
import { Routes } from "../../types";
import PhoneInput, { Value } from "react-phone-number-input";
import { Option } from "../../types";
import debounce from "lodash/debounce";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { getBranches } from "../../utils/helpers/commonApiCalls";
import { getCookie } from "../../utils/functions";

const CreateLoadingPoint = ({
  handleClosed,
  isOpen,
  handleReFetch,
}: {
  handleClosed: () => void;
  isOpen: boolean;
  handleReFetch: () => void;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [branchOptions, setBranchOptions] = useState<Option[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<number | undefined>();
  const [routeOptions, setRouteOptions] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState<number | undefined>();
  const [phone, setPhone] = useState<Value | undefined>("");
  const [findingRoute, setFindingRoute] = useState(false);

  const token = getCookie("oya_token");

  //branches
  const loadBranchOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ALL_BRANCHES}?filters=${JSON.stringify(filterData)}`
        );

        const searchOptions = res.data?.payload?.items?.map((item: Branch) => ({
          label: item?.name,
          value: item?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  //fetchRoutes
  const fetchRoutes = debounce((searchVal: string) => {
    if (searchVal) {
      setFindingRoute(true);
      let filterData = [
        {
          f: "from.name",
          o: "contains",
          p: [`${searchVal}`],
          c: "OR",
        },
        {
          f: "to.name",
          o: "contains",
          p: [`${searchVal}`],
        },
      ];

      base_api_request
        .get(`${RouteConstants.ROUTES}?filters=${JSON.stringify(filterData)}`)
        .then((res) => {
          // console.log(res?.data?.payload)

          const searchOptions = res.data?.payload?.items?.map(
            (item: Routes) => ({
              label: `${item?.from?.name} - ${item?.to?.name}`,
              value: item?.id,
            })
          );
          setRouteOptions(searchOptions);
          setFindingRoute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, 800);

  //create loading point
  const submit = async () => {
    try {
      setLoading(true);
      const data = form.getFieldsValue();
      await base_api_request.post(
        RouteConstants.ALL_LOADING_POINTS,
        {
          ...data,
          phone,
          route_id: selectedRoute,
          branch_id: selectedBranch,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      notification.success({
        message: "New Loading Point added successfully",
      });
      setLoading(false);
      handleClosed();
      handleReFetch();
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message,
      });
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getBranches()
      .then((res) => {
        setBranchOptions(
          res.payload?.items?.map((item: Branch) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <Modal open={isOpen} onCancel={handleClosed} footer={null}>
      <Spin spinning={loading}>
        <div>
          <Typography variant="h5">Create loading point</Typography>
          <Form
            form={form}
            className="mt-5"
            layout="vertical"
            autoComplete="none"
          >
            <Form.Item
              className="w-full"
              label="Loading Point Name"
              name="label"
              required
            >
              <Input placeholder="label" />
            </Form.Item>
            <Form.Item className="w-full" label="Phone" name="phone" required>
              <PhoneInput
                name="phone"
                className="phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                defaultCountry="GH"
                onChange={(e) => {
                  setPhone(e);
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-full"
              label="Select Branch"
              name="branch_id"
              required
            >
              <AsyncSelect
                loadOptions={(inputValue, callback) => {
                  loadBranchOptions(inputValue, callback);
                }}
                onChange={(e: any) => {
                  setSelectedBranch(e?.value);
                }}
                isSearchable
                isClearable
                defaultOptions={branchOptions}
              />
            </Form.Item>
            <Form.Item
              className="w-full"
              label="Select a Route"
              name="route_id"
              required
            >
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Search..."
                isLoading={findingRoute}
                isClearable={true}
                options={routeOptions}
                onChange={(data: any) => setSelectedRoute(data?.value)}
                onInputChange={(val: any) => fetchRoutes(val)}
              />
            </Form.Item>
            {/* <Form.Item name='code' label='Specify the Company code'>
              <Input placeholder='code' />
            </Form.Item> */}
          </Form>
          <div className="flex w-full justify-end items-end mt-10">
            <div className="flex items-center gap-3">
              <CustomButton
                data-testid="continueToCreate"
                disabled={loading}
                onClick={submit}
              >
                Continue
              </CustomButton>
              <Button
                data-testid="closeForm"
                disabled={loading}
                variant="outlined"
                size="sm"
                onClick={handleClosed}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default CreateLoadingPoint;
