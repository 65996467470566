import { useState } from "react";
import { Form, Modal, notification, Spin } from "antd";
import { Button, Typography } from "@material-tailwind/react";
import { base_api_request } from "../../utils/api";
import CustomButton from "../../components/CustomButton";
import Select from "react-select";
import { Pickup } from "../../types";
// import PhoneInput, { Value } from 'react-phone-number-input';
import { Option } from "../../types";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import debounce from "lodash/debounce";

const CreateMidroute = ({
    handleClosed,
    isOpen,
    reload,
}: {
    handleClosed: () => void;
    isOpen: boolean;
    reload: () => void;
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [pickupPointOptions, setPickupPointOptions] = useState<Option[]>([]);
    const [fetchingPickup, setFetchingPickup] = useState(false);
    const [fetchingRoute, setFetchingRoute] = useState(false);

    const [routeOptions, setRouteOptions] = useState<Option[]>([]);

    //loadRoute Options
    const loadRouteOptions = debounce((inputValue) => {
        if (inputValue) {
            setFetchingRoute(true);
            let filterData = [
                {
                    f: "source.name",
                    o: "contains",
                    p: [`${inputValue}`],
                    c: "OR",
                },
                {
                    f: "destination.name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            base_api_request
                .get(
                    `${
                        RouteConstants.ALL_LOADING_POINTS
                    }?filters=${JSON.stringify(filterData)}`
                )
                .then((res) => {
                    // console.log(res.data)
                    const searchOptions = res.data?.payload?.items?.map(
                        (item: any) => ({
                            label: `${item?.source?.name} - ${item?.destination?.name}`,
                            value: item?.id,
                        })
                    );
                    setRouteOptions(searchOptions);
                    setFetchingRoute(false);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, 300);

    //loadPickup point Options
    const loadPickupOptions = debounce((inputValue) => {
        if (inputValue) {
            setFetchingPickup(true);

            let filterData = [
                {
                    f: "name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            base_api_request
                .get(
                    `${
                        RouteConstants.ALL_PICKUP_POINTS
                    }?filters=${JSON.stringify(filterData)}`
                )
                .then((res) => {
                    // console.log(res.data)
                    const searchOptions = res.data?.payload?.items?.map(
                        (item: Pickup) => ({
                            label: item?.name,
                            value: item?.id,
                        })
                    );

                    setPickupPointOptions(searchOptions);
                    setFetchingPickup(false);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, 300);

    //handle submit
    const submit = async () => {
        try {
            setLoading(true);
            const data = form.getFieldsValue();
            await base_api_request.post(RouteConstants.MIDROUTES, {
                station_route_id: data?.route_id?.value,
                pickup_id: data?.pickup_id?.value,
            });
            setLoading(false);
            notification.success({
                message: "New Midroute created successfully",
            });
            setLoading(false);
            form.resetFields();
            handleClosed();
            reload();
        } catch (e: any) {
            notification.error({
                message: e?.response?.data?.message,
            });
            setLoading(false);
            console.log(e);
        }
    };

    return (
        <Modal open={isOpen} onCancel={handleClosed} footer={null}>
            <Spin spinning={loading}>
                <div>
                    <Typography variant="h5">Create Mid-Route</Typography>
                    <Form
                        form={form}
                        className="mt-5"
                        layout="vertical"
                        autoComplete="none"
                    >
                        <Form.Item
                            className="w-full"
                            label="Select Station Route"
                            name="route_id"
                            required
                        >
                            <Select
                                isSearchable
                                isClearable
                                options={routeOptions}
                                isLoading={fetchingRoute}
                                onInputChange={(val) => loadRouteOptions(val)}
                                noOptionsMessage={() => "E.g: Circle - Kumasi"}
                            />
                        </Form.Item>

                        <Form.Item
                            className="w-full"
                            label="Select Pickup Point"
                            name="pickup_id"
                            required
                        >
                            <Select
                                options={pickupPointOptions}
                                isSearchable
                                isClearable
                                onInputChange={(val) => loadPickupOptions(val)}
                                isLoading={fetchingPickup}
                                noOptionsMessage={() =>
                                    "E.g: Kasoa Police Station"
                                }
                            />
                        </Form.Item>
                    </Form>
                    <div className="flex w-full justify-end items-end mt-10">
                        <div className="flex items-center gap-3">
                            <CustomButton
                                data-testid="continueNewMidRoute"
                                disabled={loading}
                                onClick={submit}
                            >
                                Continue
                            </CustomButton>
                            <Button
                                data-testid="closeNewMidRoute"
                                disabled={loading}
                                variant="outlined"
                                size="sm"
                                onClick={handleClosed}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default CreateMidroute;
