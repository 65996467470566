import { useState, useEffect, useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box, ListItemIcon, MenuItem } from "@mui/material";
import { Schedule } from "./branch";
import moment from "moment";
import {
  AddOutlined,
  ForwardOutlined,
  MoveUpOutlined,
} from "@mui/icons-material";
import { base_api_request } from "../../utils/api";
import PageLayout from "../../components/layouts/PageLayout";
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from "date-fns";
import CustomButton from "../../components/CustomButton";
import CreateSchedule from "../../components/Branches/CreateSchedule";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import {
  ChevronDoubleLeftIcon,
  ClipboardDocumentIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import SchedulesFilter from "../../components/shared/schedulesFilter";
import { Input, Modal, Spin, notification } from "antd";
import StatusTag from "../../components/shared/statusTag";
import { useNavigate } from "react-router";

const Schedules = () => {
  const [loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<
    Schedule | undefined
  >();

  const [moneyCollected, setMoneyCollected] = useState<string | null>("");
  const [moneyCollectedModal, setMoneyCollectedModal] =
    useState<boolean>(false);
  const [selectedBus, setSelectedBus] = useState<number | undefined>();
  const [selectedBranch, setSelectedBranch] = useState<number | undefined>();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPorter, setSelectedPorter] = useState<any>(undefined);
  const [tripCode, setTripCode] = useState<string | null>("");

  const [copied, setCopied] = useState(false);

  const navigate = useNavigate();

  const [defaultDates, setDefaultDates] = useState<any>([
    startOfWeek(new Date()),
    endOfWeek(new Date()),
  ]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  //for filter drawer
  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter = () => {
    setOpenFilter(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(true);
  };

  let filter: any = [];

  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates(null);
    } else if (selectedDate instanceof Array && selectedDate?.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const scaleBus = async (schedule: any) => {
    setLoading(true);

    try {
      await base_api_request.put(
        `${RouteConstants.SCHEDULES}/${schedule?.id}/scale`
      );
      getSchedules();
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    }
    setLoading(false);
  };

  const awayBus = async (schedule: any) => {
    setLoading(true);

    try {
      await base_api_request.put(
        `${RouteConstants.SCHEDULES}/${schedule?.id}/away`,
        {
          money_collected: moneyCollected,
        }
      );
      setMoneyCollectedModal(false);
      notification.success({
        message: "Bus awayed",
      });
      getSchedules();
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    }
    setLoading(false);
  };

  //table columns
  const schedulesColumns = useMemo<MRT_ColumnDef<Schedule>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.code}`,
        Cell: ({ cell }: { cell: any }) => (
          <>
            <button
              onClick={() => {
                handleCopy(cell.getValue());
              }}
              className="flex items-center gap-1"
            >
              <div>{cell.getValue()}</div>

              {copied ? (
                <span className="text-green-500">Copied!</span>
              ) : (
                <ClipboardDocumentIcon className="w-5 h-5 text-gray-500" />
              )}
            </button>
          </>
        ),
        size: 100,
        header: "Trip Code",
      },
      {
        accessorFn: (row) => `${row?.status}`,
        size: 120,
        Cell: ({ cell }: { cell: any }) => <StatusTag name={cell.getValue()} />,
        header: "Status",
      },
      {
        accessorFn: (row) => `${row?.route?.from?.name}`,
        header: "From",
      },
      {
        accessorFn: (row) => `${row?.route?.to?.name}`,
        header: "Destination",
      },

      {
        accessorFn: (row) => `${row?.price?.amount}`,
        size: 100,
        header: "Price",
        Cell: ({ cell }: any) => {
          return (
            <Box>
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "GHS",
              }).format(Number(cell?.getValue()))}
            </Box>
          );
        },
      },
      {
        accessorFn: (row) => `${row?.bus?.reg_number}`,
        size: 100,
        header: "Bus",
      },

      {
        accessorFn: (row) => `${row?.branch?.name}`,
        size: 200,
        header: "Branch",
      },
      {
        accessorFn: (row) => `${row?.branch_supervisor?.name}`,
        header: "Branch Supervisor",
      },
      {
        accessorFn: (row) => `${row?.field_officer?.name}`,
        header: "Field Officer",
      },
      {
        accessorFn: (row) => `${row?.porter?.name}`,
        header: "Porter",
      },
      {
        accessorFn: (row) => `${row?.driver?.name}`,

        header: "Driver",
      },
      {
        accessorFn: (row) => `${row?.full_capacity}`,
        size: 80,
        header: "Full Capacity",
      },
      {
        accessorFn: (row) => `${row?.onboard_passengers}`,
        size: 80,
        header: "Passengers Onboard",
      },
      {
        accessorKey: "creator",
        size: 80,
        header: "Created By",
        accessorFn: (row) => `${row?.creator?.name ?? ""}`,
      },
      {
        accessorFn: (row) => `${row?.passenger_capacity}`,
        size: 80,
        header: "Passenger Capacity",
      },
      {
        accessorFn: (row) => `${row?.departures_at}`,
        header: "Departure Date",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell.getValue()).format("MMM Do YY,h:mm a")}</Box>
        ),
        size: 200,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //get schedules
  const getSchedules = async () => {
    setLoading(true);
    try {
      if (defaultDates) {
        filter.push({
          f: "created_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }
      if (selectedBus) {
        filter.push({
          f: "bus.id",
          o: "=",
          p: [selectedBus],
        });
      }

      if (selectedBranch) {
        filter.push({
          f: "station.id",
          o: "=",
          p: [selectedBranch],
        });
      }

      if (selectedPorter) {
        filter.push({
          f: "porter.id",
          o: "=",
          p: [selectedPorter],
        });
      }

      if (tripCode) {
        filter.push({
          f: "code",
          o: "=",
          p: [`${tripCode}`],
        });
      }

      const res = await base_api_request.get(
        `${RouteConstants.SCHEDULES}?sorting=updated_at:desc&limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&filters=${JSON.stringify(filter)}`
      );
      setSchedules(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  //onload
  useEffect(() => {
    getSchedules();

    // eslint-disable-next-line
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    selectedBus,
    selectedBranch,
    selectedPorter,
    tripCode,
  ]);

  return (
    <PageLayout>
      <CreateSchedule
        refetch={() => getSchedules()}
        isOpen={open}
        handleClosed={() => setOpen(false)}
      />
      <div className="overflow-hidden h-screen">
        <div
          className={`${
            openFilter ? "" : "grid grid-cols-4"
          }  h-full overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="my-4">
              <div className="flex justify-between items-center my-4">
                <h3 className="font-bold py-2 text-2xl">Schedules</h3>

                <div className="flex gap-3">
                  <CustomButton
                    data-testid="createSchedule"
                    onClick={() => setOpen(true)}
                  >
                    <div className="flex gap-1 text-white items-center">
                      <AddOutlined
                        sx={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      <div>Create Schedule</div>
                    </div>
                  </CustomButton>
                  <ChevronDoubleLeftIcon
                    className={`${
                      openFilter ? "block" : "block lg:hidden"
                    } h-5 w-5 cursor-pointer`}
                    onClick={handleOpenFilter}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "0",
                  },
                }}
                enableRowActions={true}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <MenuItem
                    key={1}
                    onClick={() => {
                      navigate(`/schedule/${row.original?.id}/details`);
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <EyeIcon className="w-6 h-6" />
                    </ListItemIcon>
                    View details
                  </MenuItem>,
                  <MenuItem
                    disabled={
                      row.original?.status === "SCALED" ||
                      row.original?.status === "LOADING" ||
                      row.original?.status === "LOADED"
                    }
                    key={2}
                    onClick={() => {
                      scaleBus(row.original);
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <MoveUpOutlined />
                    </ListItemIcon>
                    Scale
                  </MenuItem>,
                  <MenuItem
                    disabled={
                      row.original?.status === "SCHEDULED" ||
                      row.original?.status === "LOADED"
                    }
                    key={3}
                    onClick={() => {
                      setSelectedSchedule(row.original);
                      setMoneyCollectedModal(true);
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <ForwardOutlined />
                    </ListItemIcon>
                    Away
                  </MenuItem>,
                ]}
                data={schedules}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                enableTopToolbar={false}
                manualPagination
                enableSorting={false}
                columns={schedulesColumns}
                rowCount={meta?.total}
                state={{ pagination, isLoading: loading }}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                  columnPinning: {
                    left: ["mrt-row-expand", "mrt-row-select"],
                    right: ["mrt-row-actions"],
                  },
                }}
                enableColumnResizing
                onPaginationChange={setPagination}
              />
            </div>
          </div>
          <div
            className={`${
              openFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full overflow-y-auto`}
          >
            <SchedulesFilter
              onHide={handleCloseFilter}
              defaultDates={defaultDates}
              handleCalendarChange={handleCalendarChange}
              onBranchChange={(e) => setSelectedBranch(e?.value)}
              onBusChange={(e: any) => {
                if (e) {
                  setSelectedBus(e?.value);
                } else {
                  filter.pop();
                  setSelectedBus(0);
                }
              }}
              setSelectedPorter={setSelectedPorter}
              handleTripCodeChange={(value) => {
                setTripCode(value);
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        footer={null}
        onCancel={() => {
          setMoneyCollectedModal(false);
        }}
        open={moneyCollectedModal}
      >
        <Spin spinning={loading}>
          <div>
            <div className="mt-8">
              <div className="ml-2 mb-1">Enter amount collected</div>
              <Input
                onChange={(e) => setMoneyCollected(e.target.value)}
                type="number"
                size="large"
                placeholder="Amount collected"
              />
            </div>

            <div className="mt-3">
              <CustomButton
                data-testid="awayBus"
                onClick={() => {
                  if (moneyCollected) {
                    awayBus(selectedSchedule);
                  } else {
                    notification.info({
                      message: "Enter amount collected",
                    });
                  }
                }}
              >
                Away bus
              </CustomButton>
            </div>
          </div>
        </Spin>
      </Modal>
    </PageLayout>
  );
};

export default Schedules;
