import React, { useState, useEffect } from "react";
import { Modal, Spin, Form, notification } from "antd";
import AsyncSelect from "react-select/async";
import { Typography, Button } from "@material-tailwind/react";
import CustomButton from "../CustomButton";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { Bus } from "../../types";
import { debounce } from "lodash";

const CreateBusFeature = ({
    handleClosed,
    isOpen,
    handleReFetch,
}: {
    handleClosed: () => void;
    isOpen: boolean;
    handleReFetch: () => void;
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [buses, setBuses] = useState<Bus[]>([]);
    const [features, setFeatures] = useState([]);
    const [fetchingBuses, setFetchingBuses] = useState(false);
    const [fetchingFeatures, setFetchingFeatures] = useState(false);

    const loadBuses = debounce(
        async (inputValue: string, callback: (options: any) => void) => {
            let filterData = [
                {
                    f: "reg_number",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.BUSES}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const searchOptions = res.data?.payload?.items?.map(
                    (item: Bus) => ({
                        label: item?.reg_number,
                        value: item?.id,
                    })
                );

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    const loadFeatures = debounce(
        async (inputValue: string, callback: (options: any) => void) => {
            let filterData = [
                {
                    f: "name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.FEATURES}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const searchOptions = res.data?.payload?.items?.map(
                    (item: any) => ({
                        label: item?.name,
                        value: item?.id,
                    })
                );

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    const getBuses = async () => {
        setFetchingBuses(false);
        try {
            const { data } = await base_api_request.get(RouteConstants.BUSES);
            setBuses(
                data?.payload?.items?.map((item: Bus) => ({
                    label: item?.reg_number,
                    value: item?.id,
                }))
            );
        } catch (e) {
            ///
        } finally {
            setFetchingBuses(false);
        }
    };

    const getFeatures = async () => {
        setFetchingFeatures(false);
        try {
            const { data } = await base_api_request.get(
                RouteConstants.FEATURES
            );
            setFeatures(
                data?.payload?.items?.map((item: any) => ({
                    label: item?.name,
                    value: item?.id,
                }))
            );
        } catch (e) {
            ///
        } finally {
            setFetchingFeatures(false);
        }
    };

    const submit = async () => {
        form.validateFields(["bus_id", "feature_id"])
            .then(async (values) => {
                try {
                    setSubmitting(true);
                    await base_api_request.post(RouteConstants.BUS_FEATURES, {
                        bus_id: values?.bus_id?.value,
                        feature_id: values?.feature_id?.value,
                    });
                    notification.success({
                        message: "Bus feature created",
                    });
                } catch (error: any) {
                    notification.error({
                        message:
                            error?.response?.data?.message || error?.message,
                    });
                } finally {
                    setSubmitting(false);
                    handleReFetch();
                    handleClosed();
                }
            })
            .catch(() => {
                //
            });
    };

    useEffect(() => {
        getBuses();
        getFeatures();
    }, []);

    return (
        <Modal open={isOpen} onCancel={handleClosed} footer={null}>
            <Spin spinning={submitting}>
                <div>
                    <Typography variant="h5">Create bus feature</Typography>
                    <Form
                        form={form}
                        className="mt-5"
                        layout="vertical"
                        autoComplete="none"
                    >
                        <Form.Item
                            name="bus_id"
                            label="Select Bus"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <AsyncSelect
                                defaultOptions={buses}
                                isLoading={fetchingBuses}
                                loadOptions={(inputValue, callback) => {
                                    loadBuses(inputValue, callback);
                                }}
                                onChange={(value) => {
                                    console.log(value);
                                    //getLoadingPoints(value?.value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="feature_id"
                            label="Select Bus feature"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <AsyncSelect
                                defaultOptions={features}
                                isLoading={fetchingFeatures}
                                loadOptions={(inputValue, callback) => {
                                    loadFeatures(inputValue, callback);
                                }}
                                onChange={(value) => {
                                    console.log(value);
                                    //getLoadingPoints(value?.value);
                                }}
                            />
                        </Form.Item>
                    </Form>
                    <div className="flex w-full justify-end items-end mt-10">
                        <div className="flex items-center gap-3">
                            <CustomButton
                                data-testid="continueNewBusFetaure"
                                disabled={submitting}
                                onClick={() => submit()}
                            >
                                Continue
                            </CustomButton>
                            <Button
                                data-testid="closeNewBusFeature"
                                disabled={submitting}
                                variant="outlined"
                                size="sm"
                                onClick={handleClosed}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default CreateBusFeature;
