import React from "react";
import CustomButton from "../CustomButton";
import PageLayout from "../layouts/PageLayout";
import { InboxOutlined } from "@ant-design/icons";
import { Form, Upload, DatePicker, Input } from "antd";
import Select from "react-select";

const { Dragger } = Upload;

const options = [
    { value: "AHAFO", label: "AHAFO" },
    { value: "ASHANTI", label: "ASHANTI" },
    { value: "BONO EAST", label: "BONO EAST" },
    { value: "BRONG AHAFO", label: "BRONG AHAFO" },
    { value: "CENTRAL", label: "CENTRAL" },
    { value: "EASTERN", label: "EASTERN" },
    { value: "GREATER ACCRA", label: "GREATER ACCRA" },
    { value: "NORTH EAST", label: "NORTH EAST" },
    { value: "NORTHERN", label: "NORTHERN" },
    { value: "OTI", label: "OTI" },
    { value: "SAVANNAH", label: "SAVANNAH" },
    { value: "UPPER EAST", label: "UPPER EAST" },
    { value: "UPPER WEST", label: "UPPER WEST" },
    { value: "WESTERN", label: "WESTERN" },
    { value: "WESTERN NORTH", label: "WESTERN NORTH" },
    { value: "VOLTA", label: "VOLTA" },
];

function AddCampaign() {
    const [form] = Form.useForm();

    const handleUploadClick = () => {
        form.validateFields()
            .then(() => {
                console.log("Form is valid! Uploading...");
            })
            .catch((error) => {
                // If validation fails, do nothing (no error message shown)
            });
    };

    return (
        <PageLayout>
            <div className="flex items-center justify-center w-full overflow-auto mb-8 relative ">
                <div className="w-[50%] items-center justify-center col-span-3 mx-4 my-6 h-full">
                    <h3 className="mt-10 flex w-full">
                        Enter Campaign Details
                    </h3>
                    <Form form={form} className="mt-20">
                        <Form.Item
                            name="campaignTitle"
                            label="Add Campaign Title"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter campaign title",
                                },
                            ]}
                        >
                            <br />
                            <Input
                                type="text"
                                placeholder="Enter Campaign Title"
                                className="h-12 w-[600px] mt-5 right-44"
                            />
                        </Form.Item>

                        <Form.Item
                            name="fileList"
                            label="Upload File"
                            valuePropName="fileList"
                            getValueFromEvent={(e) => e.fileList}
                            rules={[
                                {
                                    required: true,
                                    message: "Please upload a file",
                                },
                            ]}
                        >
                            <Dragger multiple>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                </p>
                            </Dragger>
                        </Form.Item>

                        <Form.Item
                            name="StartDate"
                            label=" Campaign Start Date"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select the start date",
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder="Start Date"
                                className="h-12 w-[600px] mt-10 right-44"
                            />
                        </Form.Item>

                        <Form.Item
                            name="EndDate"
                            label="Campaign End Date"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select the end date",
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder="End Date"
                                className="h-12 w-[600px] mt-10 right-40"
                            />
                        </Form.Item>

                        <h5>Filter by Demographic</h5>

                        <Form.Item
                            name="demographic"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a demographic",
                                },
                            ]}
                        >
                            <br />
                            <input
                                type="radio"
                                name="demographic"
                                value="male"
                            />
                            <label htmlFor="" className="pl-3">
                                Male
                            </label>
                        </Form.Item>

                        <Form.Item>
                            <input
                                type="radio"
                                name="demographic"
                                value="female"
                            />
                            <label htmlFor="" className="pl-3">
                                Female
                            </label>
                        </Form.Item>
                        <Form.Item>
                            <input
                                type="radio"
                                name="demographic"
                                value="all"
                            />
                            <label htmlFor="" className="pl-3">
                                All
                            </label>
                        </Form.Item>

                        <h5>Filter by Age Range</h5>

                        <Form.Item
                            name="ageRange"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select an age range",
                                },
                            ]}
                        >
                            <br />
                            <input
                                type="radio"
                                id="html"
                                name="fav_language"
                                value="Teenagers (13-19)"
                            />
                            <label htmlFor="" className="pl-3">
                                Teenagers (13-19)
                            </label>
                        </Form.Item>

                        <Form.Item>
                            <input
                                type="radio"
                                id="css"
                                name="fav_language"
                                value="Young adults (20-39)"
                            />
                            <label htmlFor="" className="pl-3">
                                Young adults (20-39)
                            </label>
                        </Form.Item>
                        <Form.Item>
                            <input
                                type="radio"
                                id="javascript"
                                name="fav_language"
                                value="Adults (40-64)"
                            />
                            <label htmlFor="" className="pl-3">
                                Adults (40-64)
                            </label>
                        </Form.Item>

                        <Form.Item>
                            <input
                                type="radio"
                                id="javascript"
                                name="fav_language"
                                value="Seniors (65+)"
                            />
                            <label htmlFor="" className="pl-3">
                                Seniors (65+)
                            </label>
                        </Form.Item>

                        <h5 className="mt-10">Select Regions</h5>
                        <Form.Item
                            name="regions"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select region(s)",
                                },
                            ]}
                        >
                            <Select
                                options={options}
                                isMulti
                                className="mb-[40px] mt-5 h-20"
                            />
                        </Form.Item>

                        <div className="flex gap-3 mb-[200px] mt-[70px]">
                            <CustomButton
                                data-testid="upload"
                                onClick={handleUploadClick}
                            >
                                <div>Upload</div>
                            </CustomButton>

                            <CustomButton
                                data-testid="cancelAddCampaign"
                                variant="secondary"
                            >
                                <div>Cancel</div>
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </div>
        </PageLayout>
    );
}

export default AddCampaign;
