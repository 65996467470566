import React, { useState } from "react";
import { Modal, Spin, Form, Input, notification } from "antd";
import { Typography } from "@mui/material";
import CustomButton from "./CustomButton";
import { Button } from "@material-tailwind/react";
import { base_api_request } from "../utils/api";
import { RouteConstants } from "../utils/helpers/RouteConstants";

type ModalProps = {
    isOpen: boolean;
    handleClosed: () => void;
};

const { TextArea } = Input;

const CreateLuggageSize = ({ isOpen, handleClosed }: ModalProps) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    const submit = async () => {
        form.validateFields(["code", "name", "description"])
            .then(async (values) => {
                setSubmitting(true);
                try {
                    await base_api_request.post(RouteConstants.LUGGAGE_SIZE, {
                        ...values,
                    });
                    notification.success({
                        message: "Luggage size successfully created",
                    });
                } catch (e: any) {
                    notification.error({
                        message: e?.response?.data?.message || e?.message,
                    });
                } finally {
                    setSubmitting(false);
                    form.resetFields();
                }
            })
            .catch((e) => {
                notification.info({
                    message: "Enter missing fields",
                });
            });
    };

    return (
        <Modal open={isOpen} onCancel={handleClosed} footer={null}>
            <Spin spinning={submitting}>
                <div>
                    <Typography variant="h5">Create luggage size</Typography>
                    <Form
                        form={form}
                        className="mt-5"
                        layout="vertical"
                        autoComplete="none"
                    >
                        <Form.Item
                            name="code"
                            label="Size unique code"
                            rules={[
                                {
                                    message: "This field is required",
                                    required: true,
                                },
                            ]}
                        >
                            <Input className="h-[48px]" />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label="Size name"
                            rules={[
                                {
                                    message: "This field is required",
                                    required: true,
                                },
                            ]}
                        >
                            <Input className="h-[48px]" />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[
                                {
                                    message: "This field is required",
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>

                        <div className="flex w-full justify-end items-end mt-10">
                            <div className="flex items-center gap-3">
                                <CustomButton
                                    data-testid="continueNewLuggageSize"
                                    disabled={submitting}
                                    onClick={submit}
                                >
                                    Continue
                                </CustomButton>
                                <Button
                                    data-testid="closeNewLuggageSize"
                                    disabled={submitting}
                                    variant="outlined"
                                    size="sm"
                                    onClick={handleClosed}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Spin>
        </Modal>
    );
};

export default CreateLuggageSize;
