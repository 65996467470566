const version = "/v2";
const statistics = `${version}/statistics`;

export const RouteConstants = {
  ALL_BRANCHES_STATS: `${statistics}/number-of-branches`,
  ALL_COMPANIES_STATS: `${statistics}/number-of-bus-companies`,
  ALL_LOADING_POINTS_STATS: `${statistics}/number-of-loading-points`,
  ALL_ROUTES_STATS: `${statistics}/number-of-routes`,
  ALL_MID_ROUTES_STATS: `${statistics}/number-of-midroutes`,
  ALL_TRIPS_STATS: `${statistics}/number-of-trips`,
  ALL_MINORS_STATS: `${statistics}/number-of-minors`,
  ALL_TOWNS_STATS: `${statistics}/number-of-towns`,
  ALL_MIGRATIONS_STATS: `${statistics}/number-of-bus-migrations`,
  ALL_CAPACITIES_STATS: `${statistics}/number-of-capacities`,
  ALL_INCIDENTS_STATS: `${statistics}/number-of-incidents`,
  ALL_PASSENGERS_STATS: `${statistics}/number-of-passengers`,
  ALL_BRANCHES: `${version}/branches`,
  ALL_COMPANIES: `${version}/companies`,
  LUGGAGE_SIZE: `${version}/luggage-sizes`,
  COMPANY_TYPES: `${version}/company-types`,
  ALL_REGIONS: `${version}/regions`,
  STATION: `${version}/station`,
  ADD_BRANCH: `${version}/branches`,
  ALL_LOADING_POINTS: `${version}/loading-points`,
  ALL_LOADING_POINT_PRICES: `${version}/loading-point-prices`,
  MY_BRANCH_ROLES: `${version}/me/branch-roles`,
  BUSES: `${version}/buses`,
  SCHEDULES: `${version}/schedules`,
  LOADING_POINT_TRAVEL_TIME: `${version}/loading-point-travel-times`,
  LOADING_POINT_BOOKINGS: `${version}/loading-point-bookings`,
  ACCOUNTS: `${version}/accounts`,
  ACCOUNT_TYPES: `${version}/account-types`,
  NETWORK_PROVIDER: `${version}/providers`,
  TICKETS: `${version}/tickets`,
  ROUTES: `${version}/routes`,
  MIGRATIONS: `${version}/migrations`,
  ALL_INCIDENTS: `${version}/accidents`,
  ALL_ADVANCE_BOOKINGS: `${version}/advanced-bookings`,
  MIDROUTES: `${version}/midroutes`,
  ALL_PICKUP_POINTS: `${version}/pickup-points`,
  ALL_STAFF: `${version}/staff`,
  PHONE: `${version}/find-by-phone`,
  TOWNS: `${version}/towns`,
  BRANCH_ROLES: `${version}/me/branch-roles`,
  ADD_STATION: `${version}/stations`,
  BUS_TYPES: `${version}/bus-types`,
  BUS_FEATURES: `${version}/bus-features`,
  FEATURES: `${version}/features`,
};
