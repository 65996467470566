import { useState, useEffect, useMemo } from "react";
import CustomDatePicker from "./bookings/customDatePicker";
import { Migration } from "./migrations/migrate";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from "date-fns";
import { Box } from "@mui/material";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import { base_api_request } from "../utils/api";
import { useParams } from "react-router";
import FieldOfficerSelector from "../components/shared/fieldOfficerSelector";

const BranchMigrations = () => {
  const { id } = useParams();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [migrations, setMigrations] = useState<Migration[]>([]);
  const [loading, setLoading] = useState(false);
  const [creatorFilter, setCreatorFilter] = useState<any>(null);

  const [defaultDates, setDefaultDates] = useState<any>([
    startOfWeek(new Date()),
    endOfWeek(new Date()),
  ]);

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates(null);
    } else if (selectedDate instanceof Array && selectedDate?.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  const columns = useMemo<MRT_ColumnDef<Migration>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.branch?.name}`,
        size: 80,
        header: "Branch name",
        accessorKey: "branch",
      },
      {
        header: "Source bus",
        accessorKey: "source",
        accessorFn: (row) => `${row?.source?.bus?.reg_number}`,
      },
      {
        accessorKey: "destination",
        header: "Destination bus",
        accessorFn: (row) => `${row?.destination?.bus?.reg_number}`,
      },
      {
        accessorKey: "migrated_passengers",
        header: "Migrated Passengers",
        accessorFn: (row) => `${row?.migrated_passengers}`,
      },

      {
        accessorFn: (row) => `${row?.creator?.name}`,
        header: "Creator",
      },
      {
        accessorFn: (row) => `${row?.created_at}`,

        header: "Date created",

        Cell: ({ cell }: any) => (
          <Box>{moment(cell.getValue()).format("MMM DD, YYYY - h:mma")}</Box>
        ),
      },
    ],
    []
  );

  //getMigrations
  const getMigrations = async () => {
    setLoading(true);
    const filter = [];

    if (id) {
      filter.push({
        f: "branch.id",
        o: "=",
        p: [parseInt(id)],
      });
    }

    if (defaultDates) {
      filter.push({
        f: "created_at",
        o: "between",
        p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
      });
    }

    if (creatorFilter) {
      filter.push({
        f: "creator.id",
        o: "=",
        p: [creatorFilter],
      });
    }

    try {
      const res = await base_api_request.get(
        `${RouteConstants.MIGRATIONS}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&filters=${JSON.stringify(filter)}`
      );
      setMigrations(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMigrations();
    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, defaultDates, creatorFilter]);

  return (
    <>
      <div className="mb-4 mt-2 p-4 rounded-lg w-full bg-white shadow-md border border-gray-400">
        <div className="gap-x-[30px] flex justify-between">
          <div className="w-full">
            <small>Filter by creator</small>
            <FieldOfficerSelector
              onChange={(selected: any) => setCreatorFilter(selected?.value)}
            />
          </div>
          <div className="w-full">
            <small className="py-2">Filter by date created</small>
            <CustomDatePicker
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
            />
          </div>
          <div className="w-full"></div>
        </div>
      </div>

      <div className="mt-4">
        <MaterialReactTable
          muiTablePaperProps={{
            sx: {
              borderRadius: "0",
            },
          }}
          data={migrations}
          enableTopToolbar={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableMultiRowSelection={false}
          enableRowSelection={false}
          manualPagination
          enableSorting={false}
          columns={columns}
          rowCount={meta?.total}
          state={{ pagination, isLoading: loading }}
          initialState={{
            showColumnFilters: false,
            density: "compact",
          }}
          enableColumnResizing
          muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
              console.log(row.original);
              // func.redirect(`/hsh/${row.original?.id}`);
              //redirect(`/branch/${row.original?.id}`);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onPaginationChange={setPagination}
        />
      </div>
    </>
  );
};

export default BranchMigrations;
