import { HomeIcon, BuildingOffice2Icon } from "@heroicons/react/24/outline";
import {
    BookOnlineOutlined,
    CarCrashOutlined,
    ForkLeftOutlined,
    LocationCityOutlined,
    PeopleAltOutlined,
    AccountTreeOutlined,
    Newspaper,
    Map,
    AirportShuttleOutlined,
    AccessTimeOutlined,
    LocationSearchingOutlined,
    LocationOnOutlined,
    PriceChangeOutlined,
    BusinessCenterOutlined,
    CalendarMonthOutlined,
} from "@mui/icons-material";
import CampaignIcon from "@mui/icons-material/Campaign";

interface SubRoutingProps {
    title?: string;
    path: string;
    icon?: JSX.Element | null;
    disabled?: boolean;
}

interface RoutingProps {
    title?: string;
    open?: boolean;
    path: string;
    icon?: JSX.Element | null;
    subItems?: SubRoutingProps[];
    disabled?: boolean;
}

export const mainRouteList: RoutingProps[] = [
    {
        title: "General",
        path: "/staff",
        icon: <HomeIcon className="w-6 h-6" />,
        open: true,
        subItems: [
            {
                title: "Home",
                path: "/",
                icon: <HomeIcon className="w-6 h-6" />,
            },
            {
                title: "Accounts",
                path: "/accounts",
                icon: (
                    <PeopleAltOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Tickets",
                path: "/tickets",
                icon: (
                    <Newspaper
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Schedules",
                path: "/schedules",
                icon: (
                    <AccessTimeOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },

            {
                title: "Incidents",
                path: "/incidents",
                icon: (
                    <CarCrashOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Advanced Bookings",
                path: "/advanced-bookings",
                icon: (
                    <BookOnlineOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
        ],
    },

    {
        title: "Companies",
        path: "/companies",
        icon: <BuildingOffice2Icon className="w-6 h-6" />,
        subItems: [
            {
                title: "Bus Companies",
                path: "/companies",
                icon: <BuildingOffice2Icon className="w-6 h-6" />,
            },
            {
                title: "Bus",
                path: "/buses",

                icon: (
                    <AirportShuttleOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Bus types",
                path: "/bus-types",

                icon: (
                    <AirportShuttleOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Bus features",
                path: "/bus-features",

                icon: (
                    <AirportShuttleOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Features",
                path: "/features",

                icon: (
                    <AirportShuttleOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
        ],
    },
    {
        title: "Branches",
        path: "/branches",
        icon: (
            <AccountTreeOutlined
                sx={{
                    width: "24px",
                    height: "24px",
                }}
            />
        ),
        subItems: [
            {
                title: "All Branches",
                path: "/branches",
                icon: (
                    <AccountTreeOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Loading Points",
                path: "/loading-points",
                icon: (
                    <LocationSearchingOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },

            {
                title: "LP Prices",
                path: "/loading-point-prices",
                icon: (
                    <PriceChangeOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "LP Travel Times",
                path: "/loading-point-time",
                icon: (
                    <CalendarMonthOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Pickup Points",
                path: "/pickup-points",
                icon: (
                    <LocationOnOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Midroutes",
                path: "/midroutes",
                icon: (
                    <LocationOnOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Staff",
                path: "/staff",
                icon: (
                    <PeopleAltOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
        ],
    },
    {
        title: "Other",
        path: "/default",
        icon: (
            <Map
                sx={{
                    width: "24px",
                    height: "24px",
                }}
            />
        ),
        subItems: [
            {
                title: "Regions",
                path: "/regions",
                icon: (
                    <Map
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Towns",
                path: "/towns",
                icon: (
                    <LocationCityOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Routes",
                path: "/routes",
                icon: (
                    <ForkLeftOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
            {
                title: "Luggage sizes",
                path: "/luggage-sizes",
                icon: (
                    <BusinessCenterOutlined
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
        ],
    },
    {
        title: "Adverts",
        path: "/adverts",
        icon: (
            <CampaignIcon
                sx={{
                    width: "24px",
                    height: "24px",
                }}
            />
        ),
        subItems: [
            {
                title: "Campaign",
                path: "/campaign",
                icon: (
                    <CampaignIcon
                        sx={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ),
            },
        ],
    },
];
