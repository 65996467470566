import { useState } from "react";
import { Modal, Spin, Form, TimePicker, notification, Input } from "antd";
import { Button, Typography } from "@material-tailwind/react";
import CustomButton from "../CustomButton";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import moment from "moment";

const CreateLoadingPointTime = ({
  handleClosed,
  isOpen,
  handleReFetch,
}: {
  handleClosed: () => void;
  isOpen: boolean;
  handleReFetch: () => void;
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  // useEffect(() => {
  // 	const sdate = new Date(`1970-01-01T${data?.start_time}Z`);
  // 	setStartTime(moment(sdate).format("LT"));

  // 	const edate = new Date(`1970-01-01T${data?.start_time}Z`);
  // 	setEndTime(moment(edate).format("LT"));
  // }, [data]);

  const submit = async () => {
    setSubmitting(true);
    try {
      const values = form.getFieldsValue();
      //console.log(values);

      let start_time = moment(values?.start_travel_time?.$d).format();
      let splitStartTime = start_time.split("T")[1];

      let end_time = moment(values?.end_travel_time?.$d).format();
      let splitEndTime = end_time.split("T")[1];

      const payload = {
        name: values?.name,
        start_travel_time: splitStartTime.split("+")[0],
        end_travel_time: splitEndTime.split("+")[0],
      };

      //   console.log(payload);
      await base_api_request.post(RouteConstants.LOADING_POINT_TRAVEL_TIME, {
        ...payload,
      });

      notification.success({
        message: "Loading point travel time created",
      });

      form.resetFields();

      handleReFetch();
      handleClosed();
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
      form.resetFields();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={isOpen} onCancel={handleClosed} footer={null}>
      <Spin spinning={submitting}>
        <div>
          <Typography variant="h5">Create Loading Point Time</Typography>
          <Form
            form={form}
            className="mt-5"
            layout="vertical"
            autoComplete="none"
          >
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item
              className="w-full"
              label="Start Travel time"
              name="start_travel_time"
              required
            >
              <TimePicker className="w-full" size="large" />
            </Form.Item>

            <Form.Item
              className="w-full"
              label="End Travel time"
              name="end_travel_time"
              required
            >
              <TimePicker className="w-full" size="large" />
            </Form.Item>
          </Form>
          <div className="flex w-full justify-end items-end mt-10">
            <div className="flex items-center gap-3">
              <CustomButton
                data-testid="continueNewLPTime"
                disabled={submitting}
                onClick={() => submit()}
              >
                Continue
              </CustomButton>
              <Button
                data-testid="closeNewLPTime"
                disabled={submitting}
                variant="outlined"
                size="sm"
                onClick={handleClosed}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default CreateLoadingPointTime;
