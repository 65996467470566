import { useEffect, useState } from "react";
import {
    Drawer,
    Form,
    InputNumber,
    DatePicker,
    message,
    Spin,
    notification,
    Input,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getBase64 } from "../../utils/functions";
import axios from "axios";
import CustomButton from "../CustomButton";
import AsyncSelect from "react-select/async";

import { Bus, BusType } from "../../types";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { debounce } from "lodash";

type Props = {
    bus: Bus | any;
    isOpen: boolean;
    handleClosed: () => void;

    refetch: () => void;
};

dayjs.extend(customParseFormat);

const EditBus = (props: Props) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const [img, setImg] = useState<any>();
    const [loadingBusTypes, setLoadingBusTypes] = useState(false);
    const [busTypes, setBusTypes] = useState<BusType[]>([]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (allowedTypes.includes(file.type)) {
                if (isLt2M) {
                    setImg(file);
                    getBase64(file, (imgUrl: string) => {
                        setImageUrl(imgUrl);
                    });
                } else {
                    message.error("Image must be smaller than 2MB!");
                }
            } else {
                message.error(
                    "Please select a valid image file (jpg, jpeg, or png)."
                );
            }
        }
    };

    const loadBusTypes = debounce(
        async (inputValue: string, callback: (option: any) => void) => {
            try {
                const filter = [
                    {
                        f: "name",
                        o: "contains",
                        p: [`${inputValue}`],
                    },
                ];
                const { data } = await base_api_request(
                    `${RouteConstants.BUS_TYPES}?filters=${JSON.stringify(
                        filter
                    )}`
                );
                const options = data?.payload?.items?.map((item: BusType) => ({
                    label: item?.name,
                    value: item?.id,
                }));

                callback(options);
            } catch (e) {
                console.log(e);
            }
        },
        1000
    );

    const getBusTypes = async () => {
        setLoadingBusTypes(true);
        try {
            const { data } = await base_api_request(RouteConstants.BUS_TYPES);
            setBusTypes(
                data?.payload?.items?.map((item: BusType) => ({
                    label: item?.name,
                    value: item?.id,
                }))
            );
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingBusTypes(false);
        }
    };

    const submit = async () => {
        const values = form.getFieldsValue();
        console.log(values);
        if (img) {
            setSubmitting(true);
            try {
                const response = await base_api_request.post(
                    `${RouteConstants.BUSES}/${props.bus?.id}/images/signed-upload`,
                    {
                        mime: img?.type,
                    }
                );

                axios
                    .put(response.data?.payload?.upload_url, img, {
                        headers: {
                            "Content-Type": img?.type,
                        },
                    })
                    .then(async function () {
                        try {
                            await base_api_request.put(
                                `/v2/buses/${props.bus?.id}`,
                                {
                                    reg_number:
                                        values?.reg_number ||
                                        props?.bus?.reg_number,
                                    capacity:
                                        values?.capacity ||
                                        props?.bus?.capacity,
                                    roadworthy_expires_at:
                                        values?.rw_expire_date ||
                                        props.bus?.rw_exp_date,
                                    insurance_expires_at:
                                        values?.insurance_date ||
                                        props.bus?.insurance_exp_date,
                                    passenger_capacity:
                                        values?.passenger_capacity ||
                                        props.bus?.passenger_capacity,
                                    model: values?.model || props.bus?.model,
                                    description: "Bus update",
                                    type_id: values?.type_id?.value,
                                }
                            );

                            notification.success({
                                message: "Bus details updated",
                            });
                            //showEditBusModal(false);
                            props.refetch();
                            props.handleClosed();
                        } catch (e: any) {
                            console.log(e);
                            setSubmitting(false);
                            notification.error({
                                message: e?.response?.data?.message,
                            });
                        } finally {
                            setSubmitting(false);
                        }
                        //setSigning(false);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            } catch (e: any) {
                console.log(e);
                setSubmitting(false);
            }
        } else {
            //props.handleSubmitting(true);
            setSubmitting(true);
            try {
                await base_api_request.put(`/v2/buses/${props.bus?.id}`, {
                    reg_number: values?.reg_number || props?.bus?.reg_number,
                    capacity: values?.capacity || props?.bus?.capacity,
                    roadworthy_expires_at:
                        values?.rw_expire_date || props.bus?.rw_exp_date,
                    insurance_expires_at:
                        values?.insurance_date || props.bus?.insurance_exp_date,
                    passenger_capacity:
                        values?.passenger_capacity ||
                        props.bus?.passenger_capacity,
                    model: values?.model || props.bus?.model,
                    description: "Bus update",
                    type_id: values?.type_id?.value,
                });
                setSubmitting(false);
                notification.success({
                    message: "Bus details updated",
                });
                //showEditBusModal(false);
                props.refetch();
                props.handleClosed();
            } catch (e: any) {
                console.log(e);
                setSubmitting(false);
                notification.error({
                    message: e?.response?.data?.message,
                });
            } finally {
                setSubmitting(false);
            }
        }
    };

    useEffect(() => {
        getBusTypes();
    }, []);

    useEffect(() => {
        form.setFieldsValue(props.bus);
        form.setFieldValue(
            "insurance_date",
            dayjs(props.bus?.insurance_exp_date)
        );
        form.setFieldValue("rw_expire_date", dayjs(props.bus?.rw_exp_date));
        form.setFieldValue("type_id", {
            label: props.bus?.type?.name,
            value: props.bus?.type?.id,
        });
    }, [props.bus, form]);

    return (
        <Drawer
            title="Edit Bus Details"
            placement="right"
            onClose={props.handleClosed}
            open={props.isOpen}
            zIndex={100}
            size="large"
        >
            <Spin spinning={submitting}>
                <div>
                    <Form form={form} layout="vertical" autoComplete="off">
                        <div className="relative border border-solid border-oya-ghana-border mt-2 p-6 rounded-lg">
                            {imageUrl ? (
                                <img src={imageUrl} alt="" />
                            ) : (
                                <>
                                    {props.bus?.image ? (
                                        <img src={props.bus?.image} alt="" />
                                    ) : (
                                        <div className="img-upload">
                                            <div className="text-center flex justify-center py-5">
                                                <img
                                                    src={require("../../images/Vector.png")}
                                                    alt="Upload"
                                                />
                                            </div>

                                            <div className="text-center">
                                                <p className="pb-2 text-oya-ghana-header-h6 font-semibold">
                                                    Drop your file here or{" "}
                                                    <br /> click to upload
                                                </p>
                                                <p className="text-oya-ghana-header-h7 font-bold">
                                                    PNG, JPEG format only.{" "}
                                                    <br /> Maximum file size
                                                    2MB.
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            <input
                                onChange={handleImageChange}
                                type="file"
                                className="absolute inset-0 w-full h-full cursor-pointer"
                                style={{ opacity: 0 }}
                            />
                        </div>
                        <div className="mt-3">
                            <Form.Item label="Bus type" name="type_id">
                                <AsyncSelect
                                    defaultOptions={busTypes}
                                    isLoading={loadingBusTypes}
                                    loadOptions={(inputValue, callback) => {
                                        loadBusTypes(inputValue, callback);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="flex mt-3 gap-3 items-center">
                            <Form.Item
                                label="Bus Registration"
                                name="reg_number"
                                className="w-full"
                            >
                                <Input placeholder="AS 1234-12" size="large" />
                            </Form.Item>
                            <Form.Item
                                label="Model"
                                name="model"
                                className="w-full"
                            >
                                <Input size="large" />
                            </Form.Item>
                        </div>

                        <div className="flex gap-3">
                            <Form.Item
                                className="w-1/2"
                                label="Insurance Expiry Date"
                                name="insurance_date"
                            >
                                <DatePicker className="w-full" />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2"
                                label="Roadworthy Expiry Date"
                                name="rw_expire_date"
                            >
                                <DatePicker
                                    className="w-full"
                                    onChange={(e) => console.log(e)}
                                />
                            </Form.Item>
                        </div>
                        <div className="flex gap-3">
                            <Form.Item
                                className="w-1/2"
                                label="Bus Capacity"
                                name="capacity"
                            >
                                <InputNumber
                                    min={10}
                                    className="w-full"
                                    value={1}
                                />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2"
                                label="Passenger Capacity"
                                name="passenger_capacity"
                            >
                                <InputNumber
                                    min={10}
                                    className="w-full"
                                    value={1}
                                />
                            </Form.Item>
                        </div>
                        {/* <Form.Item name='description' label='Description'>
              <TextArea rows={4} />
            </Form.Item> */}

                        <div className="flex gap-2 justify-end items-end w-full">
                            <CustomButton onClick={submit}>
                                <div>Update</div>
                            </CustomButton>
                            <CustomButton
                                onClick={props.handleClosed}
                                variant="secondary"
                            >
                                <div>Cancel</div>
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </Spin>
        </Drawer>
    );
};

export default EditBus;
