import { useState, useEffect, useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import { Dropdown, Space, Spin, notification } from "antd";
import { ArrowDropDownOutlined, EditOutlined } from "@mui/icons-material";
import { Midroute } from "../types";
import CustomButton from "../components/CustomButton";
import { AddOutlined } from "@mui/icons-material";
import { base_api_request } from "../utils/api";
import type { MenuProps } from "antd";
import PageLayout from "../components/layouts/PageLayout";
import CreateMidroute from "../components/Midroute/CreateMidroute";
import EditMidroute from "../components/Midroute/EditMidroute";
import { RouteConstants } from "../utils/helpers/RouteConstants";

const Midroutes = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [midroute, setMidroute] = useState<Midroute[]>([]);
  const [selectedMidroute, setSelectedMidroute] = useState<Midroute | any>();
  const [editMidrouteModal, showEditMidrouteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const items: MenuProps["items"] = [
    {
      key: "1",

      label: (
        <button
          data-testid="editMidRoute"
          onClick={() => {
            showEditMidrouteModal(true);
          }}
        >
          Edit Midroute
        </button>
      ),
      icon: (
        <EditOutlined
          sx={{
            color: "#7c0000",
          }}
        />
      ),
    },
  ];

  const midRouteColumn = useMemo<MRT_ColumnDef<Midroute>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.route?.source?.name}`,

        header: "Source Name",
      },
      {
        accessorFn: (row) => `${row?.route?.destination?.name}`,

        header: "Destination Name",
      },
      {
        accessorFn: (row) => `${row?.branch?.name}`,
        header: "Branch",
      },
      {
        accessorFn: (row) => `${row?.pickup?.name}`,
        header: "Pickup Name",
      },
      {
        id: "more",
        header: "",
        accessorFn: (row) => `${row?.id}`,
        Cell: ({ cell, row }) => (
          <Box>
            <Dropdown
              trigger={["click"]}
              menu={{
                items,
                onChange: (e) => {
                  //console.log('value being set', e);
                  setSelectedMidroute(e);
                },
              }}
              placement="bottomRight"
            >
              <button
                data-testid="moreMidRoutes"
                onClick={(e) => {
                  //console.log('value in more', e);
                  e.preventDefault();
                }}
              >
                <Space>
                  More
                  <ArrowDropDownOutlined />
                </Space>
              </button>
            </Dropdown>
          </Box>
        ),
        size: 80,
      },
    ],

    // eslint-disable-next-line
    []
  );

  const updateMidroute = async (values: any) => {
    try {
      setSubmitting(true);
      console.log({
        ...values,
      });
      await base_api_request.put(
        `${RouteConstants.MIDROUTES}/${selectedMidroute?.id}`,
        {
          station_route_id:
            values?.route_id?.value || selectedMidroute?.route?.id,
          pickup_id: values?.pickup_id?.value || selectedMidroute?.pickup?.id,
        }
      );
      setSubmitting(false);
      showEditMidrouteModal(false);
      notification.success({
        message: "Midroute details updated",
      });
      getMidroutes();
    } catch (e: any) {
      setSubmitting(false);
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
    }
  };

  const getMidroutes = async () => {
    setLoading(true);
    try {
      const res = await base_api_request.get(
        `${RouteConstants.MIDROUTES}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc`
      );
      setMidroute(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    // getDetails();
    getMidroutes();

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <PageLayout>
      <Spin spinning={submitting} className="z-[9999]">
        <CreateMidroute
          isOpen={open}
          handleClosed={() => setOpen(false)}
          reload={() => getMidroutes()}
        />
        <EditMidroute
          midroute={selectedMidroute}
          isOpen={editMidrouteModal}
          handleClosed={() => showEditMidrouteModal(false)}
          // token={token}
          handleSubmitting={(loading) => {
            setSubmitting(loading);
          }}
          updateMidroute={updateMidroute}
        />
        <div className="col-span-3 mx-4 my-6 h-full">
          <div className="w-full overflow-auto mb-8 relative">
            <div className=" my-4">
              <div className="flex justify-between items-center mb-5">
                <h3 className="font-bold py-2 text-2xl">Midroutes</h3>
                <CustomButton
                  data-testid="createMidRoute"
                  onClick={() => setOpen(true)}
                >
                  <div className="flex gap-1 text-white items-center">
                    <AddOutlined
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <div>Create Midroute</div>
                  </div>
                </CustomButton>
              </div>
            </div>
            <div className="grid grid-cols-1 mt-8">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "0",
                  },
                }}
                data={midroute}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                enableTopToolbar={false}
                manualPagination
                enableSorting={false}
                columns={midRouteColumn}
                rowCount={meta?.total}
                state={{ pagination, isLoading: loading }}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                }}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    console.log(row.original);
                    setSelectedMidroute(row.original);
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                enableColumnResizing
                onPaginationChange={setPagination}
              />
            </div>
          </div>
        </div>
      </Spin>
    </PageLayout>
  );
};

export default Midroutes;
