export type Campaign = {
  id: number;
  title: string;
  fileUploaded: string;
  startDate: string;
  endDate: string;
  demographic: string;
  ageRange: string;
  regions: string[];
};

export const campaignsData: Campaign[] = [
  {
    id: 1,
    title: "Men and Women",
    fileUploaded: "Your Voice",
    startDate: "2023-11-16",
    endDate: "2025-11-30",
    demographic: "All",
    ageRange: "Young adults (20-39)",
    regions: [
      "AHAFO",
      "ASHANTI",
      "BONO EAST",
      "BRONG AHAFO",
      "CENTRAL",
      "GREATER ACCRA",
      "NORTH EAST",
    ],
  },
  {
    id: 2,
    title: "Teens",
    fileUploaded: "Teens",
    startDate: "2023-12-31",
    endDate: "2024-11-30",
    demographic: "All",
    ageRange: "Teenagers (13-19)",
    regions: ["CENTRAL", "GREATER ACCRA"],
  },
  {
    id: 3,
    title: "Education & Skill Development",
    fileUploaded: "Software",
    startDate: "2023-12-31",
    endDate: "2024-11-30",
    demographic: "All",
    ageRange: "Young adults (20-39)",
    regions: ["CENTRAL", "GREATER ACCRA"],
  },
  {
    id: 4,
    title: "Social Causes & Activism",
    fileUploaded: "Climate",
    startDate: "2023-12-31",
    endDate: "2024-11-30",
    demographic: "All",
    ageRange: "Young adults (20-39)",
    regions: ["CENTRAL", "GREATER ACCRA"],
  },
  {
    id: 5,
    title: "Health & Wellness",
    fileUploaded: "Health",
    startDate: "2023-12-31",
    endDate: "2024-11-30",
    demographic: "All",
    ageRange: "Adults (40-64)",
    regions: ["CENTRAL", "GREATER ACCRA"],
  },
  {
    id: 6,
    title: "Community Engagement",
    fileUploaded: "Teens",
    startDate: "2023-12-31",
    endDate: "2024-11-30",
    demographic: "All",
    ageRange: "Young adults (20-39)",
    regions: ["CENTRAL", "GREATER ACCRA"],
  },
  {
    id: 7,
    title: "Marketing & Business",
    fileUploaded: "Teens",
    startDate: "2023-12-31",
    endDate: "2024-11-30",
    demographic: "All",
    ageRange: " Adults (40-64)",
    regions: ["CENTRAL", "GREATER ACCRA"],
  },
  {
    id: 8,
    title: "Technology & Innovation",
    fileUploaded: "Teens",
    startDate: "2023-12-31",
    endDate: "2024-11-30",
    demographic: "All",
    ageRange: "Young adults (20-39)",
    regions: ["CENTRAL", "GREATER ACCRA"],
  },
  {
    id: 9,
    title: "Entertainment & Creativity",
    fileUploaded: "Teens",
    startDate: "2023-12-31",
    endDate: "2024-11-30",
    demographic: "All",
    ageRange: "Adults (40-64)",
    regions: ["CENTRAL", "GREATER ACCRA"],
  },
  {
    id: 10,
    title: "Teens",
    fileUploaded: "Teens",
    startDate: "2023-12-31",
    endDate: "2024-11-30",
    demographic: "All",
    ageRange: "Teenagers (13-19)",
    regions: ["CENTRAL", "GREATER ACCRA"],
  },
];

export default campaignsData;
