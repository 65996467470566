import { useEffect, useState } from "react";
import PageLayout from "../components/layouts/PageLayout";
import Card from "../components/Main/Card";
import { BuildingOffice2Icon, FolderIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import {
    CarCrashOutlined,
    ForkLeftOutlined,
    LocationCityOutlined,
    PeopleAltOutlined,
    AccountTreeOutlined,
    LocationOn,
    ChildCare,
    ReduceCapacity,
    AirportShuttle,
} from "@mui/icons-material";
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from "date-fns";
import { base_api_request } from "../utils/api";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import CustomDatePicker from "./bookings/customDatePicker";
import { useNavigate } from "react-router";

const Main = () => {
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [branches, setBranches] = useState<number>(0);
    const [companies, setCompanies] = useState<number>(0);
    const [loadingPoints, setLoadingPoints] = useState<number>(0);
    const [towns, setTowns] = useState<number>(0);
    const [midroutes, setMidroutes] = useState<number>(0);
    const [routes, setRoutes] = useState<number>(0);
    const [minors, setMinors] = useState<number>(0);
    const [trips, setTrips] = useState<number>(0);
    const [passengers, setPassengers] = useState<number>(0);
    const [capacities, setCapacities] = useState<number>(0);
    const [migrations, setMigrations] = useState<number>(0);
    const [incidents, setIncidents] = useState<number>(0);

    const [defaultDates, setDefaultDates] = useState<any>([
        startOfWeek(new Date()),
        endOfWeek(new Date()),
    ]);

    const handleCalendarChange = (selectedDate: any) => {
        if (!selectedDate) {
            setDefaultDates(null);
        } else if (
            selectedDate instanceof Array &&
            selectedDate?.length === 2
        ) {
            const [startDate, endDate] = selectedDate;

            const adjustedEndDate = endOfDay(endDate);

            //setPagination({ pageIndex: 0, pageSize: 10 });
            setDefaultDates([startDate, adjustedEndDate]);
        } else if (selectedDate instanceof Date) {
            const startDate = startOfDay(selectedDate);
            const endDate = endOfDay(selectedDate);

            //setPagination({ pageIndex: 0, pageSize: 10 });
            setDefaultDates([startDate, endDate]);
        }
    };

    const getBranches = async () => {
        try {
            setSubmitting(true);
            const res = await base_api_request.get(
                RouteConstants.ALL_BRANCHES_STATS
            );
            setBranches(res.data?.payload);
            setSubmitting(false);
        } catch (e) {
            console.log(e);
            setSubmitting(false);
        }
    };

    const getCompanies = async () => {
        try {
            setSubmitting(true);
            const res = await base_api_request.get(
                RouteConstants.ALL_COMPANIES_STATS
            );
            setCompanies(res.data?.payload);
            setSubmitting(false);
        } catch (e) {
            console.log(e);
            setSubmitting(false);
        }
    };

    const getLoadingPoints = async () => {
        try {
            setSubmitting(true);
            const res = await base_api_request.get(
                RouteConstants.ALL_LOADING_POINTS_STATS
            );
            setLoadingPoints(res.data?.payload);
            setSubmitting(false);
        } catch (e) {
            console.log(e);
            setSubmitting(false);
        }
    };

    const getRoutes = async () => {
        try {
            setSubmitting(true);
            const res = await base_api_request.get(
                RouteConstants.ALL_ROUTES_STATS
            );
            setRoutes(res.data?.payload);
            setSubmitting(false);
        } catch (e) {
            console.log(e);
            setSubmitting(false);
        }
    };

    const getMidroutes = async () => {
        try {
            setSubmitting(true);
            const res = await base_api_request.get(
                RouteConstants.ALL_MID_ROUTES_STATS
            );
            setMidroutes(res.data?.payload);
            setSubmitting(false);
        } catch (e) {
            console.log(e);
            setSubmitting(false);
        }
    };
    const getTrips = async () => {
        try {
            setLoading(true);
            if (defaultDates) {
                const defaultFilters = [
                    {
                        f: "created_at",
                        o: "between",
                        p: [
                            defaultDates[0].toISOString(),
                            defaultDates[1].toISOString(),
                        ],
                    },
                ];

                const res = await base_api_request.get(
                    `${RouteConstants.ALL_TRIPS_STATS}?filters=${JSON.stringify(
                        defaultFilters
                    )}`
                );
                setTrips(res.data?.payload);
            } else {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_TRIPS_STATS}`
                );
                setTrips(res.data?.payload);
            }

            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };
    const getMinors = async () => {
        try {
            setLoading(true);
            if (defaultDates) {
                const defaultFilters = [
                    {
                        f: "created_at",
                        o: "between",
                        p: [
                            defaultDates[0].toISOString(),
                            defaultDates[1].toISOString(),
                        ],
                    },
                ];

                const res = await base_api_request.get(
                    `${
                        RouteConstants.ALL_MINORS_STATS
                    }?filters=${JSON.stringify(defaultFilters)}`
                );
                setMinors(res.data?.payload);
            } else {
                const res = await base_api_request.get(
                    RouteConstants.ALL_MINORS_STATS
                );
                setMinors(res.data?.payload);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };
    const getTowns = async () => {
        try {
            setSubmitting(true);
            const res = await base_api_request.get(
                RouteConstants.ALL_TOWNS_STATS
            );
            setTowns(res.data?.payload);
            setSubmitting(false);
        } catch (e) {
            console.log(e);
            setSubmitting(false);
        }
    };
    const getMigrations = async () => {
        try {
            setLoading(true);
            if (defaultDates) {
                const defaultFilters = [
                    {
                        f: "created_at",
                        o: "between",
                        p: [
                            defaultDates[0].toISOString(),
                            defaultDates[1].toISOString(),
                        ],
                    },
                ];

                const res = await base_api_request.get(
                    `${
                        RouteConstants.ALL_MIGRATIONS_STATS
                    }?filters=${JSON.stringify(defaultFilters)}`
                );
                setMigrations(res.data?.payload);
            } else {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_MIGRATIONS_STATS}`
                );
                setMigrations(res.data?.payload);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };
    const getCapacity = async () => {
        try {
            setLoading(true);
            if (defaultDates) {
                const defaultFilters = [
                    {
                        f: "created_at",
                        o: "between",
                        p: [
                            defaultDates[0].toISOString(),
                            defaultDates[1].toISOString(),
                        ],
                    },
                ];

                const res = await base_api_request.get(
                    `${
                        RouteConstants.ALL_CAPACITIES_STATS
                    }?filters=${JSON.stringify(defaultFilters)}`
                );
                setCapacities(res.data?.payload);
            } else {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_CAPACITIES_STATS}`
                );
                setCapacities(res.data?.payload);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const getIncidents = async () => {
        try {
            setLoading(true);
            if (defaultDates) {
                const defaultFilters = [
                    {
                        f: "created_at",
                        o: "between",
                        p: [
                            defaultDates[0].toISOString(),
                            defaultDates[1].toISOString(),
                        ],
                    },
                ];

                const res = await base_api_request.get(
                    `${
                        RouteConstants.ALL_INCIDENTS_STATS
                    }?filters=${JSON.stringify(defaultFilters)}`
                );
                setIncidents(res.data?.payload);
            } else {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_INCIDENTS_STATS}`
                );
                setIncidents(res.data?.payload);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const getPassengers = async () => {
        try {
            setLoading(true);
            if (defaultDates) {
                const defaultFilters = [
                    {
                        f: "created_at",
                        o: "between",
                        p: [
                            defaultDates[0].toISOString(),
                            defaultDates[1].toISOString(),
                        ],
                    },
                ];

                const res = await base_api_request.get(
                    `${
                        RouteConstants.ALL_PASSENGERS_STATS
                    }?filters=${JSON.stringify(defaultFilters)}`
                );
                setPassengers(res.data?.payload);
            } else {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_PASSENGERS_STATS}`
                );
                setPassengers(res.data?.payload);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const navigate = useNavigate();
    const goToTrips = () => {
        navigate("/schedules");
    };
    const goToPassenger = () => {
        navigate("/tickets");
    };
    const goToBranches = () => {
        navigate("/branches");
    };
    const goToCompanies = () => {
        navigate("/companies");
    };
    const goToIncidents = () => {
        navigate("/incidents");
    };
    const goToLoadingPoints = () => {
        navigate("/loading-points");
    };
    // const goToMigrations = () => {
    //     navigate("/migrations");
    // };
    const goToMidroutes = () => {
        navigate("/midroutes");
    };
    const goToRoutes = () => {
        navigate("/routes");
    };
    const goToTowns = () => {
        navigate("/towns");
    };

    useEffect(() => {
        getBranches();
        getCompanies();
        getLoadingPoints();
        getRoutes();
        getMidroutes();
        getTowns();
    }, []);

    useEffect(() => {
        getTrips();
        getMinors();
        getMigrations();
        getPassengers();
        getCapacity();
        getIncidents();
        // eslint-disable-next-line
    }, [defaultDates]);

    return (
        <PageLayout>
            <div className="w-full bg-gray-200 overflow-y-auto pb-8 relative h-screen">
                <div className="col-span-3 mx-4 my-6 h-full">
                    <div className="mb-8">
                        <div className="flex justify-between my-4 items-center">
                            <Typography variant="h3">Main</Typography>
                            <div>
                                <CustomDatePicker
                                    defaultDates={defaultDates}
                                    handleDateChange={handleCalendarChange}
                                />
                            </div>
                        </div>

                        <div className="lg:grid lg:grid-cols-3 gap-5 mt-3 flex flex-col">
                            <button
                                data-testid="trips"
                                className="cursor-pointer"
                                onClick={goToTrips}
                            >
                                <Card
                                    isLoading={loading}
                                    title="Number of Trips"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        trips
                                    )}
                                    icon={
                                        <AirportShuttle
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                            <button
                                data-testid="passengers"
                                className=" cursor-pointer"
                                onClick={goToPassenger}
                            >
                                <Card
                                    isLoading={loading}
                                    title="Number of Passengers"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        passengers
                                    )}
                                    icon={
                                        <PeopleAltOutlined
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                            <button
                                data-testid="minors"
                                className="cursor-pointer"
                            >
                                <Card
                                    isLoading={loading}
                                    title="Number of Minors"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        minors
                                    )}
                                    icon={
                                        <ChildCare
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                        </div>
                        <div className="my-3" />
                        <div className="lg:grid lg:grid-cols-3 gap-5 mt-3 flex flex-col">
                            <button
                                data-testid="capacities"
                                className="cursor-pointer"
                            >
                                <Card
                                    isLoading={loading}
                                    title="Number of Capacity"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        capacities
                                    )}
                                    icon={
                                        <ReduceCapacity
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                            <button
                                data-testid="migrations"
                                className="cursor-button"
                            >
                                <Card
                                    isLoading={loading}
                                    title="Number of Migrations"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        migrations
                                    )}
                                    icon={<FolderIcon className="w-9 h-9" />}
                                />
                            </button>
                            <button
                                data-testid="incidents"
                                className="cursor-pointer"
                                onClick={goToIncidents}
                            >
                                <Card
                                    isLoading={loading}
                                    title="Number of Incidents"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        incidents
                                    )}
                                    icon={
                                        <CarCrashOutlined
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                        </div>

                        <div className="mt-5" />

                        <Typography variant="h3">Other</Typography>
                        <div className="lg:grid lg:grid-cols-3 gap-5 mt-3 flex flex-col">
                            <button
                                data-testid="companies"
                                className="cursor-pointer"
                                onClick={goToCompanies}
                            >
                                <Card
                                    isLoading={submitting}
                                    title="Number of Companies"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        companies
                                    )}
                                    icon={
                                        <BuildingOffice2Icon className="w-9 h-9" />
                                    }
                                />
                            </button>
                            <button
                                data-testid="branches"
                                className="cursor-pointer"
                                onClick={goToBranches}
                            >
                                <Card
                                    isLoading={submitting}
                                    title="Number of Branches"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        branches
                                    )}
                                    icon={
                                        <AccountTreeOutlined
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                            <button
                                data-testid="loadingPoints"
                                className="cursor-pointer"
                                onClick={goToLoadingPoints}
                            >
                                <Card
                                    isLoading={submitting}
                                    title="Number of Loading Points"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        loadingPoints
                                    )}
                                    icon={
                                        <LocationOn
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                        </div>
                        <div className="my-3" />
                        <div className="lg:grid lg:grid-cols-3 gap-5 mt-3 mb-12 pb-[100px] flex flex-col">
                            <button
                                data-testid="towns"
                                className="cursor-pointer"
                                onClick={goToTowns}
                            >
                                <Card
                                    isLoading={submitting}
                                    title="Number of Towns"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        towns
                                    )}
                                    icon={
                                        <LocationCityOutlined
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                            <button
                                data-testid="routes"
                                className="cursor-pointer"
                                onClick={goToRoutes}
                            >
                                <Card
                                    isLoading={submitting}
                                    title="Number of Routes"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        routes
                                    )}
                                    icon={
                                        <ForkLeftOutlined
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                            <button
                                data-testid="midroutes"
                                className="cursor-pointer"
                                onClick={goToMidroutes}
                            >
                                <Card
                                    isLoading={submitting}
                                    title="Number of Midroutes"
                                    stat={Intl.NumberFormat("en-EN").format(
                                        midroutes
                                    )}
                                    icon={
                                        <ForkLeftOutlined
                                            sx={{
                                                width: "35px",
                                                height: "35px",
                                            }}
                                        />
                                    }
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

export default Main;
