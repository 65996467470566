import { useMemo, useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import moment from "moment";
import { endOfDay, startOfDay } from "date-fns";
import PageLayout from "../../components/layouts/PageLayout";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { LoadingPointBookingType } from "../branches/branch";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";
import LoadingPointBookingFilter from "../../components/Branches/LoadingPointBookingFilter";
import { notification } from "antd";
import LoadingPointBookingModal from "./loadingPointBookingModal";
import AdvanceBookingModal from "./advancedBookingModal";
import CustomButton from "../../components/CustomButton";
import AssignToScheduleModal from "./AssignToScheduleModal";
import { LPBookingLuggageType } from "../../types";

interface Filter {
  f: string;
  o: string;
  p: string[] | number[] | boolean[];
  r?: string;
}

const AllBookingsList = () => {
  const [defaultDates, setDefaultDates] = useState<any>();

  const [bookingType, setBookingType] = useState<number | undefined>(1);
  const [ticketOwner, setTicketOwner] = useState<number | undefined>();

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [loadingPointRowSelection, setLoadingPointRowSelection] =
    useState<MRT_RowSelectionState>({});

  const [loading, setLoading] = useState(false);

  const [bookingData, setBookingData] = useState<LoadingPointBookingType[]>([]);
  const [bookings, setBookings] = useState<LoadingPointBookingType[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [showAssignToSchedule, setShowAssignToSchedule] =
    useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [currentAdvanceIndex, setCurrentAdvanceIndex] = useState<number | null>(
    null
  );
  const [selectedAdvanceRow, setSelectedAdvanceRow] =
    useState<LoadingPointBookingType | null>(null);
  const [openAdvance, setOpenAdvance] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<string | undefined>("");

  const [luggages, setLuggages] = useState<Array<LPBookingLuggageType>>([]);

  const handleOpen = async (row: LoadingPointBookingType) => {
    await getLuggages(row?.id);
    const index = bookings.findIndex((item) => item.id === row.id);
    if (index !== -1) {
      setSelectedRow(row);
      setCurrentIndex(index);
      setOpen(true);
    }
  };

  const getLuggages = async (id: string) => {
    try {
      const { data } = await base_api_request.get(
        `${RouteConstants.LOADING_POINT_BOOKINGS}/${id}/luggages`
      );
      //console.log(data?.response?.items);
      setLuggages(data?.payload?.items);
    } catch (error) {
      //
    }
  };

  const handleNext = () => {
    if (currentIndex !== null && currentIndex < bookings.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSelectedRow(bookings[currentIndex + 1]);
    }
  };

  const handlePrev = () => {
    if (currentIndex !== null && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setSelectedRow(bookings[currentIndex - 1]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
    setCurrentIndex(null);
    getBookings();
  };

  const handleAdvanceOpen = (row: any) => {
    const index = bookingData.findIndex((item) => item.id === row.id);
    if (index !== -1) {
      setSelectedAdvanceRow(row);
      setCurrentAdvanceIndex(index);
      setOpenAdvance(true);
    }
  };

  const handleAdvanceNext = () => {
    if (
      currentAdvanceIndex !== null &&
      currentAdvanceIndex < bookingData.length - 1
    ) {
      setCurrentAdvanceIndex(currentAdvanceIndex + 1);
      setSelectedAdvanceRow(bookingData[currentAdvanceIndex + 1]);
    }
  };

  const handleAdvancePrev = () => {
    if (currentAdvanceIndex !== null && currentAdvanceIndex > 0) {
      setCurrentAdvanceIndex(currentAdvanceIndex - 1);
      setSelectedAdvanceRow(bookingData[currentAdvanceIndex - 1]);
    }
  };

  const handleAdvanceClose = () => {
    setOpenAdvance(false);
    setSelectedAdvanceRow(null);
    setCurrentAdvanceIndex(null);
  };

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  //for filter drawer
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const handleOpenFilter = () => {
    setOpenFilter(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(true);
  };

  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates(null);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;
      const adjustedEndDate = endOfDay(endDate);
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);
      setDefaultDates([startDate, endDate]);
    }
  };

  const getBookings = async () => {
    setLoading(true);
    let defaultFilters: Filter[] = [];

    try {
      if (paymentStatus) {
        defaultFilters.push({
          f: "status.code",
          o: "=",
          p: [paymentStatus],
        });
      }
      if (defaultDates) {
        defaultFilters.push({
          f: "travel_date",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }
      if (selectedBranch) {
        defaultFilters.push({
          f: "branch.id",
          o: "=",
          p: [Number(selectedBranch)],
        });
      }

      if (ticketOwner) {
        defaultFilters.push({
          f: "owner.id",
          o: "=",
          p: [ticketOwner],
        });
      }
      const res = await base_api_request.get(
        `${RouteConstants.LOADING_POINT_BOOKINGS}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&filters=${JSON.stringify(defaultFilters)}&sorting=travel_date:desc`
      );
      setBookings(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  const fetchTableData = async () => {
    setIsFetching(true);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;

      const defaultFilters = [];

      if (defaultDates) {
        defaultFilters.push({
          f: "created_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }

      const response = await base_api_request.get(
        `${RouteConstants.ALL_ADVANCE_BOOKINGS}?offset=${offset}&limit=${
          pagination.pageSize
        }&filters=${JSON.stringify(defaultFilters)}&sorting=created_at:desc`
      );
      //console.log(response);
      setBookingData(response?.data?.payload?.items);
      setRowCount(response?.data?.payload?.total);
      setIsFetching(false);
      setIsLoading(false);
    } catch (error: any) {
      //console.log(error);
      setIsLoading(false);
      setIsFetching(false);
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    }
  };

  // fetch data
  useEffect(() => {
    if (bookingType === 1) {
      fetchTableData();
    } else if (bookingType === 2) {
      getBookings();
    }

    // eslint-disable-next-line
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    bookingType,
    ticketOwner,
    selectedBranch,
    paymentStatus,
  ]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.id,
        id: "id",
        header: "ID",
        size: 20,
      },
      {
        accessorFn: (row: any) => row?.user?.name,
        id: "userName",
        header: "User Name",
      },
      {
        accessorFn: (row: any) => row?.user?.phone,
        id: "userPhone",
        header: "User Phone",
      },
      {
        accessorFn: (row: any) => row?.message,
        id: "message",
        header: "Message",
      },
      {
        accessorFn: (row: any) =>
          row?.route?.from?.name + "-" + row?.route?.to?.name,
        id: "loadingPoint",
        header: "Loading Point",
      },
      {
        accessorFn: (row: any) => row?.minors,
        id: "minors",
        header: "Minors",
        size: 15,
      },
      {
        accessorFn: (row: any) => row?.status?.name,
        id: "status",
        header: "Status",
        size: 40,
      },
      {
        accessorFn: (row: any) =>
          moment(row?.min_travel_time).format("Do MMM YYYY - hh:mm a") +
          "-" +
          moment(row?.max_travel_time).format("hh:mm a"),
        id: "date",
        header: "Date",
      },
    ],
    []
  );

  const loading_point_columns = useMemo<
    MRT_ColumnDef<LoadingPointBookingType>[]
  >(
    () => [
      {
        accessorFn: (row) => `${row?.owner?.name}`,
        header: "Owner",
      },
      {
        accessorFn: (row) => (
          <Box>
            <div
              className={`${
                row.status.code === "BOOKED"
                  ? "text-blue-600"
                  : row.status.code === "PAID"
                  ? "text-green-600"
                  : row.status.code === "SCHEDULED"
                  ? "text-yellow-600"
                  : "text-red-500"
              }`}
            >
              {row.status.name}
            </div>
          </Box>
        ),
        header: "Status",
      },
      {
        accessorFn: (row) => `${row?.currency}${row?.amount_payable}`,
        header: "Amount payable",
      },
      {
        accessorFn: (row) =>
          `${row?.loading_point?.from?.name} - ${
            row?.loading_point?.to?.name
          } ${
            row?.loading_point?.label ? `(${row?.loading_point?.label})` : ""
          }`,

        header: "Loading point",
      },
      {
        accessorFn: (row) => `${row?.num_of_seats} `,
        header: "No. of Seats",
      },
      {
        accessorFn: (row) => `${row?.minors} `,
        header: "Minors",
      },
      {
        accessorFn: (row) => <Box>{moment(row?.travel_date).format("ll")}</Box>,
        header: "Travel Date",
      },
      {
        accessorFn: (row) => (
          <Box>
            {moment(row?.start_travel_time, "HH:mm:ss.SSSSSS").format("HH:mm")}{" "}
            - {moment(row?.end_travel_time, "HH:mm:ss.SSSSSS").format("HH:mm")}
          </Box>
        ),
        header: "Travel Time",
      },
      {
        accessorFn: (row) => `${row?.branch?.name}`,
        header: "Branch",
      },

      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Booked On",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell.getValue()).format("LLL")}</Box>
        ),
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            openFilter ? "" : "grid grid-cols-4"
          }  h-full overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="mt-5">
              <Typography variant="h4">
                Advanced & Loading point bookings
              </Typography>
            </div>
            <div className="flex justify-between items-center mt-5">
              <div className="flex gap-5 items-center">
                <button
                  data-testid="openAdavnceBooking"
                  id="setBookingType1"
                  onClick={() => setBookingType(1)}
                  className={`py-2 px-4 rounded-lg ${
                    bookingType === 1
                      ? "bg-oya-ghana-red text-white hover:bg-red-700"
                      : "bg-white text-oya-ghana-red border-oya-ghana-red border hover:bg-red-100"
                  }`}
                >
                  <div>Advance bookings</div>
                </button>
                <button
                  data-testid="openLoadingPointBooking"
                  id="setBookingType2"
                  onClick={() => setBookingType(2)}
                  className={`py-2 px-4 rounded-lg ${
                    bookingType === 2
                      ? "bg-oya-ghana-red text-white hover:bg-red-700"
                      : "bg-white text-oya-ghana-red border-oya-ghana-red border hover:bg-red-100"
                  }`}
                >
                  <div>Loading point bookings</div>
                </button>
              </div>
              <div>
                <ChevronDoubleLeftIcon
                  className={`${
                    openFilter ? "block" : "block lg:hidden"
                  } h-5 w-5 cursor-pointer`}
                  onClick={() => handleOpenFilter()}
                />
              </div>
            </div>

            {bookingType === 1 && (
              <div className="grid grid-cols-1 mt-3">
                <div className="mt-3 mb-20">
                  <MaterialReactTable
                    enableColumnResizing
                    columns={columns}
                    getRowId={(row) => row.id}
                    onRowSelectionChange={setRowSelection}
                    data={bookingData}
                    muiTablePaperProps={{
                      sx: {
                        borderRadius: "0",
                      },
                    }}
                    // muiTableBodyProps={{
                    //   sx: {
                    //     margin: 40,

                    //     "& tr:nth-of-type(odd)": {
                    //       backgroundColor: "#F0EDEA",
                    //     },
                    //   },
                    // }}
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: () => {
                        handleAdvanceOpen(row.original);
                      },
                      sx: {
                        cursor: "pointer",
                      },
                    })}
                    enableTopToolbar={true}
                    enableColumnActions={false}
                    //enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableFilters={false}
                    enableFullScreenToggle={false}
                    enableSorting={false}
                    manualPagination
                    onPaginationChange={setPagination}
                    initialState={{
                      showColumnFilters: false,
                    }}
                    enablePagination={true}
                    rowCount={rowCount}
                    state={{
                      rowSelection,
                      pagination,
                      showProgressBars: isFetching,

                      density: "compact",
                      isLoading: isLoading,
                    }}
                    // defaultColumn={{
                    //   maxSize: 400,
                    //   minSize: 80,
                    //   size: 120,
                    // }}
                    muiPaginationProps={{
                      showRowsPerPage: false,
                    }}
                  />
                  <AdvanceBookingModal
                    isOpen={openAdvance}
                    onClose={handleAdvanceClose}
                    rowData={selectedAdvanceRow}
                    currentIndex={setCurrentAdvanceIndex}
                    onPrev={handleAdvancePrev}
                    onNext={handleAdvanceNext}
                    disablePrev={currentAdvanceIndex === 0}
                    disableNext={currentAdvanceIndex === bookingData.length - 1}
                  />
                </div>
              </div>
            )}

            {bookingType === 2 && (
              <div className="h-full overflow-hidden">
                <div className="col-span-3 h-full overflow-y-auto pt-[20px]">
                  <div className="grid grid-cols-1">
                    <div className="w-full h-full mb-8">
                      <MaterialReactTable
                        muiTablePaperProps={{
                          sx: {
                            borderRadius: "0",
                          },
                        }}
                        data={bookings}
                        enableFilters={false}
                        enableTopToolbar={true}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableMultiRowSelection={true}
                        enableRowSelection={true}
                        manualPagination
                        enableSorting={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        columns={loading_point_columns}
                        onRowSelectionChange={setLoadingPointRowSelection}
                        state={{
                          rowSelection: loadingPointRowSelection,
                          pagination,
                          isLoading: loading,
                        }}
                        enablePagination={true}
                        rowCount={meta.total}
                        initialState={{
                          showColumnFilters: false,
                          density: "compact",
                        }}
                        enableColumnResizing
                        muiTableBodyRowProps={({ row }) => ({
                          onClick: () => {
                            handleOpen(row.original);
                          },
                          sx: {
                            cursor: "pointer",
                          },
                        })}
                        onPaginationChange={setPagination}
                        renderTopToolbarCustomActions={({ table }) => (
                          <>
                            {table.getSelectedRowModel().rows.length > 0 ? (
                              <CustomButton
                                onClick={() => {
                                  const selectedRows = table
                                    .getSelectedRowModel()
                                    .rows.map((item) => item.original?.id);

                                  localStorage.setItem(
                                    "rows",
                                    JSON.stringify(selectedRows)
                                  );

                                  setShowAssignToSchedule(true);
                                }}
                              >
                                Onboard user(s)
                              </CustomButton>
                            ) : null}
                          </>
                        )}
                      />
                      <LoadingPointBookingModal
                        isOpen={open}
                        onClose={handleClose}
                        rowData={selectedRow}
                        currentIndex={setCurrentIndex}
                        onPrev={handlePrev}
                        onNext={handleNext}
                        disablePrev={currentIndex === 0}
                        disableNext={currentIndex === bookings.length - 1}
                        luggages={luggages}
                      />

                      <AssignToScheduleModal
                        isOpen={showAssignToSchedule}
                        handleClose={() => setShowAssignToSchedule(false)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={`${
              openFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-100 overflow-y-auto pb-[4rem]`}
          >
            <LoadingPointBookingFilter
              onHide={handleCloseFilter}
              handleBranchChange={(value) => {
                setSelectedBranch(value);
              }}
              onCreatorChange={(value) => {
                setTicketOwner(value);
              }}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              handleStatusChange={(value) => {
                setPaymentStatus(value);
              }}
              listType={bookingType}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AllBookingsList;
