import { useEffect, useState } from "react";
import { Drawer, Form, Input, Select } from "antd";
import { Company } from "../../types";
import CustomButton from "../CustomButton";
import PhoneInput, { Value } from "react-phone-number-input";

import { getCompanyTypes } from "../../utils/helpers/commonApiCalls";
import { Company_types } from "../../types";

type Props = {
    company: Company | any;
    isOpen: boolean;
    handleClosed: () => void;
    handleSubmitting: (value: boolean) => void;
    updateCompany: (values: any, phone: any, contact_phone: any) => void;
};
interface CompanyTypes {
    id: number;
    name: string;
    created_at: string;
}

const EditCompany = (props: Props) => {
    const [form] = Form.useForm();
    const [phone, setPhone] = useState<Value | undefined>("");
    const [contact_phone, setContactPhone] = useState<Value | undefined>("");
    const [loadCompanyTypes, setLoadCompanyTypes] = useState<boolean>(false);
    const [companyTypes, setCompanyTypes] = useState<Company_types[]>([]);
    const [selectedCompanyType, setSelectedCompType] = useState<number>(0);

    const validateEmail = (
        rule: any,
        value: string,
        callback: (message?: string) => void
    ) => {
        // Regular expression for basic email validation
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        if (!value || emailRegex.test(value)) {
            callback();
        } else {
            callback("Please enter a valid email address");
        }
    };

    const submit = async () => {
        const values = form.getFieldsValue();

        props.updateCompany(values, phone, contact_phone);
    };

    useEffect(() => {
        setLoadCompanyTypes(true);
        getCompanyTypes()
            .then((res) => {
                setCompanyTypes(
                    res.payload?.items?.map((item: CompanyTypes) => ({
                        value: item?.id,
                        label: item?.name,
                    }))
                );
                setLoadCompanyTypes(false);
            })
            .catch((e) => {
                setLoadCompanyTypes(false);
                console.log(e);
            });
        form.setFieldsValue(props.company);
        setSelectedCompType(Number(props.company?.type?.id));
        console.log(Number(props.company?.type?.id));
        setPhone(props.company?.phone);
        setContactPhone(props.company?.contact_phone);
    }, [props.company, form]);

    return (
        <>
            <Drawer
                title="Edit Company Details"
                placement="right"
                onClose={props.handleClosed}
                open={props.isOpen}
                zIndex={100}
            >
                <div>
                    <Form form={form} layout="vertical" autoComplete="off">
                        <Form.Item
                            label="Company Operation Type"
                            name="company_type_id"
                            className="w-full"
                        >
                            <Select
                                size="large"
                                options={companyTypes}
                                defaultValue={selectedCompanyType}
                                onChange={(value) => {
                                    setSelectedCompType(value);
                                }}
                                loading={loadCompanyTypes}
                            />
                        </Form.Item>
                        <div className="flex items-center">
                            <Form.Item
                                className="w-1/2 mb-2 pr-3"
                                label="Company Name"
                                name="name"
                            >
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2 mb-2"
                                label="Company Code"
                                name="code"
                            >
                                <Input size="large" />
                            </Form.Item>
                        </div>
                        {/* <Form.Item name='middle_name' label='Middle Name' colon={false}>
              <Input />
            </Form.Item> */}

                        <Form.Item
                            label="Phone Number"
                            name="phone"
                            colon={false}
                        >
                            <PhoneInput
                                className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                                defaultCountry="GH"
                                name="phone"
                                value={phone}
                                onChange={(e) => setPhone(e)}
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                { message: "Email is required" },
                                { validator: validateEmail },
                            ]}
                            label="Company Email"
                            name="email"
                            colon={false}
                        >
                            <Input size="large" />
                        </Form.Item>

                        <Form.Item
                            colon={false}
                            label="Contact Person Name"
                            name="contact_name"
                        >
                            <Input size="large" />
                        </Form.Item>
                        <Form.Item
                            name="contact_phone"
                            label="Contact Person Phone"
                            colon={false}
                        >
                            <PhoneInput
                                className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                                defaultCountry="GH"
                                name="contact_phone"
                                value={contact_phone}
                                onChange={(e) => setContactPhone(e)}
                            />
                        </Form.Item>

                        <div className="flex gap-2 justify-end items-end w-full">
                            <CustomButton
                                data-testid="updateCompany"
                                onClick={submit}
                            >
                                <div>Update</div>
                            </CustomButton>
                            <CustomButton
                                data-testid="cancelNewCompany"
                                onClick={props.handleClosed}
                                variant="secondary"
                            >
                                <div>Cancel</div>
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </Drawer>
        </>
    );
};

export default EditCompany;
