import React, { useMemo, useState } from "react";
import PageLayout from "../layouts/PageLayout";
import CustomButton from "../CustomButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Campaign, campaignsData } from "./CampaignData";
import CustomDatePicker from "../../pages/bookings/customDatePicker";
import { endOfDay, startOfDay } from "date-fns";

function CampaignComponent() {
  const columns = useMemo<MRT_ColumnDef<Campaign>[]>(
    () => [
      {
        header: "ID",
        accessorFn: (row) => row.id,
      },

      {
        accessorFn: (row) => row.title,
        header: "Title",
      },
      {
        header: "File",
        accessorFn: (row) => row.fileUploaded,
      },
      {
        header: "Start Date",
        accessorFn: (row) => row.startDate,
      },
      {
        header: "End Date",
        accessorFn: (row) => row.endDate,
      },
      {
        header: "Demographic",
        accessorFn: (row) => row.demographic,
      },
      {
        header: "Age Range",
        accessorFn: (row) => row.ageRange,
      },
      {
        size: 80,
        header: "Region",
        accessorFn: (row) => row.regions,
      },
    ],
    []
  );

  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates(null);
    } else if (selectedDate instanceof Array && selectedDate?.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setDefaultDates([startDate, endDate]);
    }
  };

  const [defaultDates, setDefaultDates] = useState<any>([
    startOfDay(new Date()),
    endOfDay(new Date()),
  ]);

  return (
    <PageLayout>
      <div className="w-full justify-end items-end flex flex-col">
        <div className="mt-5 mr-10">
          <div className="py-2">Filter Date Created</div>

          <div className="flex items-center gap-3">
            <CustomDatePicker
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              placement="leftStart"
            />
            <CustomButton data-testid="addCampaign">
              <div className="flex items-center w-[130px]">
                <PlusIcon className="h-5 w-5" />
                <Link to="/AddCampaign">
                  <div className="text-white">Add Campaign</div>
                </Link>
              </div>
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 mr-5 ml-5">
        <div className="w-full h-full mb-8 mt-20">
          <MaterialReactTable
            muiTablePaperProps={{
              sx: {
                borderRadius: "0",
              },
            }}
            // muiTableBodyProps={{
            //     sx: {
            //         margin: 40,

            //     "& tr:nth-of-type(odd)": {
            //       backgroundColor: "#F0EDEA",
            //     },
            //   },
            // }}
            data={campaignsData}
            enableTopToolbar={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableMultiRowSelection={false}
            enableRowSelection={false}
            manualPagination
            enableSorting={false}
            columns={columns}
            initialState={{
              showColumnFilters: false,
              density: "compact",
            }}
            enableColumnResizing
            muiTableBodyRowProps={({ row }) => ({
              onClick: (event) => {
                console.log(row.original);
              },
              sx: {
                cursor: "pointer",
              },
            })}
          />
        </div>
      </div>
    </PageLayout>
  );
}

export default CampaignComponent;
