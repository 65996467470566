import { notification } from "antd";
import { base_api_request } from "../utils/api";
import { RouteConstants } from "../utils/helpers/RouteConstants";

export const getBranchRoles = async () => {
  try {
    const response = await base_api_request.get(RouteConstants.BRANCH_ROLES);
    return response?.data?.payload?.items;
  } catch (error: any) {
    //console.log(error);
    notification.error({
      message: error?.response?.data?.message || error?.message,
    });
  }
};
