import React from 'react';
import { Skeleton } from 'antd';

const LoadingCards = () => {
  return (
    <div className='p-3 border rounded-md bg-white'>
      <Skeleton paragraph active />
    </div>
  );
};

export default LoadingCards;
