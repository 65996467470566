import React, { useEffect, useState } from "react";
import { Form, Modal, Spin, Input, notification } from "antd";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import { Option, NetworkProvider, Seat } from "../../types";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { base_api_request } from "../../utils/api";
import CustomButton from "../../components/CustomButton";
import { Schedule } from "../branches/branch";
import { Spinner } from "@material-tailwind/react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

const EnrolAdvanceBooking = ({ isOpen, handleClose }: Props) => {
  const bookingId = localStorage.getItem("bookingId");
  const [form] = Form.useForm();
  const [networks, setNetworks] = useState<Option[]>([]);
  const [fetchingProviders, setFetchingProviders] = useState(false);
  const [pay_phone, setPaymentPhone] = useState<string | undefined>();
  const [seatsLoading, setSeatsLoading] = useState(false);
  const [busSeats, setBusSeats] = useState<Seat[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [schedules, setSchedule] = useState<Schedule[]>([]);
  const [tripCode, setTripCode] = useState<string>("");
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule>();
  const [loading, setLoading] = useState<boolean>(false);

  const getNetworkProviders = async () => {
    try {
      setFetchingProviders(true);
      const { data } = await base_api_request.get(
        RouteConstants.NETWORK_PROVIDER
      );
      setNetworks(
        data?.payload?.items?.map((item: NetworkProvider, index: number) => ({
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (e) {
      console.log(e);
    } finally {
      setFetchingProviders(false);
    }
  };

  const handleSearchSchedule = async () => {
    setLoading(true);
    let defaultFilter = [];

    defaultFilter.push({
      f: "code",
      o: "=",
      p: [`${tripCode}`],
    });

    try {
      const response = await base_api_request.get(
        `${RouteConstants.SCHEDULES}?filters=${JSON.stringify(defaultFilter)}`
      );

      setSchedule(response?.data?.payload?.items);
      setLoading(false);
    } catch (error: any) {
      //console.log(error);
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
      setLoading(false);
    }
  };

  const getBusSeats = async (id: number) => {
    setSeatsLoading(true);
    try {
      const res = await base_api_request.get(
        `${RouteConstants.SCHEDULES}/${id}/seats`
      );
      setBusSeats(
        res.data?.payload?.items?.map((item: Seat) => ({
          label: `Seat ${item?.number} - ${
            item?.status === 1 ? "Unavailable" : "Available"
          }`,
          value: item?.id,
        }))
      );
      setSeatsLoading(false);
    } catch (e) {
      console.log(e);
      setSeatsLoading(false);
    }
  };

  const handleFinish = async () => {
    try {
      //send data
      //console.log("bookingId", bookingId);
      form
        .validateFields([
          "seat",
          "account_provider",
          "account_number",
          "account_name",
        ])
        .then(async (val) => {
          const { seat, account_name, account_number, account_provider } = val;
          setSubmitting(true);
          try {
            const res = await base_api_request.post(
              `${RouteConstants.ALL_ADVANCE_BOOKINGS}/${bookingId}/enrol`,
              {
                schedule_id: Number(selectedSchedule?.id),
                seat_id: seat?.value,
                payment: {
                  method: account_provider?.value,
                  account_name,
                  account_number,
                  account_provider: account_provider?.value,
                },
              }
            );
            notification.success({
              message: res?.data?.message,
            });
            setSubmitting(false);
            handleClose();
            localStorage.removeItem("bookingId");
          } catch (error: any) {
            notification.error({
              message: error?.response?.data?.message,
            });
            setSubmitting(false);
          }
        })
        .catch((e) => {
          //
          notification.info({
            message: "Provide required info",
          });
        });
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
    }
  };

  useEffect(() => {
    getNetworkProviders();

    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      footer={null}
      open={isOpen}
      closable={submitting}
      onCancel={() => handleClose()}
    >
      <Spin spinning={submitting}>
        <div className="font-medium">Enrol user unto a schedule</div>
        <Form form={form} layout="vertical">
          <div className="w-[85%] p-2 flex gap-3">
            <Input
              onChange={(e) => setTripCode(e.target.value)}
              prefix={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M14.375 27.1875C7.3125 27.1875 1.5625 21.4375 1.5625 14.375C1.5625 7.3125 7.3125 1.5625 14.375 1.5625C21.4375 1.5625 27.1875 7.3125 27.1875 14.375C27.1875 21.4375 21.4375 27.1875 14.375 27.1875ZM14.375 3.4375C8.3375 3.4375 3.4375 8.35 3.4375 14.375C3.4375 20.4 8.3375 25.3125 14.375 25.3125C20.4125 25.3125 25.3125 20.4 25.3125 14.375C25.3125 8.35 20.4125 3.4375 14.375 3.4375Z"
                    fill="#FF5A55"
                  />
                  <path
                    d="M27.5 28.4375C27.2625 28.4375 27.025 28.35 26.8375 28.1625L24.3375 25.6625C23.975 25.3 23.975 24.7 24.3375 24.3375C24.7 23.975 25.3001 23.975 25.6626 24.3375L28.1626 26.8375C28.5251 27.2 28.5251 27.8 28.1626 28.1625C27.9751 28.35 27.7375 28.4375 27.5 28.4375Z"
                    fill="#FF5A55"
                  />
                </svg>
              }
              placeholder="Enter trip code"
              className="w-full"
              type="number"
              maxLength={6}
              data-testid="tripCodeSearchInput"
            />
            <div className="rounded-lg bg-oya-ghana-red h-[40px] justify-center items-center w-[60px] flex">
              {loading ? (
                <div className="w-full flex justify-center items-center">
                  <Spinner className="text-white" />
                </div>
              ) : (
                <button onClick={() => handleSearchSchedule()} className="p-2">
                  <ArrowRightIcon className="w-6 h-5 text-white" />
                </button>
              )}
            </div>
          </div>
          <div className="mt-2">
            {schedules?.length > 0 ? (
              <>
                {schedules.map((item) => (
                  <div className="p-2 border rounded-[8px] mb-3" key={item?.id}>
                    <div className="py-2 text-[18px]">
                      {item?.route?.from?.name} - {item?.route?.to?.name}
                    </div>
                    <div>
                      Loading point -{" "}
                      {item?.loading_point?.label
                        ? item?.loading_point?.label
                        : "N/A"}
                    </div>
                    <div className="w-full flex justify-end items-end">
                      <button
                        className="font-semibold text-oya-ghana-red"
                        onClick={() => {
                          setSelectedSchedule(item);
                          getBusSeats(item?.id);
                        }}
                      >
                        <div>Select</div>
                      </button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div>No schedules available</div>
            )}
          </div>
          {selectedSchedule && (
            <>
              <Form.Item
                colon={false}
                label="Select a bus seat"
                name="seat"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select a seat"
                  options={busSeats}
                  isOptionDisabled={(option, selectValue) =>
                    option.status === 1
                  }
                  isSearchable
                  isClearable
                  isLoading={seatsLoading}
                />
              </Form.Item>
              <Form.Item
                colon={false}
                label="Choose Payment Network"
                name="account_provider"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Select
                  placeholder="Your network provider"
                  options={networks}
                  isSearchable
                  isClearable
                  isLoading={fetchingProviders}
                />
              </Form.Item>

              <Form.Item
                colon={false}
                label="Mobile Money Number"
                name="account_number"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <PhoneInput
                  value={pay_phone}
                  onChange={(e) => {
                    let formatted = e?.replace(/\s+/g, "");
                    setPaymentPhone(formatted);
                  }}
                  placeholder="0244123456"
                  className="phone bg-white w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                  defaultCountry="GH"
                />
              </Form.Item>

              <Form.Item
                colon={false}
                label="Mobile Money Name"
                name="account_name"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input size="large" placeholder="Full name" />
              </Form.Item>

              <div className="m-5">
                <CustomButton onClick={handleFinish}>
                  <div>Submit</div>
                </CustomButton>
              </div>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default EnrolAdvanceBooking;
