import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import CustomButton from "../CustomButton";
import { Input, Form, Drawer, notification, Spin, TimePicker } from "antd";
import dayjs from "dayjs";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import moment from "moment";

interface modalProps {
  isEditOpened: boolean;
  handleClosed: () => void;
  dataToEdit: any;
  handleReFetch: () => void;
}

const EditLoadingPointTime = ({
  isEditOpened,
  handleClosed,
  dataToEdit,
  handleReFetch,
}: modalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  //on page load
  useEffect(() => {
    form.setFieldValue("name", dataToEdit?.name);
    form.setFieldValue(
      "start_travel_time",
      dayjs(`${dataToEdit?.start_time}`, "HH:mm:ss")
    );
    form.setFieldValue(
      "end_travel_time",
      dayjs(`${dataToEdit?.end_time}`, "HH:mm:ss")
    );

    // eslint-disable-next-line
  }, [dataToEdit]);

  //editBranch
  const submit = async () => {
    form
      .validateFields(["name", "start_travel_time", "end_travel_time"])
      .then(async (values) => {
        try {
          let start_time = moment(values?.start_travel_time?.$d).format();
          let splitStartTime = start_time.split("T")[1];

          let end_time = moment(values?.end_travel_time?.$d).format();
          let splitEndTime = end_time.split("T")[1];
          setLoading(true);

          let payload = {
            name: values?.name,
            start_travel_time: splitStartTime.split("+")[0],
            end_travel_time: splitEndTime.split("+")[0],
          };

          await base_api_request.put(
            `${RouteConstants.LOADING_POINT_TRAVEL_TIME}/${dataToEdit?.id}`,
            payload
          );
          setLoading(false);
          notification.success({
            message: "Loading point time edited successfully",
          });
          handleClosed();
          handleReFetch();
        } catch (e: any) {
          notification.error({
            message: e?.response?.data?.message,
          });
          setLoading(false);
          console.log(e);
        }
      })
      .catch((e) => {
        notification.error({ message: "Please provide required fields" });
      });
  };

  return (
    <>
      <Drawer
        title="Edit Loading Point Travel Time"
        placement="right"
        onClose={handleClosed}
        open={isEditOpened}
        zIndex={100}
        size="default"
      >
        <Spin spinning={loading}>
          <div className="">
            <div className="h-screen">
              <Form form={form} layout="vertical" autoComplete="none">
                <Form.Item label="Name" name="name">
                  <Input />
                </Form.Item>
                <Form.Item
                  className="w-full"
                  label="Start Travel time"
                  name="start_travel_time"
                  required
                >
                  <TimePicker className="w-full" size="large" />
                </Form.Item>

                <Form.Item
                  className="w-full"
                  label="End Travel time"
                  name="end_travel_time"
                  required
                >
                  <TimePicker className="w-full" size="large" />
                </Form.Item>
              </Form>
              <div className="flex w-full justify-end items-end mt-5 pb-5">
                <div className="flex items-center gap-3">
                  <CustomButton
                    data-testid="submitNewBranch"
                    disabled={loading}
                    onClick={submit}
                  >
                    Submit
                  </CustomButton>
                  <Button
                    data-testid="closeNewBranch"
                    disabled={loading}
                    variant="outlined"
                    size="sm"
                    onClick={handleClosed}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditLoadingPointTime;
