import { useEffect, useState } from "react";
import { auth_api_call, base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { Option, Routes, User } from "../../types";
import AsyncSelect from "react-select/async";
import { Typography } from "@material-tailwind/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import {
  PaymentMethodOption,
  PaymentStatusOption,
} from "../../utils/constants";
import Select from "react-select";
import { getAllUsers, getRoutes } from "../../utils/helpers/commonApiCalls";
import CustomDatePicker from "../../pages/bookings/customDatePicker";
import Checkbox from "../utils/Checkbox";
// import { Refresh } from "@mui/icons-material";
import { debounce } from "lodash";

interface filterProps {
  handleFilterClose: () => void;
  defaultDates: any;
  handleCalendarChange: (date: any) => void;
  isMidRoute: boolean;
  onMidRouteChange: (date: any) => void;
  onTicketOwnerChnage: (date: any) => void;
  onPayMethodChange: (date: any) => void;
  onPayStatsChange: (date: any) => void;
  onRouteChange: (date: any) => void;
  handleReset: () => void;
}

const TicketsFilter = ({
  handleFilterClose,
  defaultDates,
  handleCalendarChange,
  isMidRoute,
  onMidRouteChange,
  onTicketOwnerChnage,
  onPayMethodChange,
  onPayStatsChange,
  onRouteChange,
  handleReset,
}: filterProps) => {
  const [routeOptions, setRouteOptions] = useState<Option[]>([]);
  const [users, setUsers] = useState<Option[]>([]);

  //onload
  useEffect(() => {
    getRoutes()
      .then((res) => {
        setRouteOptions(
          res.payload?.items?.map((item: Routes) => ({
            label: `${item?.from?.name} - ${item?.to?.name}`,
            value: item?.id,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });

    getAllUsers()
      .then((res) => {
        setUsers(
          res.payload?.items?.map((item: User) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const loadRouteOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "from.name",
          o: "contains",
          p: [`${inputValue}`],
          c: "OR",
        },
        {
          f: "to.name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ROUTES}?filters=${JSON.stringify(filterData)}`
        );
        const options = res.data?.payload?.items?.map((item: Routes) => ({
          label: `${item?.from?.name} - ${item?.to?.name}`,
          value: item?.id,
        }));

        callback(options);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  const loadUserOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "first_name",
          o: "contains",
          p: [`${inputValue}`],
          c: "OR",
        },
        {
          f: "last_name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await auth_api_call.get(
          `${RouteConstants.ACCOUNTS}?filters=${JSON.stringify(filterData)}`
        );
        setUsers(
          res.data?.payload?.items?.map((item: User) => ({
            label: item?.name,
            value: item?.id,
          }))
        );

        callback(
          res.data?.payload?.items?.map((item: User) => ({
            label: item?.name,
            value: item?.id,
          }))
        );

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  return (
    <div className="px-3 py-[20px] bg-white border-l-[1px] border-gray-500 h-full overflow-y-auto">
      <div className="mb-2 flex justify-between items-center mt-8">
        <Typography variant="h5" color="blue-gray">
          Filters
        </Typography>
        <button
          onClick={() => {
            handleFilterClose();
          }}
        >
          <ChevronDoubleRightIcon className="w-5 h-5 cursor-pointer" />
        </button>
      </div>

      <div className="mb-4 w-full">
        <div className="py-2">Date Created</div>
        <CustomDatePicker
          defaultDates={defaultDates}
          handleDateChange={(data) => handleCalendarChange(data)}
        />
      </div>

      <div className="mb-4">
        <div className="pb-2">Search Route</div>
        <AsyncSelect
          loadOptions={(inputValue, callback) => {
            loadRouteOptions(inputValue, callback);
          }}
          onChange={(e: any) => {
            onRouteChange(e);
          }}
          isSearchable
          isClearable
          defaultOptions={routeOptions}
        />
      </div>

      <div className="mb-4">
        <div className="py-2">Payment Status</div>
        <Select
          options={PaymentStatusOption}
          isClearable
          onChange={(e) => {
            onPayStatsChange(e);
          }}
        />
      </div>

      <div className="mb-4">
        <div className="py-2">Payment Method</div>
        <Select
          isClearable
          options={PaymentMethodOption}
          onChange={(e) => {
            onPayMethodChange(e);
          }}
        />
      </div>

      <div className="mb-4">
        <div className="py-2">Ticket Owner</div>
        <AsyncSelect
          defaultOptions={users}
          onChange={(e) => {
            onTicketOwnerChnage(e);
          }}
          loadOptions={(inputValue, callback) => {
            loadUserOptions(inputValue, callback);
          }}
          placeholder="Owner"
          isSearchable
          isClearable
        />
      </div>

      <div className="mb-4">
        <div className="py-2">Mid-Route</div>
        <Checkbox checked={isMidRoute} onChange={onMidRouteChange}>
          <div className="italic">Show With Mid-Route</div>
        </Checkbox>
      </div>

      {/* <div className="w-full mt-3">
        <button
          data-testid="resetTicketFilters"
          className="rounded-[5px] p-2 bg-oya-ghana-red w-full flex gap-3 items-center text-white justify-center"
          onClick={() => handleReset()}
        >
          <Refresh
            sx={{
              color: "#fff",
              width: "25px",
              height: "25px",
            }}
          />
          Reset Filters
        </button>
      </div> */}
    </div>
  );
};

export default TicketsFilter;
