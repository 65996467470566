import { useEffect, useState } from "react";
import { base_api_request } from "../../utils/api";
import { Option } from "../../types";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import AsyncSelect from "react-select/async";
import { getPorters } from "../../utils/helpers/commonApiCalls";
import { debounce } from "lodash";

interface selectProps {
  onChange?: (data: any) => void;
}

const PorterSelector = ({ onChange }: selectProps) => {
  // const [loading, setLoading] = useState(false);
  // const [officerData, setOfficerData] = useState([]);

  const [porterOptions, setPorterOptions] = useState<Option[]>([]);

  useEffect(() => {
    getPorters()
      .then((res: any) => {
        setPorterOptions(
          res.payload?.items?.map((item: any) => ({
            label: item?.user?.name,
            value: item?.user?.id,
          }))
        );
      })
      .catch((e: any) => console.log(e));
  }, []);

  const loadPorterOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      const defaultFilter: any = [
        {
          g: [
            {
              f: "account_type.code",
              o: "=",
              p: ["PORTER"],
            },
          ],
        },
      ];

      if (inputValue) {
        defaultFilter.push({
          g: [
            {
              f: "user.name",
              o: "contains",
              p: [inputValue],
              c: "OR",
            },
            {
              f: "user.phone",
              o: "contains",
              p: [inputValue],
            },
          ],
        });
      }

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ALL_STAFF}?filters=${JSON.stringify(defaultFilter)}`
        );

        const searchOptions = res.data?.payload?.items?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  return (
    <>
      <div className="py-2 text-gray-500">Filter by Porter</div>
      <AsyncSelect
        loadOptions={(inputValue, callback) => {
          loadPorterOptions(inputValue, callback);
        }}
        onChange={onChange}
        isSearchable
        isClearable
        defaultOptions={porterOptions}
      />
    </>
  );
};

export default PorterSelector;
