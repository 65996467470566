import { Button, Typography } from "@material-tailwind/react";
import { Form, Input, Modal, Spin, notification } from "antd";
import CustomButton from "../CustomButton";
import { useState } from "react";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

interface modalProps {
  isOpened: boolean;
  handleClosed: () => void;
  handleReFetch: () => void;
}

const CreateRegion = ({
  isOpened,
  handleClosed,
  handleReFetch,
}: modalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      //console.log(values);
      const response = await base_api_request.post(RouteConstants.ALL_REGIONS, {
        name: values.name,
        code: values.code,
      });
      console.log(response);

      setLoading(false);
      notification.success({
        message: "Region created successfully",
      });
      handleClosed();
      handleReFetch();
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message,
      });
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Modal open={isOpened} onCancel={handleClosed} footer={null}>
        <div>
          <Typography variant="h5">Create Region</Typography>

          <Form
            form={form}
            className="mt-5"
            layout="vertical"
            autoComplete="none"
          >
            <Form.Item
              required
              className="w-full"
              label="Region Code"
              name="code"
            >
              <Input size="large" placeholder="CODE" />
            </Form.Item>

            <Form.Item
              required
              className="w-full mt-4"
              label="Region Name"
              name="name"
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
          </Form>

          <div className="flex w-full justify-end items-end mt-10">
            <div className="flex items-center gap-3">
              <CustomButton
                data-testid="submitNewRegion"
                disabled={loading}
                onClick={submit}
              >
                {loading ? <Spin /> : "Submit"}
              </CustomButton>
              <Button
                data-testid="closeNewRegion"
                disabled={loading}
                variant="outlined"
                size="sm"
                onClick={handleClosed}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateRegion;
