import { useState, useEffect } from "react";
import { Input, Form, Modal, notification, Spin } from "antd";
import { Button, Typography } from "@material-tailwind/react";
import { base_api_request } from "../../utils/api";
import CustomButton from "../../components/CustomButton";
import AsyncSelect from "react-select/async";
import { Option } from "../../types";
import debounce from "lodash/debounce";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { getBranches } from "../../utils/helpers/commonApiCalls";
import { Branch } from "./branch";

const AddLoadingPointPriceModal = ({
  handleClosed,
  isOpen,
  handleReFetch,
}: {
  handleClosed: () => void;
  isOpen: boolean;
  handleReFetch: () => void;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingPointBranchOptions, setloadingPointBranchOptions] = useState<
    Option[]
  >([]);
  const [branches, setBranches] = useState<Option[]>([]);
  const [, setSelectedLoadingPoint] = useState<number | undefined>();
  const [selectedBranch, setSelectedBranch] = useState<any>();

  const loadLoadingPointOptions = debounce(
    async (searchVal: string, callback: (option: any) => void) => {
      if (searchVal) {
        let filterData = [
          {
            f: "source.name",
            o: "contains",
            p: [`${searchVal}`],
            c: "OR",
          },
          {
            f: "destination.name",
            o: "contains",
            p: [`${searchVal}`],
          },
        ];

        try {
          const res = await base_api_request.get(
            `${RouteConstants.ALL_LOADING_POINTS}?filters=${JSON.stringify(
              filterData
            )}`
          );

          const searchOptions = res.data?.payload?.items?.map((item: any) => ({
            label: `${item?.source?.name} - ${item?.destination?.name} ${
              item?.label ? `(${item?.label})` : ""
            }`,
            value: item?.id,
          }));
          callback(searchOptions);
        } catch (error) {
          console.log(error);
        }
      }
    },
    1000
  );

  const loadBranchOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ALL_BRANCHES}?filters=${JSON.stringify(filterData)}`
        );

        const searchOptions = res.data?.payload?.items?.map((item: Branch) => ({
          label: item?.name,
          value: item?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  //create loading point
  const submit = async () => {
    try {
      setLoading(true);
      const data = form.getFieldsValue();
      await base_api_request.post(RouteConstants.ALL_LOADING_POINT_PRICES, {
        price: data.price,
        loading_point_id: data.loading_point_id?.value,
      });
      setLoading(false);
      notification.success({
        message: "Loading Point price added successfully",
      });
      setLoading(false);
      handleClosed();
      handleReFetch();
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message,
      });
      setLoading(false);
      console.log(e);
    }
  };

  const getLoadingPoints = async (branchId: number | undefined) => {
    if (branchId) {
      setLoading(true);
      try {
        const filter = [
          {
            f: "station.id",
            o: "=",
            p: [branchId],
          },
        ];
        const { data } = await base_api_request.get(
          `${RouteConstants.ALL_LOADING_POINTS}?filters=${JSON.stringify(
            filter
          )}`
        );
        setloadingPointBranchOptions(
          data?.payload?.items?.map((item: any) => ({
            label: `${item?.source?.name} - ${item?.destination?.name} ${
              item?.label ? `(${item?.label})` : ""
            }`,
            value: item?.id,
          }))
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getBranches()
      .then((res) => {
        setBranches(
          res?.payload?.items?.map((item: any) => ({
            label: `${item?.name}`,
            value: item?.id,
          }))
        );
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <Modal open={isOpen} onCancel={handleClosed} footer={null}>
      <Spin spinning={loading}>
        <div>
          <Typography variant="h5">Add Loading Point Price</Typography>
          <Form
            form={form}
            className="mt-5"
            layout="vertical"
            autoComplete="none"
          >
            <Form.Item
              className="w-full"
              label="Select Branch"
              name="branch_id"
              required
            >
              <AsyncSelect
                loadOptions={(inputValue, callback) => {
                  loadBranchOptions(inputValue, callback);
                }}
                onChange={(e: any) => {
                  setSelectedBranch(e);
                  getLoadingPoints(e?.value);
                }}
                isSearchable
                isClearable
                defaultOptions={branches}
              />
            </Form.Item>
            {selectedBranch && (
              <>
                <Form.Item
                  className="w-full"
                  label="Select Loading Point"
                  name="loading_point_id"
                  required
                >
                  <AsyncSelect
                    loadOptions={(inputValue, callback) => {
                      loadLoadingPointOptions(inputValue, callback);
                    }}
                    onChange={(e: any) => {
                      setSelectedLoadingPoint(e?.value);
                    }}
                    isSearchable
                    isClearable
                    isLoading={loading}
                    defaultOptions={loadingPointBranchOptions}
                  />
                </Form.Item>
                <Form.Item
                  required
                  className="w-full"
                  name="price"
                  label="Specify the price"
                >
                  <Input type="number" size="large" placeholder="Price" />
                </Form.Item>
              </>
            )}
          </Form>
          <div className="flex w-full justify-end items-end mt-10">
            <div className="flex items-center gap-3">
              <CustomButton
                data-testid="continueToSubmit"
                disabled={loading}
                onClick={submit}
              >
                Continue
              </CustomButton>
              <Button
                data-testid="closePriceModal"
                disabled={loading}
                variant="outlined"
                size="sm"
                onClick={handleClosed}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AddLoadingPointPriceModal;
