import React from "react";
import LoadingCards from "./LoadingCards";
import { Typography } from "@material-tailwind/react";

const Card = ({
    title,
    stat,
    icon,
    isLoading,
}: {
    title: string;
    stat: number | string;
    icon: React.ReactNode;
    isLoading: boolean;
}) => {
    if (isLoading) {
        return <LoadingCards />;
    }
    return (
        <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
            <div className="flex justify-between items-center">
                <div>
                    <Typography className="font-semibold">{title}</Typography>

                    <Typography
                        variant="h3"
                        className="mt-3 flex justify-start"
                    >
                        {stat}
                    </Typography>
                </div>
                <div className="text-oya-ghana-red">{icon}</div>
            </div>
        </div>

        // <div className='bg-white p-3 rounded-md shadow m-4 my-3 md:my-0 w-full'>
        //   <div className='flex items-center'>
        //     <div className='flex-[0.6]'>
        //       <div>{title}</div>
        //       <div className='my-4 sm:text-xl font-bold'>{stat}</div>
        //     </div>
        //     <div className='flex-[0.4]'>
        //       <div>{icon}</div>
        //     </div>
        //   </div>
        // </div>
    );
};

export default Card;
