import Navbar from './Navbar';
import CustomSidebar from './CustomSidebar';

const PageLayout = ({ children }: any) => {
  return (
    <div className='h-screen overflow-hidden'>
      <div className='flex h-full'>
        {/* sidebar */}
        <CustomSidebar />
        {/* navbar */}
        <div className='w-full '>
          <Navbar />
          <div className='h-screen overflow-y-auto'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
