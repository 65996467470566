// api.js
import axios from "axios";
import { RIDE_MS_URL, AUTH_MS_URL, ACCOUNTS_MS_URL } from "./constants";
import { getCookie } from "./functions";

const token = getCookie("oya_token");

export const base_api_request = axios.create({
    baseURL: `${RIDE_MS_URL}`,

    headers: {
        Authorization: `Bearer ${token}`,
    },
});

export const auth_api_call = axios.create({
    baseURL: `${AUTH_MS_URL}`,

    headers: {
        Authorization: `Bearer ${token}`,
    },
});

export const accounts_api_call = axios.create({
    baseURL: `${ACCOUNTS_MS_URL}`,

    headers: {
        Authorization: `Bearer ${token}`,
    },
});
