import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import CustomButton from "../CustomButton";
import { Input, Form, Drawer, notification, Spin } from "antd";

import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

interface modalProps {
  isEditOpened: boolean;
  handleClosed: () => void;
  dataToEdit: any;
  handleReFetch: () => void;
}

const EditLoadingPointPrice = ({
  isEditOpened,
  handleClosed,
  dataToEdit,
  handleReFetch,
}: modalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [price, setPrice] = useState("");

  //on page load
  useEffect(() => {
    form.setFieldValue("price", dataToEdit?.price);

    // eslint-disable-next-line
  }, [dataToEdit]);

  //editBranch
  const submit = async () => {
    if (price) {
      try {
        setLoading(true);

        await base_api_request.put(
          `${RouteConstants.ALL_LOADING_POINT_PRICES}/${dataToEdit?.id}`,
          {
            // ...values,
            price,
          }
        );
        setLoading(false);
        notification.success({
          message: "Loading point price edited successfully",
        });
        handleClosed();
        handleReFetch();
      } catch (e: any) {
        notification.error({
          message: e?.response?.data?.message,
        });
        setLoading(false);
        console.log(e);
      }
    } else {
      notification.error({ message: "Please set a price " });
    }
  };

  return (
    <>
      <Drawer
        title="Edit Loading Point Price"
        placement="right"
        onClose={handleClosed}
        open={isEditOpened}
        zIndex={100}
        size="default"
      >
        <Spin spinning={loading}>
          <div className="">
            <div className="h-screen">
              <Form form={form} layout="vertical" autoComplete="none">
                <div>
                  <Form.Item
                    required
                    className="w-full"
                    name="price"
                    label="Loading Point Price"
                  >
                    <Input
                      size="large"
                      placeholder="alias"
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </Form>
              <div className="flex w-full justify-end items-end mt-5 pb-5">
                <div className="flex items-center gap-3">
                  <CustomButton
                    data-testid="submitNewBranch"
                    disabled={loading}
                    onClick={submit}
                  >
                    Submit
                  </CustomButton>
                  <Button
                    data-testid="closeNewBranch"
                    disabled={loading}
                    variant="outlined"
                    size="sm"
                    onClick={handleClosed}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditLoadingPointPrice;
