import React, { useState } from "react";
import { Modal, Spin, Form, notification, Input } from "antd";
import { Typography, Button } from "@material-tailwind/react";
import CustomButton from "../CustomButton";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

const CreateBusType = ({
    handleClosed,
    isOpen,
    handleReFetch,
}: {
    handleClosed: () => void;
    isOpen: boolean;
    handleReFetch: () => void;
}) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    const submit = async () => {
        form.validateFields(["code", "name"])
            .then(async (values) => {
                try {
                    setSubmitting(true);
                    await base_api_request.post(RouteConstants.BUS_TYPES, {
                        name: values?.name,
                        code: values?.code,
                    });
                    notification.success({
                        message: "Bus type created",
                    });
                } catch (error: any) {
                    notification.error({
                        message:
                            error?.response?.data?.message || error?.message,
                    });
                } finally {
                    setSubmitting(false);
                    handleReFetch();
                    handleClosed();
                }
            })
            .catch(() => {
                //
            });
    };

    return (
        <Modal open={isOpen} onCancel={handleClosed} footer={null}>
            <Spin spinning={submitting}>
                <div>
                    <Typography variant="h5">Create bus type</Typography>
                    <Form
                        form={form}
                        className="mt-5"
                        layout="vertical"
                        autoComplete="none"
                    >
                        <Form.Item
                            name="code"
                            label="Code"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Enter a code" />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label="Name of bus type"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Enter a name" />
                        </Form.Item>
                    </Form>
                    <div className="flex w-full justify-end items-end mt-10">
                        <div className="flex items-center gap-3">
                            <CustomButton
                                data-testid="continueNewBusType"
                                disabled={submitting}
                                onClick={() => submit()}
                            >
                                Continue
                            </CustomButton>
                            <Button
                                data-testid="closeNewBusType"
                                disabled={submitting}
                                variant="outlined"
                                size="sm"
                                onClick={handleClosed}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default CreateBusType;
