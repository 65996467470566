import { useState } from "react";
import { Modal, Spin, notification } from "antd";
import { Button, Typography } from "@material-tailwind/react";
import CustomButton from "../CustomButton";
import { base_api_request } from "../../utils/api";
import Select from "react-select";

import { debounce } from "lodash";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

interface modalProps {
    handleClosed: () => void;
    isOpen: boolean;
    handleReFetch: () => void;
}

const CreateRoute = ({ isOpen, handleClosed, handleReFetch }: modalProps) => {
    const [fetchingSource, setFetchingSource] = useState(false);
    const [sourceTowns, setSourceTown] = useState([]);
    const [theSource, setTheSource] = useState("");
    const [fetchingDest, setFetchingDest] = useState(false);
    const [destinationTowns, setDestinationTowns] = useState([]);
    const [theDestination, setTheDestination] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    //fetchsourceTowns
    const fetchSourceTowns = debounce((inputValue: any) => {
        if (inputValue) {
            setFetchingSource(true);

            base_api_request
                .get(
                    `${
                        RouteConstants.TOWNS
                    }?filters=[{"f":"name","o":"contains", "p":[${JSON.stringify(
                        inputValue
                    )}]}]`
                )
                .then((response) => {
                    const newOptions = response?.data?.payload?.items.map(
                        (item: any) => ({
                            value: item?.id,
                            label: item?.name,
                        })
                    );
                    // console.log(response?.data?.payload);
                    setSourceTown(newOptions);
                    setFetchingSource(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, 800);

    //fetchDestinationTowns
    const fetchDestinationTowns = debounce((inputValue: any) => {
        if (inputValue) {
            setFetchingDest(true);

            base_api_request
                .get(
                    `${
                        RouteConstants.TOWNS
                    }?filters=[{"f":"name","o":"contains", "p":[${JSON.stringify(
                        inputValue
                    )}]}]`
                )
                .then((response) => {
                    const newOptions = response?.data?.payload?.items.map(
                        (item: any) => ({
                            value: item?.id,
                            label: item?.name,
                        })
                    );
                    // console.log(response?.data?.payload);
                    setDestinationTowns(newOptions);
                    setFetchingDest(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, 800);

    //create routes
    const submit = async () => {
        if (!theSource) {
            notification.error({ message: "Please select a pickup" });
        } else if (!theDestination) {
            notification.error({ message: "Please select a destination" });
        } else if (theSource === theDestination) {
            notification.error({
                message: "Pickup and destination cannot be the same",
            });
        } else {
            try {
                setIsBusy(true);

                let data = {
                    source_id: theSource,
                    destination_id: theDestination,
                };

                const response = await base_api_request.post(
                    RouteConstants.ROUTES,
                    data
                );
                console.log(response?.data);
                notification.success({ message: "Route created successfully" });
                setIsBusy(false);
                handleClosed();
                handleReFetch();
            } catch (e: any) {
                notification.error({
                    message: e?.response?.data?.message,
                });
                setIsBusy(false);
                console.log(e);
            }
        }
    };

    return (
        <Modal open={isOpen} onCancel={handleClosed} footer={null}>
            <div>
                <Typography variant="h5">Create a new route</Typography>

                <hr className="" />

                <div className="mt-2">
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Search Source Town"
                        isClearable={true}
                        isSearchable={true}
                        options={sourceTowns}
                        isLoading={fetchingSource}
                        isDisabled={isBusy}
                        onInputChange={(input: any) => fetchSourceTowns(input)}
                        onChange={(newValue: any) =>
                            setTheSource(newValue?.value)
                        }
                    />
                </div>

                <div className="mt-4">
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Search Destination Town"
                        isClearable={true}
                        isSearchable={true}
                        options={destinationTowns}
                        isLoading={fetchingDest}
                        isDisabled={isBusy}
                        onInputChange={(input: any) =>
                            fetchDestinationTowns(input)
                        }
                        onChange={(newValue: any) =>
                            setTheDestination(newValue?.value)
                        }
                    />
                </div>

                <div className="flex w-full justify-end items-end mt-10">
                    <div className="flex items-center gap-3">
                        <CustomButton
                            data-testid="continueNewRoute"
                            disabled={isBusy}
                            onClick={submit}
                        >
                            {isBusy ? <Spin /> : "Continue"}
                        </CustomButton>
                        <Button
                            data-testid="closeNewRoute"
                            disabled={isBusy}
                            variant="outlined"
                            size="sm"
                            onClick={handleClosed}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateRoute;
