import React, { useState, useEffect } from "react";
import CustomButton from "../CustomButton";
import {
    Drawer,
    Form,
    Input,
    DatePicker,
    TimePicker,
    notification,
} from "antd";
import Loader from "../utils/Loader";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Checkbox from "../utils/Checkbox";
import { disabledDate } from "../../utils/functions";
import { Option, LoadingPoint, Bus } from "../../types";
import { base_api_request } from "../../utils/api";
import moment from "moment";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { debounce } from "lodash";

const CreateSchedule = ({
    isOpen,
    handleClosed,
    refetch,
}: {
    isOpen: boolean;
    handleClosed: () => void;
    refetch: () => void;
}) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [busCompId, setBusCompId] = useState<number | undefined>();
    const [branchId, setBranchId] = useState<any>();
    const [loadingPoints, setLoadingPoints] = useState<Option[]>([]);
    const [branches, setBranches] = useState<Option[]>([]);
    const [buses, setBuses] = useState<Option[]>([]);
    const [drivers, setDrivers] = useState<Option[]>([]);
    const [conductors, setConductors] = useState<Option[]>([]);
    const [porters, setPorters] = useState<Option[]>([]);
    const [fieldOfficers, setFieldOfficers] = useState<Option[]>([]);
    const [branchSupervisors, setBranchSupervisors] = useState<Option[]>([]);

    const [allowMidRoute, setAllowMidRoute] = useState<boolean>(false);
    const [isHighPriority, setIsHighPriority] = useState<boolean>(false);
    const [isTicketing, setIsTicketing] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [fetchingLoadingPoints, setFetchingLoadingPoints] = useState(false);
    const [prepaid, setPrepaid] = useState(false);
    const [isPremium, setIsPremium] = useState(false);

    const getLoadingPoints = async (branchId: number | undefined) => {
        //from brranch id
        if (branchId) {
            setFetchingLoadingPoints(true);
            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_BRANCHES}/${branchId}/loading-points`
                );
                setLoadingPoints(
                    res.data?.payload?.items?.map((item: LoadingPoint) => ({
                        label: `${item?.source?.name} - ${item?.destination?.name}`,
                        value: item?.id,
                    }))
                );
                setFetchingLoadingPoints(false);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const loadLoadingPointOptions = debounce(
        async (inputValue: string, callback: (option: any) => void) => {
            if (branchId) {
                try {
                    const filter = [
                        {
                            f: "source.name",
                            o: "contains",
                            p: [`${inputValue}`],
                            c: "OR",
                        },
                        {
                            f: "destination.name",
                            o: "contains",
                            p: [`${inputValue}`],
                        },
                    ];
                    const res = await base_api_request.get(
                        `${RouteConstants.ALL_BRANCHES
                        }/${branchId}/loading-points?filters=${JSON.stringify(
                            filter
                        )}`
                    );
                    const options = res.data?.payload?.items?.map(
                        (item: LoadingPoint) => ({
                            label: `${item?.source?.name} - ${item?.destination?.name} ${item?.label ? `(${item?.label})` : ''}`,
                            value: item?.id,
                        })
                    );
                    callback(options);
                } catch (e) {
                    console.log(e);
                }
            }
        },
        1500
    );

    const getDrivers = async (branchId: number | undefined) => {
        if (branchId) {
            const filter = [
                {
                    f: "account_type.code",
                    o: "=",
                    p: ["DRIVER"],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_BRANCHES
                    }/${branchId}/staff?filters=${JSON.stringify(filter)}`
                );
                setDrivers(
                    res.data?.payload?.items?.map((item: any) => ({
                        label: item?.user?.name,
                        value: item?.id,
                    }))
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    const getConductors = async (branchId: number | undefined) => {
        if (branchId) {
            const filter = [
                {
                    f: "account_type.code",
                    o: "=",
                    p: ["CONDUCTOR"],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_BRANCHES
                    }/${branchId}/staff?filters=${JSON.stringify(filter)}`
                );
                setConductors(
                    res.data?.payload?.items?.map((item: any) => ({
                        label: item?.user?.name,
                        value: item?.id,
                    }))
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    const getPorters = async (branchId: number | undefined) => {
        if (branchId) {
            const filter = [
                {
                    f: "account_type.code",
                    o: "=",
                    p: ["PORTER"],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_BRANCHES
                    }/${branchId}/staff?filters=${JSON.stringify(filter)}`
                );
                setPorters(
                    res.data?.payload?.items?.map((item: any) => ({
                        label: item?.user?.name,
                        value: item?.id,
                    }))
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    const getFieldOfficers = async (branchId: number | undefined) => {
        if (branchId) {
            const filter = [
                {
                    f: "account_type.code",
                    o: "=",
                    p: ["FIELD_OFFICER"],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_BRANCHES
                    }/${branchId}/staff?filters=${JSON.stringify(filter)}`
                );
                console.log(res.data?.payload?.items);
                setFieldOfficers(
                    res.data?.payload?.items?.map((item: any) => ({
                        label: item?.user?.name,
                        value: item?.id,
                    }))
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    const getBranchSupervisor = async (branchId: number | undefined) => {
        if (branchId) {
            const filter = [
                {
                    f: "account_type.code",
                    o: "=",
                    p: ["BRANCH_SUPERVISOR"],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_BRANCHES
                    }/${branchId}/staff?filters=${JSON.stringify(filter)}`
                );
                console.log(res.data?.payload?.items);
                setBranchSupervisors(
                    res.data?.payload?.items?.map((item: any) => ({
                        label: item?.user?.name,
                        value: item?.id,
                    }))
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    const loadBusOptions = debounce(
        async (inputValue: string, callback: (option: any) => void) => {
            const filter = [
                {
                    f: "company.id",
                    o: "=",
                    p: [busCompId],
                },
                {
                    f: "reg_number",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];
            try {
                const res = await base_api_request.get(
                    `${RouteConstants.BUSES}/?filters=${JSON.stringify(filter)}`
                );
                const busOptions = res.data?.payload?.items?.map(
                    (item: Bus) => ({
                        label: item?.reg_number,
                        value: item?.id,
                    })
                );

                callback(busOptions);
            } catch (e) {
                console.log(e);
            }
        },
        1500
    );

    const getBuses = async (company_Id: number | undefined) => {
        //from companies id
        if (company_Id) {
            const filter = [
                {
                    f: "company.id",
                    o: "=",
                    p: [company_Id],
                },
            ];
            try {
                const res = await base_api_request.get(
                    `${RouteConstants.BUSES}/?filters=${JSON.stringify(filter)}`
                );
                setBuses(
                    res.data?.payload?.items?.map((item: Bus) => ({
                        label: item?.reg_number,
                        value: item?.id,
                    }))
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    const getBranchDetails = async (branchId: number | undefined) => {
        if (branchId) {
            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_BRANCHES}/${branchId}/details`
                );
                setBusCompId(res.data?.payload?.bus_company?.id);
                getBuses(res.data?.payload?.bus_company?.id);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const getBranches = async () => {
        try {
            const res = await base_api_request.get(
                RouteConstants.MY_BRANCH_ROLES
            );
            setBranches(
                res.data?.payload?.items?.map((item: any) => ({
                    label: `${item?.account_type?.name} @ ${item?.branch?.name}`,
                    value: item?.branch,
                }))
            );
        } catch (e) {
            console.log(e);
        }
    };

    const handleRequests = (value: any) => {
        setBranchId(value?.id);
        getLoadingPoints(value?.id);
        getBranchDetails(value?.id);
        getPorters(value?.id);
        getFieldOfficers(value?.id);
        getBranchSupervisor(value?.id);
        getDrivers(value?.id);
        getConductors(value?.id);
        setLoading(false);
    };

    const submit = async () => {
        setSubmitting(true);
        const data = form.getFieldsValue();
        let depart_date = moment(data?.departure_date?.$d).format();
        let depart_time = moment(data?.departure_time?.$d).format();

        try {
            await base_api_request.post(
                `${RouteConstants.ALL_BRANCHES}/${data?.branch_id?.value?.id}/schedules`,
                {
                    price: data?.price,
                    mid_route: allowMidRoute,
                    priority: isHighPriority,
                    ticketing: isTicketing,
                    loading_point_id: data?.loading_point_id?.value,
                    bus_id: data?.bus_id?.value,
                    driver_id: data?.driver_id?.value,
                    conductor_id: data?.conductor_id?.value,
                    porter_id: data?.porter_id?.value,
                    field_officer_id: data?.field_officer_id?.value,
                    branch_supervisor_id: data?.branch_supervisor_id?.value,
                    prepaid: prepaid,
                    premium: isPremium,
                    departure_time: `${moment(depart_date).format(
                        "YYYY-MM-DD"
                    )}T${depart_time?.split("T")[1]}`,
                }
            );
            notification.success({
                message: "Schedule created",
            });
            form.resetFields();
            setSubmitting(false);
            refetch();
            handleClosed();
        } catch (e: any) {
            notification.error({
                message: e?.response?.data?.message,
            });
            setSubmitting(false);
        }
    };

    useEffect(() => {
        getBranches();

        // getDrivers();
        // getConductors();
        // getPorters();
        // getFieldOfficers();

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Drawer
                title="Create New Schedule"
                placement="right"
                onClose={handleClosed}
                open={isOpen}
                zIndex={100}
                width={390}
            >
                <div>
                    <Form form={form} layout="vertical" autoComplete="off">
                        <Form.Item
                            label="Select Role Branch"
                            name="branch_id"
                            className="w-full"
                            required
                        >
                            <Select
                                options={branches}
                                onChange={(e) => {
                                    handleRequests(e?.value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Select Loading Point"
                            name="loading_point_id"
                            className="w-full"
                            required
                        >
                            <AsyncSelect
                                defaultOptions={loadingPoints}
                                loadOptions={(inputValue, callback) => {
                                    loadLoadingPointOptions(
                                        inputValue,
                                        callback
                                    );
                                }}
                                isDisabled={loading || fetchingLoadingPoints}
                                isLoading={fetchingLoadingPoints}
                            />
                        </Form.Item>

                        <div className="flex items-center">
                            <Form.Item
                                label="Select Bus"
                                name="bus_id"
                                className="w-1/2 mb-2 pr-3"
                                required
                            >
                                <AsyncSelect
                                    defaultOptions={buses}
                                    isDisabled={loading}
                                    loadOptions={(inputValue, callback) => {
                                        loadBusOptions(inputValue, callback);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Select Driver"
                                name="driver_id"
                                className="w-1/2 mb-2 pr-3"
                                required
                            >
                                <Select
                                    options={drivers}
                                    isDisabled={loading}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item
                            label="Select Conductor"
                            name="conductor_id"
                            className="w-full"
                            required
                        >
                            <Select options={conductors} isDisabled={loading} />
                        </Form.Item>
                        <div className="flex items-center">
                            <Form.Item
                                label="Select Porter"
                                name="porter_id"
                                className="w-1/2 mb-2 pr-3"
                                required
                            >
                                <Select
                                    options={porters}
                                    isDisabled={loading}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Field Officer"
                                name="field_officer_id"
                                className="w-1/2 mb-2 pr-3"
                                required
                            >
                                <Select
                                    options={fieldOfficers}
                                    isDisabled={loading}
                                />
                            </Form.Item>

                            {/* <Form.Item
                label="Field Branch Supervisor"
                name="branch_supervisor_id"
                className="w-1/2 mb-2 pr-3"
                required
              >
                <Select options={branchSupervisors} isDisabled={loading} />
              </Form.Item> */}
                        </div>
                        <Form.Item
                            label="Select Branch Supervisor"
                            name="branch_supervisor_id"
                            className="w-full"
                            required
                        >
                            <Select
                                options={branchSupervisors}
                                isDisabled={loading}
                            />
                        </Form.Item>
                        <div className="flex items-center">
                            <Form.Item
                                name="departure_date"
                                label="Departure Date"
                                colon={false}
                                className="w-1/2 mb-2 pr-3"
                                required
                            >
                                <DatePicker
                                    allowClear
                                    disabledDate={disabledDate}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item
                                name="departure_time"
                                label="Departure Time"
                                colon={false}
                                className="createSchedule w-1/2 mb-2 pr-3"
                                required
                            >
                                <TimePicker size="large" />
                            </Form.Item>
                        </div>

                        <Form.Item
                            required
                            name="price"
                            label="Price"
                            colon={false}
                        >
                            <Input type="number" />
                        </Form.Item>

                        <div className="flex items-center flex-wrap gap-2">
                            <Form.Item name="allow_midroute">
                                <Checkbox
                                    checked={allowMidRoute}
                                    onChange={() =>
                                        setAllowMidRoute(!allowMidRoute)
                                    }
                                >
                                    Allow Mid-Route
                                </Checkbox>
                            </Form.Item>
                            <Form.Item name="priority">
                                <Checkbox
                                    checked={isHighPriority}
                                    onChange={() =>
                                        setIsHighPriority(!isHighPriority)
                                    }
                                >
                                    High Priority
                                </Checkbox>
                            </Form.Item>
                            <Form.Item name="is_ticketing">
                                <Checkbox
                                    checked={isTicketing}
                                    onChange={() =>
                                        setIsTicketing(!isTicketing)
                                    }
                                >
                                    Ticketing
                                </Checkbox>
                            </Form.Item>
                            <Form.Item name="is_ticketing">
                                <Checkbox
                                    checked={prepaid}
                                    onChange={(e) =>
                                        setPrepaid(e.target.checked)
                                    }
                                >
                                    Prepaid
                                </Checkbox>
                            </Form.Item>
                            <Form.Item name="is_ticketing">
                                <Checkbox
                                    checked={isPremium}
                                    onChange={(e) =>
                                        setIsPremium(e.target.checked)
                                    }
                                >
                                    Premium
                                </Checkbox>
                            </Form.Item>
                        </div>
                        {submitting ? (
                            <div className="mt-10">
                                <Loader ready={submitting} bgColor="bg-white" />
                            </div>
                        ) : (
                            <div className="flex gap-2 justify-end items-end w-full">
                                <CustomButton
                                    data-testid="createSchedule"
                                    onClick={submit}
                                >
                                    <div>Create</div>
                                </CustomButton>
                                <CustomButton
                                    data-testid="cancelCreateSchedule"
                                    onClick={handleClosed}
                                    variant="secondary"
                                >
                                    <div>Cancel</div>
                                </CustomButton>
                            </div>
                        )}
                    </Form>
                </div>
            </Drawer>
        </>
    );
};

export default CreateSchedule;
