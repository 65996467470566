import { COOKIES_DOMAIN, DB_PREF, ACCOUNTS_URL } from './constants';
import type { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd';
import moment from 'moment';

interface DisabledTimeConfig {
  disabledHours?: () => number[];
  disabledMinutes?: (selectedHour: number) => number[];
  disabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
}

export const redirect = (to: any) => {
  window.location = to;
};

export const setCookie = (cname: string, cvalue: any, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = `${
    DB_PREF + cname
  }=${cvalue};${expires};path=/;domain=${COOKIES_DOMAIN};SameSite=Strict`;
};

export const getCookie = (cookieName: any) => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(';');
  for (let index = 0; index < cookiesArray.length; index++) {
    let cookie = cookiesArray[index];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

export const disabledDate = (current: any) => {
  // Can not select days before today and today
  return current && current < moment().startOf('day');
};

export const disabledTime = (now: any): DisabledTimeConfig => {
  const currentHour: number = now.hour();
  const currentMinute: number = now.minute();

  // Calculate the next time slot (20 minutes from now)
  // const nextTimeSlot = now.clone().add(20, 'minutes');

  // If the current time is before 8:20 am, disable the times before 8:20 am
  if (currentHour < 8 || (currentHour === 8 && currentMinute < 20)) {
    return {
      disabledHours: () => [...Array(8).keys()], // Disable hours before 8 am
      disabledMinutes: () => [...Array(60).keys()], // Disable all minutes
    };
  }

  // Otherwise, disable the times before the current time
  return {
    disabledHours: () => [...Array(currentHour).keys()],
    disabledMinutes: (selectedHour: number) => {
      if (selectedHour === currentHour) {
        return [...Array(currentMinute).keys()];
      }
      return [];
    },
    disabledSeconds: (selectedHour: number, selectedMinute: number) => {
      if (selectedHour === currentHour && selectedMinute === currentMinute) {
        // Disable seconds for the current hour and minute
        return [...Array(now.second()).keys()];
      }
      return [];
    },
  };
};

export const delCookie = (cname: string) => {
  //console.log(cname);

  window.document.cookie = `${cname}=;domain=${COOKIES_DOMAIN};path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const logout = () => {
  // delCookie('oya_user');
  // delCookie('oya_token');
  // delCookie('oya_usr');

  // window.localStorage.clear();
  // redirect(`${ACCOUNTS_URL}/login?appref=${window.location.origin}/`);
  window.localStorage.clear();
  redirect(`${ACCOUNTS_URL}/logout?appref=${window.location.origin}/`);
};

export const stringToBase64 = (str: string) => {
  const base64String = btoa(str);
  return base64String;
};

export const formatDateRange = (startDate?: Date, endDate?: Date): string => {
  if (!startDate && !endDate) {
    return 'All Time';
  }

  const getFormattedDate = (date: Date): string => {
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const meridiem = hours >= '12' ? 'pm' : 'am';
    const formattedHours = parseInt(hours) % 12 || 12;

    return `${day}${
      day === 1 || day === 21 || day === 31
        ? 'st'
        : day === 2 || day === 22
        ? 'nd'
        : day === 3 || day === 23
        ? 'rd'
        : 'th'
    } ${month}, ${year} ${formattedHours}:${minutes}${meridiem}`;
  };

  if (startDate && endDate) {
    const formattedStartDate = getFormattedDate(startDate);
    const formattedEndDate = getFormattedDate(endDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
  } else if (startDate) {
    return `From Beginning - ${getFormattedDate(startDate)}`;
  } else if (endDate) {
    return `Till ${getFormattedDate(endDate)}`;
  }

  return 'Invalid date range';
};

export const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const getBase64 = (img: any, callback: (params: any) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
