import { useEffect, useState, ReactNode } from "react";
import { Typography } from "@material-tailwind/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { Option } from "../../types";
import { Branch } from "../../pages/branches/branch";
import { getAllUsers } from "../../utils/helpers/commonApiCalls";
import { User } from "../../types";
import { auth_api_call } from "../../utils/api";
import { debounce } from "lodash";
import { DateRangePicker } from "rsuite";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subYears,
} from "date-fns";
import { LoadingPointBookingStatuses } from "../../utils/constants";

interface filterProps {
  onHide: () => void;
  handleBranchChange: (value: any) => void;
  onCreatorChange: (value: any) => void;
  defaultDates: [Date, Date];
  handleDateChange: (value: [Date, Date]) => void;
  handleStatusChange: (value: string | undefined) => void;
  listType: number | undefined;
}

interface Range {
  label: ReactNode;
  value: any;
  closeOverlay?: boolean;
  placement?: "bottom" | "left";
}

const LoadingPointBookingFilter = ({
  onHide,
  handleBranchChange,
  onCreatorChange,
  defaultDates,
  handleDateChange,
  handleStatusChange,
  listType,
}: filterProps) => {
  const [branches, setBranches] = useState<Option[]>([]);
  const [users, setUsers] = useState<Option[]>([]);

  const getBranches = async () => {
    try {
      const res = await base_api_request.get(RouteConstants.ALL_BRANCHES);
      setBranches(
        res.data?.payload?.items?.map((item: any) => ({
          label: `${item?.name}`,
          value: item?.id,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  const customRanges: Range[] = [
    {
      label: "Today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Yesterday",
      value: [
        startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
        endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
      ],
    },
    {
      label: "This Week",
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Last Week",
      value: [
        startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
        endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
      ],
    },
    {
      label: "This Month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: "Last Month",
      value: [
        startOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
        endOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
      ],
    },
    {
      label: "This Year",
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
    {
      label: "Last Year",
      value: [
        startOfYear(subYears(new Date(), 1)),
        endOfYear(subYears(new Date(), 1)),
      ],
    },
  ];

  const loadBranchOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ALL_BRANCHES}?filters=${JSON.stringify(filterData)}`
        );

        const searchOptions = res.data?.payload?.items?.map((item: Branch) => ({
          label: item?.name,
          value: item?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  const loadUserOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "first_name",
          o: "contains",
          p: [`${inputValue}`],
          c: "OR",
        },
        {
          f: "last_name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await auth_api_call.get(
          `${RouteConstants.ACCOUNTS}?filters=${JSON.stringify(filterData)}`
        );
        setUsers(
          res.data?.payload?.items?.map((item: User) => ({
            label: item?.name,
            value: item?.id,
          }))
        );

        callback(
          res.data?.payload?.items?.map((item: User) => ({
            label: item?.name,
            value: item?.id,
          }))
        );

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  useEffect(() => {
    getBranches();

    getAllUsers()
      .then((res) => {
        setUsers(
          res.payload?.items?.map((item: User) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="px-3 py-[20px] bg-white border-l-[1px] border-gray-500 h-full overflow-y-auto">
      {/* header */}
      <div className="mb-2 flex justify-between items-center mt-8">
        <Typography variant="h5" color="blue-gray">
          Filters
        </Typography>
        <ChevronDoubleRightIcon
          className="w-5 h-5 cursor-pointer"
          onClick={() => onHide()}
        />
      </div>
      <div className="w-full">
        <p>Travel Date</p>
        <DateRangePicker
          className="w-full"
          placement="leftStart"
          value={defaultDates}
          onChange={(e: any) => handleDateChange(e)}
          ranges={customRanges}
        />
      </div>

      <div>
        {listType === 2 && (
          <div>
            <div className="mt-5 w-full">
              <p>Branch</p>
              <AsyncSelect
                defaultOptions={branches}
                placeholder="Select branch"
                loadOptions={(inputValue, callback) => {
                  loadBranchOptions(inputValue, callback);
                }}
                onChange={(val) => {
                  handleBranchChange(val?.value);
                }}
                isClearable
              />
            </div>

            <div className="my-4">
              <div className="py-2">Booked by</div>
              <AsyncSelect
                defaultOptions={users}
                onChange={(e) => {
                  onCreatorChange(e?.value);
                }}
                loadOptions={(inputValue, callback) => {
                  loadUserOptions(inputValue, callback);
                }}
                placeholder="Owner"
                isSearchable
                isClearable
              />
            </div>

            <div className="my-4">
              <div className="py-2">Status</div>
              <Select
                options={LoadingPointBookingStatuses}
                onChange={(e) => {
                  handleStatusChange(e?.value);
                }}
                placeholder="Status"
                isSearchable
                isClearable
              />
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-end mt-5 lg:hidden">
        <button
          className="border-[1px] border-oya-ghana-red text-oya-ghana-red px-4 py-1 rounded-[5px] hover:bg-oya-ghana-red hover:text-white"
          onClick={() => onHide()}
        >
          Apply filter
        </button>
      </div>
    </div>
  );
};

export default LoadingPointBookingFilter;
