import { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import CustomDatePicker from "../../pages/bookings/customDatePicker";
import AsyncSelect from "react-select/async";
import { Input } from "antd";
import { base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { Branch } from "../../pages/branches/branch";
import { Bus, Option } from "../../types";
import { getBranches, getBuses } from "../../utils/helpers/commonApiCalls";
import PorterSelector from "./porterSelector";
import { debounce } from "lodash";

interface filterProps {
  onHide: () => void;
  defaultDates: any;
  handleCalendarChange: (date: any) => void;
  onBranchChange: (date: any) => void;
  onBusChange: (date: any) => void;
  setSelectedPorter: (value: any) => void;
  handleTripCodeChange: (value: string) => void;
}

const SchedulesFilter = ({
  onHide,
  defaultDates,
  handleCalendarChange,
  onBranchChange,
  onBusChange,
  setSelectedPorter,
  handleTripCodeChange,
}: filterProps) => {
  const [branchOptions, setBranchOptions] = useState<Option[]>([]);
  const [busOptions, setBusOptions] = useState<Option[]>([]);

  const handleChange = debounce((value) => {
    handleTripCodeChange(value);
  }, 1500);

  //onload
  useEffect(() => {
    getBuses()
      .then((res) => {
        setBusOptions(
          res.payload?.items?.map((item: Bus) => ({
            label: item?.reg_number,
            value: item?.id,
          }))
        );
      })
      .catch((e) => console.log(e));

    getBranches()
      .then((res) => {
        setBranchOptions(
          res.payload?.items?.map((item: Branch) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      })
      .catch((e) => console.log(e));
  }, []);

  //fetch branches
  const loadBranchOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ALL_BRANCHES}?filters=${JSON.stringify(filterData)}`
        );

        const searchOptions = res.data?.payload?.items?.map((item: Branch) => ({
          label: item?.name,
          value: item?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  //fetch buses
  const loadBusOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "reg_number",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.BUSES}?filters=${JSON.stringify(filterData)}`
        );

        const searchOptions = res.data?.payload?.items?.map((item: Bus) => ({
          label: item?.reg_number,
          value: item?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  return (
    <div className="px-3 py-[20px] bg-white border-l-[1px] border-gray-500 h-full overflow-y-auto">
      <div className="mb-2 flex justify-between items-center mt-8">
        <Typography variant="h5" color="blue-gray">
          Filters
        </Typography>
        <ChevronDoubleRightIcon
          className="w-5 h-5 cursor-pointer"
          onClick={() => onHide()}
        />
      </div>

      <div className="my-4 ">
        <div className="py-2">Date Created</div>
        <CustomDatePicker
          defaultDates={defaultDates}
          handleDateChange={(data) => handleCalendarChange(data)}
        />
      </div>

      <div className="mb-4">
        <div className="py-2 text-gray-500">Filter By Branches</div>
        <AsyncSelect
          loadOptions={(inputValue, callback) => {
            loadBranchOptions(inputValue, callback);
          }}
          onChange={(e) => {
            onBranchChange(e);
          }}
          isSearchable
          isClearable
          defaultOptions={branchOptions}
        />
      </div>
      <div className="mb-4">
        <PorterSelector
          onChange={(selected: any) => setSelectedPorter(selected?.value)}
        />
      </div>
      <div className="mb-4">
        <div className="py-2 text-gray-500">Filter By Buses</div>
        <AsyncSelect
          loadOptions={(inputValue, callback) => {
            loadBusOptions(inputValue, callback);
          }}
          onChange={(e: any) => {
            onBusChange(e);
          }}
          isSearchable
          isClearable
          defaultOptions={busOptions}
        />
      </div>

      <div>
        <div className="py-2 text-gray-500">Filter By Trip code</div>
        <Input
          onChange={(e) => handleChange(e.target.value)}
          size="large"
          prefix={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M14.375 27.1875C7.3125 27.1875 1.5625 21.4375 1.5625 14.375C1.5625 7.3125 7.3125 1.5625 14.375 1.5625C21.4375 1.5625 27.1875 7.3125 27.1875 14.375C27.1875 21.4375 21.4375 27.1875 14.375 27.1875ZM14.375 3.4375C8.3375 3.4375 3.4375 8.35 3.4375 14.375C3.4375 20.4 8.3375 25.3125 14.375 25.3125C20.4125 25.3125 25.3125 20.4 25.3125 14.375C25.3125 8.35 20.4125 3.4375 14.375 3.4375Z"
                fill="#FF5A55"
              />
              <path
                d="M27.5 28.4375C27.2625 28.4375 27.025 28.35 26.8375 28.1625L24.3375 25.6625C23.975 25.3 23.975 24.7 24.3375 24.3375C24.7 23.975 25.3001 23.975 25.6626 24.3375L28.1626 26.8375C28.5251 27.2 28.5251 27.8 28.1626 28.1625C27.9751 28.35 27.7375 28.4375 27.5 28.4375Z"
                fill="#FF5A55"
              />
            </svg>
          }
          placeholder="Enter trip code"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default SchedulesFilter;
