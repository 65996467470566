import { useState, useEffect, useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import { Dropdown, Space, Input } from "antd";
import { base_api_request } from "../utils/api";
import { getCompanies } from "../utils/helpers/commonApiCalls";
import AsyncSelect from "react-select/async";
import {
  AddOutlined,
  ArrowDropDownOutlined,
  EditOutlined,
} from "@mui/icons-material";
import CreateBus from "../components/Buses/CreateBus";
import EditBus from "../components/Buses/EditBus";
import { Refresh } from "@mui/icons-material";
import PageLayout from "../components/layouts/PageLayout";
import type { MenuProps } from "antd";
import CustomButton from "../components/CustomButton";
import { Bus, Company, Option } from "../types";
import moment from "moment";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import { debounce } from "lodash";
import { isDateInPast } from "../functions/isDateInPast";
import { isDateInCurrentMonth } from "../functions/isDateInCurrentMonth";

type Props = {};

const Buses = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [bus_reg, setBusReg] = useState<string | any>("");
  const [company, setCompany] = useState<Option[]>([]);
  const [selectedBus, setSelectedBus] = useState<Bus | any>();
  //const [submitting, setSubmitting] = useState<boolean>(false);
  const [editBusModal, showEditBusModal] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<number | undefined>();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [buses, setBuses] = useState<Bus[]>([]);
  const [loading, setLoading] = useState(false);

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const items: MenuProps["items"] = [
    {
      key: "1",

      label: (
        <button
          data-testid="editBus"
          onClick={() => {
            showEditBusModal(true);
          }}
        >
          Edit Bus
        </button>
      ),
      icon: (
        <EditOutlined
          sx={{
            color: "#7c0000",
          }}
        />
      ),
    },
  ];

  const columns = useMemo<MRT_ColumnDef<Bus>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.reg_number}`,

        header: "Registration",
      },
      {
        accessorFn: (row) => `${row?.model}`,
        size: 100,
        header: "Model",
      },
      {
        accessorFn: (row) => `${row?.passenger_capacity}`,
        size: 100,
        header: "Passenger Capacity",
      },
      {
        accessorFn: (row) => `${row?.capacity}`,
        size: 80,
        header: "Total Capacity",
      },
      {
        accessorFn: (row) => `${row?.insurance_exp_date}`,
        header: "Insurance Expiry Date",
        Cell: ({ cell }: any) => (
          <Box>
            {cell.getValue() === "null" ? (
              "N/A"
            ) : (
              <div
                className={
                  isDateInPast(moment(cell.getValue()).format("YYYY-MM-DD"))
                    ? "text-red-600"
                    : isDateInCurrentMonth(
                        moment(cell.getValue()).format("YYYY-MM-DD")
                      )
                    ? "text-yellow-600"
                    : "text-black"
                }
              >
                {moment(cell.getValue()).format("LL")}
              </div>
            )}
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.rw_exp_date}`,
        header: "Roadworthy Expiry Date",
        Cell: ({ cell }: any) => (
          <Box>
            {cell.getValue() === "null" ? (
              "N/A"
            ) : (
              <div
                className={
                  isDateInPast(moment(cell.getValue()).format("YYYY-MM-DD"))
                    ? "text-red-600"
                    : isDateInCurrentMonth(
                        moment(cell.getValue()).format("YYYY-MM-DD")
                      )
                    ? "text-yellow-600"
                    : "text-black"
                }
              >
                {moment(cell.getValue()).format("LL")}
              </div>
            )}
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.bus_company?.name}`,
        header: "Operated by",
      },

      {
        id: "more",
        header: "",
        accessorFn: (row) => `${row?.id}`,
        Cell: ({ cell, row }) => (
          <Box>
            <Dropdown
              trigger={["click"]}
              menu={{
                items,
                onChange: (e) => {
                  //console.log('value being set', e);
                  setSelectedBus(e);
                },
              }}
              placement="bottomRight"
            >
              <button
                data-testid="moreBuses"
                onClick={(e) => {
                  //console.log('value in more', e);
                  e.preventDefault();
                }}
              >
                <Space>
                  More
                  <ArrowDropDownOutlined />
                </Space>
              </button>
            </Dropdown>
          </Box>
        ),
        size: 80,
      },
    ],

    // eslint-disable-next-line
    []
  );

  const getBuses = async () => {
    setLoading(true);

    let filters: any = [];

    if (selectedCompany) {
      filters.push({
        f: "company.id",
        o: "=",
        p: [Number(selectedCompany)],
      });
    }

    if (bus_reg) {
      filters.push({
        f: "reg_number",
        o: "contains",
        p: [`${bus_reg}`],
      });
    }

    try {
      const res = await base_api_request.get(
        `${RouteConstants.BUSES}?limit=${pagination.pageSize}&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc&filters=${JSON.stringify(filters)}`
      );
      setBuses(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  const loadCompanyOptions = debounce(
    async (inputValue: string, callback: (options: Option[]) => void) => {
      let filterData = [
        {
          f: "name",
          o: "contains",
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ALL_COMPANIES}?filters=${JSON.stringify(
            filterData
          )}`
        );

        const searchOptions = res.data?.payload?.items?.map(
          (item: Company) => ({
            label: item?.name,
            value: item?.id,
          })
        );

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    },
    2000
  );

  const resetFilters = async () => {
    setSelectedCompany(undefined);
    setBusReg("");
    setPagination((prev) => ({ pageIndex: 0, pageSize: 12 }));
    getBuses();
  };

  useEffect(() => {
    getBuses();
    getCompanies()
      .then((res) => {
        setCompany(
          res.payload?.items?.map((item: Company) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      })
      .catch((e) => console.log(e));

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, selectedCompany, bus_reg]);

  return (
    <PageLayout>
      <CreateBus
        refresh={() => {
          getBuses();
        }}
        isOpen={open}
        handleClosed={() => setOpen(false)}
      />
      <EditBus
        isOpen={editBusModal}
        handleClosed={() => {
          showEditBusModal(false);
        }}
        bus={selectedBus}
        refetch={() => getBuses()}
      />
      <div className="w-full overflow-auto mb-8 relative">
        <div className="col-span-3 mx-4 my-6 h-full">
          <div className="flex justify-between items-center mb-5">
            <h3 className="font-bold py-2 text-2xl">Buses</h3>
            <CustomButton data-testid="createBus" onClick={() => setOpen(true)}>
              <div className="flex gap-1 text-white items-center">
                <AddOutlined
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                <div>Create a Bus</div>
              </div>
            </CustomButton>
          </div>
          <div
            className="shadow border bg-white p-3 rounded-md w-full my-4 flex items-center justify-between
          "
          >
            <div className="flex gap-4 items-center">
              <div>
                <div className="py-2 text-gray-500">Search By Company Name</div>
                <AsyncSelect
                  loadOptions={(inputValue, callback) => {
                    loadCompanyOptions(inputValue, callback);
                  }}
                  onChange={(e: any) => {
                    setSelectedCompany(e?.value);
                  }}
                  isSearchable
                  isClearable
                  defaultOptions={company}
                />
              </div>
              <div>
                <div className="py-2 text-gray-500">
                  Search By Bus Registration
                </div>

                <Input
                  placeholder="GX 9674-20"
                  onChange={(e) => {
                    setBusReg(e.target.value);
                  }}
                  value={bus_reg}
                  size="large"
                />
              </div>
            </div>
            <button
              data-testid="resetBusesFilters"
              className="p-2 flex items-center gap-3"
              onClick={() => resetFilters()}
            >
              <Refresh
                sx={{
                  color: "#7C0000",
                  width: "25px",
                  height: "25px",
                }}
              />
              <div className="text-oya-ghana-red">Reset filters</div>
            </button>
          </div>
          <div className="grid grid-cols-1">
            <div className="w-full h-full mb-8">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "0",
                  },
                }}
                data={buses}
                enableTopToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                manualPagination
                enableSorting={false}
                columns={columns}
                rowCount={meta?.total}
                state={{ pagination, isLoading: loading }}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                }}
                enableColumnResizing
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    setSelectedBus(row?.original);
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                onPaginationChange={setPagination}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Buses;
