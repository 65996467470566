import { useState, useEffect, useMemo } from "react";
import PageLayout from "../components/layouts/PageLayout";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import { base_api_request } from "../utils/api";
import { Incident } from "./incidents/incident";
import moment from "moment";
import CustomDatePicker from "./bookings/customDatePicker";
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from "date-fns";
import { RouteConstants } from "../utils/helpers/RouteConstants";

const Incidents = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfWeek(new Date()),
    endOfWeek(new Date()),
  ]);

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates(null);
    } else if (selectedDate instanceof Array && selectedDate?.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  const columns = useMemo<MRT_ColumnDef<Incident>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.deaths}`,
        size: 50,
        header: "Deaths",
      },
      {
        accessorFn: (row) => `${row?.injured}`,
        size: 50,
        header: "Injured",
      },
      {
        accessorFn: (row) => `${row?.location}`,
        header: "Location of incident",
      },
      {
        accessorFn: (row) => `${row?.reporter?.name}`,

        header: "Reported by",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{cell?.getValue() === "undefined" ? "" : cell?.getValue()}</Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.reporter?.phone}`,

        header: "Reporter mobile number",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{cell?.getValue() === "undefined" ? "" : cell?.getValue()}</Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Date reported",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell?.getValue()).format("LLL")}</Box>
        ),
      },
    ],
    []
  );
  const getIncidents = async () => {
    setLoading(true);

    try {
      const filter = [
        {
          f: "created_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        },
      ];

      const res = await base_api_request.get(
        `${RouteConstants.ALL_INCIDENTS}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&filters=${JSON.stringify(filter)}&sorting=created_at:desc`
      );
      setBranches(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getIncidents();
    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, defaultDates]);
  return (
    <PageLayout>
      <div className="w-full overflow-auto mb-8 relative">
        <div className="col-span-3 mx-4 my-6 h-full">
          <div className="flex justify-between items-center my-4">
            <h3 className="font-bold py-2 text-2xl">Incidents</h3>
            <div>
              <div className="py-2">Filter Date Reported</div>
              <CustomDatePicker
                defaultDates={defaultDates}
                handleDateChange={handleCalendarChange}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 min-h-full">
            <div className="w-full pb-8">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "0",
                  },
                }}
                data={branches}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                manualPagination
                enableSorting={false}
                columns={columns}
                rowCount={meta?.total}
                state={{ pagination, isLoading: loading }}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                }}
                enableColumnResizing
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    console.log(row.original);
                    // func.redirect(`/hsh/${row.original?.id}`);
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                onPaginationChange={setPagination}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Incidents;
