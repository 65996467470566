import { useState, useEffect } from "react";
import { Input, Form, notification, Spin, Modal } from "antd";
import Select from "react-select";
import { Button } from "@material-tailwind/react";
import CustomButton from "../CustomButton";
import { base_api_request } from "../../utils/api";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { Region, Town } from "../../types";
import { GOOGLE_KEY } from "../../utils/constants";
import { getRegions } from "../../utils/helpers/commonApiCalls";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

interface Location {
  lat: number | any;
  lng: number | any;
}

type Option = {
  label: string;
  value: any;
};

const containerStyle = {
  width: "100%",
  height: "400px",
};

interface modalProps {
  isEditOpened: boolean;
  handleClosed: () => void;
  dataToEdit: Town;
  handleReFetch: () => void;
}

const EditTown = ({
  handleClosed,
  isEditOpened,
  handleReFetch,
  dataToEdit,
}: modalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState<Region[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >();
  const [lat, setLatitude] = useState<any>(dataToEdit?.latitude);
  const [lng, setLongitude] = useState<any>(dataToEdit?.longitude);

  const [selectValue, setSelectValue] = useState<Option | undefined>();

  const [locationAddress, setLocationAddress] = useState<string | undefined>();

  const handlePlaceSelect = (place: any) => {
    setSelectValue(place);
    // console.log('Selected place:', place);
    // Extract the lat and lng from the selected place
    if (place) {
      geocodeByAddress(place?.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          // console.log("Successfully got latitude and longitude", {
          //     lat,
          //     lng,
          // });
          setLatitude(lat);
          setLongitude(lng);
        });
    }
  };

  const handleSelectedLocation = (e: google.maps.MapMouseEvent) => {
    setSelectedLocation({
      lat: e?.latLng?.lat(),
      lng: e?.latLng?.lng(),
    });
    setLatitude(e?.latLng?.lat());
    setLongitude(e?.latLng?.lng());
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${GOOGLE_KEY}`,
  });

  const submit = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      await base_api_request.put(`${RouteConstants.TOWNS}/${dataToEdit?.id}`, {
        ...values,
        region_id: values?.region_id?.value,
        latitude: lat,
        longitude: lng,
      });
      notification.success({
        message: "Town has been edited",
      });
      setSelectValue(undefined);
      setLoading(false);
      handleReFetch();
      handleClosed();
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message,
      });
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getRegions()
      .then((res) => {
        const mapData = res.payload?.items?.map((item: Region) => ({
          label: item?.name,
          value: item?.id,
        }));
        setRegions(mapData);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    form.setFieldValue("name", dataToEdit?.name);
    form.setFieldValue("alias", dataToEdit?.alias);
    form.setFieldValue("region_id", {
      value: dataToEdit?.region?.code,
      label: dataToEdit?.region?.name,
    });

    setLatitude(dataToEdit?.latitude);
    setLongitude(dataToEdit?.longitude);

    // eslint-disable-next-line
  }, [dataToEdit]);

  useEffect(() => {
    if (selectedLocation) {
      // Perform reverse geocoding to get the address from the coordinates
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(
        selectedLocation.lat,
        selectedLocation.lng
      );

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results) {
            //console.log(results[0]);
            setLocationAddress(results[0]?.formatted_address);
          } else {
            setLocationAddress("Address not found");
          }
        } else {
          setLocationAddress("Address not found");
        }
      });
    }
  }, [selectedLocation]);

  return (
    <Modal
      onCancel={handleClosed}
      open={isEditOpened}
      className="sm:min-w-[989px]"
      footer={null}
    >
      <Spin spinning={loading}>
        <div>
          <div className="my-5 font-bold text-2xl">Edit Town</div>
          <Form
            form={form}
            className="mt-5"
            layout="vertical"
            autoComplete="none"
          >
            <Form.Item
              className="w-full"
              label="Select Region"
              name="region_id"
              required
            >
              <Select
                placeholder="Regions"
                options={regions}
                isClearable
                isSearchable
              />
            </Form.Item>
            <div className="flex gap-3">
              <Form.Item className="w-1/2" label="Town Name" name="name">
                <Input placeholder="name" size="large" />
              </Form.Item>
              <Form.Item className="w-1/2" label="Town Alias" name="alias">
                <Input placeholder="alias" size="large" />
              </Form.Item>
            </div>
            <div className="w-full mt-2">
              <div className="py-2">Search and Select Google Location</div>
              <GooglePlacesAutocomplete
                apiKey={GOOGLE_KEY}
                selectProps={{
                  value: selectValue,
                  onChange: handlePlaceSelect,
                  isClearable: true,
                  cacheOptions: false,
                  placeholder: "Search town",
                }}
              />
            </div>
            <div className="py-3">
              {locationAddress && (
                <div>
                  <div className="py-1">Selected Location</div>
                  <div className="text-lg font-semibold text-gray-700">
                    {locationAddress}
                  </div>
                </div>
              )}
            </div>
            <div>
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{
                    lat,
                    lng,
                  }}
                  zoom={13}
                >
                  <MarkerF
                    position={{
                      lat,
                      lng,
                    }}
                    draggable={true}
                    onDragEnd={handleSelectedLocation}
                  />
                </GoogleMap>
              )}
            </div>
          </Form>
          <div className="flex w-full justify-end items-end mt-10">
            <div className="flex items-center gap-3">
              <CustomButton data-testid="submitNewTown" onClick={submit}>
                <div>Submit</div>
              </CustomButton>
              <Button
                data-testid="closeNewTown"
                disabled={loading}
                variant="outlined"
                size="sm"
                onClick={handleClosed}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default EditTown;
