import { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import logoImg from "../../images/logo.png";
import { Tooltip, Typography } from "@material-tailwind/react";
import { mainRouteList } from "../../routes/mainRouteList";
import { Link, NavLink } from "react-router-dom";

const CustomSidebar = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    //const [scrollPosition, setScrollPosition] = useState(0);

    //   const handleScroll = () => {
    //     setScrollPosition(window.scrollY);
    //   };

    //   useEffect(() => {
    //     window.addEventListener("scroll", handleScroll);
    //   }, []);

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Sidebar collapsed={isCollapsed}>
            <Menu
                menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                        // only apply styles on first level elements of the tree
                        if (level === 0)
                            return {
                                color: disabled ? "#eee" : "#000",
                            };
                    },
                }}
                className="bg-white h-full"
            >
                {/* logo */}
                <div className="p-3 flex items-center gap-3 w-full">
                    <Link className="w-full" to="#">
                        <img src={logoImg} alt="logo" />
                    </Link>
                    <Typography
                        className={`${isCollapsed ? "hidden" : ""}`}
                        variant="h5"
                    >
                        Bus Ride Manager
                    </Typography>
                    <div
                        className={`${isCollapsed
                                ? "hidden"
                                : "flex justify-end items-center w-full"
                            }`}
                    >
                        <ChevronDoubleLeftIcon
                            className="w-5 h-5 cursor-pointer"
                            onClick={() => handleCollapse()}
                        />
                    </div>
                </div>
                <ChevronDoubleRightIcon
                    className={`${isCollapsed
                            ? "w-5 h-5 cursor-pointer my-3 flex mr-auto ml-auto"
                            : "hidden"
                        }`}
                    onClick={() => handleCollapse()}
                />
                {mainRouteList.map((menu: any, i: number) => (
                    <>
                        {isCollapsed ? (
                            <>
                                {menu?.subItems ? (
                                    <Tooltip
                                        key={i}
                                        content={menu.title}
                                        placement="right-end"
                                    >
                                        <SubMenu
                                            icon={menu?.icon}
                                            label={menu?.title}
                                            defaultOpen={menu?.open || true}
                                        >
                                            {menu?.subItems?.map(
                                                (item: any, index: number) => (
                                                    <MenuItem
                                                        key={index}
                                                        icon={item.icon}
                                                        disabled={
                                                            menu?.disabled
                                                        }
                                                        component={
                                                            <NavLink
                                                                to={`${item?.path}`}
                                                                className="text-black"
                                                                style={({
                                                                    isActive,
                                                                }) =>
                                                                    isActive
                                                                        ? {
                                                                            background:
                                                                                "rgb(124, 0, 0,0.05)",
                                                                            borderLeftWidth:
                                                                                "5px",
                                                                            borderLeftColor:
                                                                                "#7C0000",
                                                                        }
                                                                        : undefined
                                                                }
                                                            />
                                                        }
                                                    >
                                                        {item?.title}
                                                    </MenuItem>
                                                )
                                            )}
                                        </SubMenu>
                                    </Tooltip>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <>
                                {menu?.subItems ? (
                                    <SubMenu
                                        label={menu?.title}
                                        defaultOpen={menu?.open || true}
                                    >
                                        {menu?.subItems?.map(
                                            (item: any, index: number) => (
                                                <MenuItem
                                                    key={index}
                                                    icon={item.icon}
                                                    disabled={menu?.disabled}
                                                    component={
                                                        <NavLink
                                                            to={`${item?.path}`}
                                                            className="text-black"
                                                            style={({
                                                                isActive,
                                                            }) =>
                                                                isActive
                                                                    ? {
                                                                        background:
                                                                            "rgb(124, 0, 0,0.05)",
                                                                        borderLeftWidth:
                                                                            "5px",
                                                                        borderLeftColor:
                                                                            "#7C0000",
                                                                    }
                                                                    : undefined
                                                            }
                                                        />
                                                    }
                                                >
                                                    {item?.title}
                                                </MenuItem>
                                            )
                                        )}
                                    </SubMenu>
                                ) : (
                                    <MenuItem
                                        icon={menu?.icon}
                                        disabled={menu?.disabled}
                                        component={
                                            <NavLink
                                                to={`${menu?.path}`}
                                                className="text-black"
                                                style={({ isActive }) =>
                                                    isActive
                                                        ? {
                                                            background:
                                                                "rgb(124, 0, 0,0.05)",
                                                            borderLeftWidth:
                                                                "5px",
                                                            borderLeftColor:
                                                                "#7C0000",
                                                        }
                                                        : undefined
                                                }
                                            />
                                        }
                                    >
                                        {menu?.title}
                                    </MenuItem>
                                )}
                            </>
                        )}
                    </>
                ))}
            </Menu>
        </Sidebar>
    );
};

export default CustomSidebar;
