import { useState, useEffect } from "react";
import { Input, Form, Modal, notification, Spin } from "antd";
import { Button, Typography } from "@material-tailwind/react";
// import { LocationOn } from '@mui/icons-material';
import { base_api_request } from "../../utils/api";
import CustomButton from "../../components/CustomButton";
import AsyncSelect from "react-select/async";
import { Region } from "../../types";
// import PickLocation from '../../components/PickLocation';
import { Option } from "../../types";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";
import { GOOGLE_KEY } from "../../utils/constants";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import { getRegions } from "../../utils/helpers/commonApiCalls";
import { debounce } from "lodash";

interface Location {
    lat: number | any;
    lng: number | any;
}

const containerStyle = {
    width: "100%",
    height: "400px",
};

const CreatePickup = ({
    handleClosed,
    isOpen,
}: {
    handleClosed: () => void;
    isOpen: boolean;
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [regionOptions, setRouteOptions] = useState<Option[]>([]);
    const [locationAddress, setLocationAddress] = useState<
        string | undefined
    >();
    const [selectedLocation, setSelectedLocation] = useState<
        Location | undefined
    >();
    const [locationURL, setLocationURL] = useState<string>("");

    const [lat, setLatitude] = useState<any>(-3.745);
    const [lng, setLongitude] = useState<any>(-38.523);

    const handlePlaceSelect = (place: any) => {
        // console.log('Selected place:', place);
        // Extract the lat and lng from the selected place
        setLocationAddress(place?.label);
        geocodeByAddress(place?.label)
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                //console.log('Successfully got latitude and longitude', { lat, lng });
                setLatitude(lat);
                setLongitude(lng);
            });
    };

    const handleSelectedLocation = (e: google.maps.MapMouseEvent) => {
        setSelectedLocation({
            lat: e?.latLng?.lat(),
            lng: e?.latLng?.lng(),
        });
        setLatitude(e?.latLng?.lat());
        setLongitude(e?.latLng?.lng());
    };

    const loadRegionOptions = debounce(
        async (inputValue: string, callback: (options: Option[]) => void) => {
            let filterData = [
                {
                    f: "name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const res = await base_api_request.get(
                    `${RouteConstants.ALL_REGIONS}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const searchOptions = res.data?.payload?.items?.map(
                    (item: Region) => ({
                        label: item?.name,
                        value: item?.id,
                    })
                );

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    const submit = async () => {
        try {
            setLoading(true);
            const data = form.getFieldsValue();
            await base_api_request.post(RouteConstants.ALL_PICKUP_POINTS, {
                ...data,
                url:
                    locationURL ||
                    `https://www.google.com/maps?q=${lat},${lng}`,
                location: locationAddress,
                latitude: selectedLocation?.lat || lat,
                longitude: selectedLocation?.lng || lng,
                region_id: data?.region_id?.value,
            });
            setLoading(false);
            notification.success({
                message: "New Pickup Point created successfully",
            });
            setLoading(false);
            handleClosed();
            window.location.reload();
        } catch (e: any) {
            notification.error({
                message: e?.response?.data?.message,
            });
            setLoading(false);
            console.log(e);
        }
    };

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: `${GOOGLE_KEY}`,
    });

    useEffect(() => {
        getRegions()
            .then((res) => {
                setRouteOptions(
                    res.payload?.items?.map((item: Region) => ({
                        label: item?.name,
                        value: item?.id,
                    }))
                );
            })
            .catch((e) => console.log(e));
        //getPickups();
    }, []);

    useEffect(() => {
        if (selectedLocation) {
            // Perform reverse geocoding to get the address from the coordinates
            const geocoder = new google.maps.Geocoder();
            const latlng = new google.maps.LatLng(
                selectedLocation.lat,
                selectedLocation.lng
            );

            geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === "OK") {
                    if (results) {
                        console.log(results[0]);
                        setLocationAddress(results[0]?.formatted_address);
                    } else {
                        setLocationAddress("Address not found");
                    }
                } else {
                    setLocationAddress("Address not found");
                }
            });

            const mapsUrl = `https://www.google.com/maps?q=${selectedLocation.lat},${selectedLocation.lng}`;
            setLocationURL(mapsUrl);
        }
    }, [selectedLocation]);

    return (
        <Modal
            open={isOpen}
            onCancel={handleClosed}
            footer={null}
            className="sm:min-w-[989px]"
        >
            <Spin spinning={loading}>
                {/* <PickLocation /> */}
                <div>
                    <Typography variant="h5">Create Pickup Point</Typography>
                    <Form
                        form={form}
                        className="mt-5"
                        layout="vertical"
                        autoComplete="none"
                    >
                        <Form.Item
                            className="w-full"
                            label="Pickup Point Name"
                            name="name"
                            required
                        >
                            <Input placeholder="eg. Kasoa TollBooth" />
                        </Form.Item>
                        <Form.Item
                            className="w-full"
                            label="Major Landmark"
                            name="landmark"
                            required
                        >
                            <Input placeholder="landmark" />
                        </Form.Item>

                        <Form.Item
                            className="w-full"
                            label="Select Region"
                            name="region_id"
                            required
                        >
                            <AsyncSelect
                                loadOptions={(inputValue, callback) => {
                                    loadRegionOptions(inputValue, callback);
                                }}
                                isSearchable
                                isClearable
                                defaultOptions={regionOptions}
                            />
                        </Form.Item>

                        <Form.Item
                            className="w-full"
                            label="Search location"
                            name="location"
                            required
                        >
                            <GooglePlacesAutocomplete
                                apiKey={GOOGLE_KEY}
                                selectProps={{
                                    onChange: handlePlaceSelect,
                                }}
                            />
                        </Form.Item>

                        <div className="py-3">
                            {locationAddress && (
                                <div>
                                    <div className="py-1">
                                        Selected Location
                                    </div>
                                    <div className="text-lg font-semibold text-gray-700">
                                        {locationAddress}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div>
                            {isLoaded && (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={{
                                        lat,
                                        lng,
                                    }}
                                    zoom={13}
                                >
                                    <MarkerF
                                        position={{
                                            lat,
                                            lng,
                                        }}
                                        draggable={true}
                                        onDragEnd={handleSelectedLocation}
                                    />
                                </GoogleMap>
                            )}
                        </div>
                    </Form>
                    <div className="flex w-full justify-end items-end mt-10">
                        <div className="flex items-center gap-3">
                            <CustomButton
                                data-testid="continueToCreatePickUp"
                                disabled={loading}
                                onClick={submit}
                            >
                                Continue
                            </CustomButton>
                            <Button
                                data-testid="closePickUp"
                                disabled={loading}
                                variant="outlined"
                                size="sm"
                                onClick={handleClosed}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default CreatePickup;
