import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import moment from "moment";
import { LPBookingLuggageType } from "../../types";
import { LoadingPointBookingType } from "../branches/branch";

interface LoadingPointModalProps {
  isOpen: boolean;
  onClose: () => void;
  rowData: LoadingPointBookingType;
  currentIndex: any;
  onPrev: () => void;
  onNext: () => void;
  disableNext: any;
  disablePrev: any;
  luggages: Array<LPBookingLuggageType>;
}

const LoadingPointModal: React.FC<LoadingPointModalProps> = ({
  isOpen,
  onClose,
  rowData,
  onPrev,
  onNext,
  disableNext,
  disablePrev,
  luggages,
}) => {
  return (
    <Modal open={isOpen}>
      <Box sx={style}>
        <div className="flex justify-between items-center ml-16 mr-3 mt-3 ">
          <div className="font-extrabold text-lg">
            <div>Booking Details</div>
          </div>
          <IconButton
            data-testid="closeLoadingPointModal"
            className=" cursor-pointer "
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </div>
        <div>
          {/* {rowData ? JSON.stringify(rowData, null, 2) : "N/A"} */}
          <div className="flex flex-row mb-5">
            <div className="mx-auto my-auto ">
              {disablePrev ? (
                <div className="w-16"></div>
              ) : (
                <div className="px-3  ">
                  <IconButton
                    data-testid="previousLoadingPoint"
                    onClick={onPrev}
                  >
                    <ChevronLeft />
                  </IconButton>
                </div>
              )}
            </div>
            <div>
              <div className="font-bold text-md my-1">Trip details</div>

              {rowData ? (
                <div className="mr-12 my-3">
                  <div className="flex flex-row justify-between gap-10">
                    <div className="">
                      <div>
                        <div className="font-medium text-sm">Route</div>
                        <div>
                          {rowData?.route?.from.name ?? "N/A"} -{" "}
                          {rowData?.route?.to.name ?? "N/A"}(
                          {rowData?.loading_point?.label ?? "N/A"})
                        </div>
                        <div>{rowData?.branch?.name ?? "N/A"}</div>
                      </div>

                      <div className="my-2">
                        <div className="font-medium text-sm ">
                          Join trip from
                        </div>
                        <div>{rowData?.pickup?.name ?? "N/A"}</div>
                      </div>
                      <div>
                        {/* <div className="my-2 text-sm font-medium">
                          Number of passengers
                        </div>
                        <div>N/A</div> */}
                      </div>
                      <div>
                        <div className="text-sm font-medium my-2">Luggages</div>
                        {luggages.length > 0 ? (
                          <div>
                            <div className="flex items-center">
                              {luggages.map((item, index) => (
                                <div className="flex items-center">
                                  <div key={item?.id}>{item?.quantity} </div>
                                  <div>{item?.luggage_size?.name}</div>
                                  <div>
                                    {index < luggages.length - 1 ? ", " : ""}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div>Travelling without luggage</div>
                        )}
                        <div className="text-sm font-medium my-2">
                          Luggage information
                        </div>
                        <div>N/A</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <div className="text-sm font-medium ">
                          Travel date & time
                        </div>
                        <div>
                          {moment(rowData?.created_at).format(
                            "Do MMM YYYY hh:mm a"
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-medium my-1">
                          Amount paid
                        </div>
                        <div>
                          {rowData?.currency ?? "N/A"}{" "}
                          {rowData?.amount_payable ?? "N/A"}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-medium my-2">
                          Number of minors
                        </div>
                        <div>{rowData?.minors ?? "N/A"}</div>
                      </div>
                      <div>
                        <div className="text-sm font-medium my-2">
                          Additional information
                        </div>
                        <div>N/A</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between gap-20 mt-4">
                    <div className="">
                      <div className="text-md font-bold my-1">
                        Personal Details
                      </div>
                      <div>
                        <div className="text-sm font-medium my-2">Name</div>
                        <div>{rowData?.creator?.name ?? "N/A"}</div>
                      </div>
                      <div>
                        <div className="text-sm font-medium my-2">
                          Phone number
                        </div>
                        <div>{rowData?.creator?.phone ?? "N/A"}</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="text-sm font-medium  mt-8">
                        Emergency Phone number
                      </div>
                      <div>N/A</div>
                    </div>
                  </div>
                </div>
              ) : (
                "N/A"
              )}
            </div>
            <div className="mx-auto my-auto ">
              {disableNext ? (
                <div className="w-16"></div>
              ) : (
                <div className="px-3">
                  <IconButton data-testid="nextLoadingPoint" onClick={onNext}>
                    <ChevronRight />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflow: "auto",
};

export default LoadingPointModal;
