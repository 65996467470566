import { useState, useEffect, useMemo } from "react";
import PageLayout from "../components/layouts/PageLayout";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import { auth_api_call } from "../utils/api";
import {
  ArrowDropDownOutlined,
  EditOutlined,
  BlockOutlined,
  Verified,
  Block,
  SearchOutlined,
} from "@mui/icons-material";
import CustomDatePicker from "./bookings/customDatePicker";

import moment from "moment";
import type { MenuProps } from "antd";
import { Dropdown, Space, Spin, notification, Input } from "antd";
import { Account } from "../types";
import { stringToBase64 } from "../utils/functions";
import { Value } from "react-phone-number-input";
import EditProfile from "../components/Accounts/EditProfile";
import BlockUser from "../components/Accounts/BlockUser";
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from "date-fns";

import { RouteConstants } from "../utils/helpers/RouteConstants";
import { E164Number } from "libphonenumber-js/types";

const Accounts = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [account, setAccount] = useState<Account[]>([]);
  const [loading, setLoading] = useState(false);
  const [editProfileModal, showEditProfileModal] = useState(false);
  const [blockProfileModal, showBlockProfileModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Account | any>();
  const [submitting, setSubmitting] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const [user_name, setUserName] = useState<string | null>("");
  const [phone_num, setUserPhone] = useState<Value | undefined>("");
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfWeek(new Date()),
    endOfWeek(new Date()),
  ]);

  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates(null);
    } else if (selectedDate instanceof Array && selectedDate?.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
      setIsFiltered(true);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      //setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
    setIsFiltered(true);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",

      label: (
        <button
          data-testid="editProfile"
          onClick={() => {
            showEditProfileModal(true);
          }}
        >
          Edit Profile
        </button>
      ),
      icon: (
        <EditOutlined
          sx={{
            color: "#7c0000",
          }}
        />
      ),
    },

    {
      key: "2",
      label: (
        <button
          data-testid="block-unblock"
          onClick={() => {
            showBlockProfileModal(true);
          }}
        >
          Block/Unblock Account
        </button>
      ),
      icon: (
        <BlockOutlined
          sx={{
            color: "#7c0000",
          }}
        />
      ),
    },
  ];

  const columns = useMemo<MRT_ColumnDef<Account>[]>(
    () => [
      {
        accessorFn: (row) =>
          `${row?.first_name} ${row?.middle_name || ""} ${row?.last_name}`,

        header: "Name",
      },
      {
        accessorFn: (row) => `${row?.phone}`,
        header: "Phone number",
      },
      {
        accessorFn: (row) => `${row?.gender}`,
        Cell: ({ cell }: any) => (
          <Box>
            {cell.getValue() === "null"
              ? "N/A"
              : cell.getValue() === "M"
              ? "Male"
              : "Female"}
          </Box>
        ),
        size: 80,
        header: "Gender",
      },
      {
        accessorFn: (row) => `${row?.ice1_phone}`,

        header: "ICE 1 Phone",
      },
      {
        accessorFn: (row) => `${row?.ice2_phone}`,
        Cell: ({ cell }: any) => (
          <Box>{cell.getValue() === "null" ? "N/A" : cell.getValue()}</Box>
        ),
        header: "ICE 2 Phone",
      },
      {
        accessorFn: (row) => `${row?.verified}`,
        Cell: ({ cell }: any) => (
          <Box>
            {cell.getValue() === "true" ? (
              <div className="flex gap-1 items-center">
                <div className="text-lime-500">
                  <Verified
                    sx={{
                      width: "23px",
                      height: "23px",
                    }}
                  />
                </div>
                <div>Yes</div>
              </div>
            ) : (
              <div className="flex gap-1 items-center">
                <div className="text-gray-300">
                  <Verified
                    sx={{
                      width: "23px",
                      height: "23px",
                    }}
                  />
                </div>
                <div>No</div>
              </div>
            )}
          </Box>
        ),
        header: "Verified",
        size: 80,
      },
      {
        accessorFn: (row) => `${row?.blocked}`,
        Cell: ({ cell }: any) => (
          <Box>
            {cell.getValue() === "true" ? (
              <div className="flex gap-1 items-center">
                <div className="text-red-600">
                  <Block
                    sx={{
                      width: "23px",
                      height: "23px",
                    }}
                  />
                </div>
                <div>Yes</div>
              </div>
            ) : (
              <div className="flex gap-1 items-center">
                <div className="text-gray-300">
                  <Block
                    sx={{
                      width: "23px",
                      height: "23px",
                    }}
                  />
                </div>
                <div>No</div>
              </div>
            )}
          </Box>
        ),
        header: "Blocked",
        size: 80,
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell?.getValue()).format("LL")}</Box>
        ),
        header: "Registration Date",
        // sortingFn: "datetime",
        enableSorting: true,
        sorting: [
          {
            id: "row.created_at",
            desc: true,
          },
        ],
      },
      {
        id: "more",
        header: "",
        accessorFn: (row) => `${row?.id}`,
        Cell: ({ cell, row }) => (
          <Box>
            <Dropdown
              trigger={["click"]}
              menu={{
                items,
                onChange: (e) => {
                  //console.log('value being set', e);
                  setSelectedUser(e);
                },
              }}
              placement="bottomRight"
            >
              <button
                data-testid="moreAccounts"
                onClick={(e) => {
                  //console.log('value in more', e);
                  e.preventDefault();
                }}
              >
                <Space>
                  More
                  <ArrowDropDownOutlined />
                </Space>
              </button>
            </Dropdown>
          </Box>
        ),
        size: 80,
      },
    ],

    // eslint-disable-next-line
    []
  );

  const updateProfile = async (
    values: any,
    ice1_phone: any,
    ice2_phone: any,
    birthDate: string,
    gender: string
  ) => {
    try {
      let formatIce1Phone = ice1_phone?.replace(/\s+/g, "");
      let formatIce2Phone = ice2_phone?.replace(/\s+/g, "");

      setSubmitting(true);
      await auth_api_call.put(
        `${RouteConstants.ACCOUNTS}/${selectedUser?.id}/update`,
        {
          ...values,
          birthdate: birthDate,
          ice1_phone: formatIce1Phone,
          ice2_phone: formatIce2Phone,
          gender,
        }
      );
      setSubmitting(false);
      showEditProfileModal(false);
      notification.success({
        message: "Account profile updated",
      });
      getAccounts(null, undefined);
    } catch (e: any) {
      setSubmitting(false);
      notification.error({
        message: e?.response?.data?.message,
      });
    }
  };

  const getAccounts = async (name: string | null, phone: Value | undefined) => {
    setLoading(true);
    let defaultFilters = [];

    if (defaultDates) {
      defaultFilters.push({
        f: "created_at",
        o: "between",
        p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
      });
    }

    const nameFilters: any = [
      {
        f: "first_name",
        o: "contains",
        p: [`${name}`],
        c: "OR",
      },
      {
        f: "last_name",
        o: "contains",
        p: [`${name}`],
      },
    ];

    const phoneFilters: any = [
      {
        f: "phone",
        o: "contains",
        p: [`${phone}`],
      },
    ];

    const base64encoded = stringToBase64(phoneFilters);

    try {
      const res = await auth_api_call.get(
        `${RouteConstants.ACCOUNTS}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc&filters=${
          name
            ? JSON.stringify(nameFilters)
            : phone
            ? base64encoded
            : JSON.stringify(defaultFilters)
        }`
      );
      setAccount(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  const getAccountsByName = async (name: string | null) => {
    setLoading(true);
    let defaultFilters = [
      {
        f: "created_at",
        o: "between",
        p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
      },
    ];

    const nameFilters: any = [
      {
        f: "first_name",
        o: "contains",
        p: [`${name}`],
        c: "OR",
      },
      {
        f: "last_name",
        o: "contains",
        p: [`${name}`],
      },
    ];

    try {
      const res = await auth_api_call.get(
        `${RouteConstants.ACCOUNTS}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc&filters=${
          name ? JSON.stringify(nameFilters) : JSON.stringify(defaultFilters)
        }`
      );
      setAccount(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  const getAccountsByNumber = async (phone: Value | undefined) => {
    setLoading(true);
    let defaultFilters = [
      {
        f: "created_at",
        o: "between",
        p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
      },
    ];

    const phoneFilters: any = [
      {
        f: "phone",
        o: "contains",
        p: [`${phone}`],
      },
    ];

    try {
      const res = await auth_api_call.get(
        `${RouteConstants.ACCOUNTS}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc&filters=${
          // name
          //     ? JSON.stringify(nameFilters)
          phone ? JSON.stringify(phoneFilters) : JSON.stringify(defaultFilters)
        }`
      );
      setAccount(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };
  const handleSearchInput = (value: string | E164Number) => {
    setSearchInput(value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearchInput(searchInput);

      if (typeof searchInput === "string") {
        const phoneRegex = /^\d+$/;
        if (phoneRegex.test(searchInput)) {
          getAccountsByNumber(searchInput);
          setUserPhone(searchInput as E164Number);
        } else {
          getAccountsByName(searchInput);
          setUserName(searchInput);
        }
      }
    }
  };

  const resetFilters = async () => {
    setSearchInput("");
    setIsFiltered(false);
    setUserPhone("");
    setUserName("");
    setDefaultDates([startOfWeek(new Date()), endOfWeek(new Date())]);
    setPagination({ pageIndex: 0, pageSize: 12 });
    setMeta({ offset: 0, page: 1, limit: 12, total_pages: null, total: 0 });
  };

  useEffect(() => {
    getAccounts(user_name, phone_num);

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, defaultDates]);

  return (
    <PageLayout>
      <Spin spinning={submitting} className="z-[1000]">
        <EditProfile
          user={selectedUser}
          isOpen={editProfileModal}
          handleClosed={() => showEditProfileModal(false)}
          handleSubmitting={(loading) => {
            setSubmitting(loading);
          }}
          updateProfile={updateProfile}
        />
        <BlockUser
          isOpen={blockProfileModal}
          handleClosed={() => showBlockProfileModal(false)}
          userName={selectedUser?.name}
          userId={selectedUser?.id}
          isBlocked={selectedUser?.blocked}
        />
        <div className="w-full overflow-auto mb-8 relative">
          <div className="col-span-3 mx-4 my-6 h-full">
            <div className="flex justify-between items-center">
              <h3 className="font-bold py-2 text-2xl">Accounts</h3>

              {/* <input placeholder='Search by name' className='h-[40px] border-[1px] rounded-[5px] border-gray-500 px-3 focus:outline-none w-[300px]' value={userName} onChange={(e) => setUserName(e.target.value)} /> */}
            </div>

            <div className="shadow border bg-white p-3 rounded-md w-full my-4 flex justify-between">
              <div></div>
              <div className="flex flex-row items-center ">
                <div>
                  <Input
                    size="large"
                    value={searchInput}
                    onChange={(e) => {
                      handleSearchInput(e.target.value);
                    }}
                    placeholder="Search with name or number"
                    prefix={<SearchOutlined />}
                    onKeyDown={handleKeyDown}
                    className="sm:w-auto md:w-80 m-4"
                  />
                </div>

                <div className="flex gap-2 items-center m-2">
                  <div className="mr-3">
                    <CustomDatePicker
                      defaultDates={defaultDates}
                      handleDateChange={handleCalendarChange}
                      placement="bottom"
                    />
                  </div>
                </div>
                <div
                  className={`${
                    isFiltered ? "text-oya-ghana-red" : ""
                  } ml-2 mr-20 text-16px cursor-pointer `}
                  onClick={() => resetFilters()}
                >
                  Clear Filters
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 mt-3 mx-4">
            <div className="w-full h-full mb-8">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "0",
                  },
                }}
                data={account}
                enableTopToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                manualPagination
                columns={columns}
                rowCount={meta?.total}
                state={{ pagination, isLoading: loading }}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                }}
                enableColumnResizing
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    //console.log(row.original);
                    setSelectedUser(row.original);
                    // func.redirect(`/hsh/${row.original?.id}`);
                    //redirect(`/branch/${row.original?.id}`);
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                onPaginationChange={setPagination}
              />
            </div>
          </div>
        </div>
      </Spin>
    </PageLayout>
  );
};

export default Accounts;
