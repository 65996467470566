import React, { useState } from "react";
import { Modal, Spin, Form, Input, notification } from "antd";
import { Typography } from "@mui/material";
import CustomButton from "./CustomButton";
import { Button } from "@material-tailwind/react";
import { base_api_request } from "../utils/api";
import { RouteConstants } from "../utils/helpers/RouteConstants";

type ModalProps = {
    isOpen: boolean;
    handleClosed: () => void;
};

const { TextArea } = Input;

const EditLuggageSize = ({ isOpen, handleClosed }: ModalProps) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    const luggageSize: any = localStorage.getItem("lsz");
    const luggageObj = JSON.parse(luggageSize);

    const submit = async () => {
        form.validateFields(["code", "name", "description"])
            .then(async (values) => {
                setSubmitting(true);
                try {
                    await base_api_request.put(
                        `${RouteConstants.LUGGAGE_SIZE}/${values?.code}`,
                        {
                            ...values,
                        }
                    );
                    notification.success({
                        message: "Luggage size successfully edit",
                    });
                } catch (e: any) {
                    notification.error({
                        message: e?.response?.data?.message || e?.message,
                    });
                } finally {
                    setSubmitting(false);
                }
            })
            .catch((e) => {
                notification.info({
                    message: "Enter missing fields",
                });
            });
    };

    //   React.useEffect(() => {
    //     if (luggageObj) {
    //       form.setFieldValue("code", luggageObj?.code);
    //       form.setFieldValue("name", luggageObj?.name);
    //       form.setFieldValue("description", luggageObj?.description);
    //     }
    //   }, []);

    return (
        <Modal
            open={isOpen}
            onCancel={() => {
                handleClosed();
                localStorage.removeItem("lsz");
            }}
            footer={null}
        >
            <Spin spinning={submitting}>
                <div>
                    <Typography variant="h5">Edit luggage size</Typography>
                    <Form
                        form={form}
                        className="mt-5"
                        layout="vertical"
                        autoComplete="none"
                    >
                        <Form.Item
                            initialValue={luggageObj?.code}
                            name="code"
                            label="Size unique code"
                        >
                            <Input className="h-[48px]" readOnly />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label="Size name"
                            initialValue={luggageObj?.name}
                            rules={[
                                {
                                    message: "This field is required",
                                    required: true,
                                },
                            ]}
                        >
                            <Input className="h-[48px]" />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Description"
                            initialValue={luggageObj?.description}
                            rules={[
                                {
                                    message: "This field is required",
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>

                        <div className="flex w-full justify-end items-end mt-10">
                            <div className="flex items-center gap-3">
                                <CustomButton
                                    data-testid="continueNewLuggageSize"
                                    disabled={submitting}
                                    onClick={submit}
                                >
                                    Continue
                                </CustomButton>
                                <Button
                                    data-testid="closeNewLuggage"
                                    disabled={submitting}
                                    variant="outlined"
                                    size="sm"
                                    onClick={() => {
                                        handleClosed();
                                        localStorage.removeItem("lsz");
                                    }}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Spin>
        </Modal>
    );
};

export default EditLuggageSize;
