import { useState, useEffect, useMemo } from "react";
import PageLayout from "../components/layouts/PageLayout";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box, ListItemIcon, MenuItem } from "@mui/material";
import { base_api_request } from "../utils/api";
// import { redirect } from '../utils/functions';
import { PencilIcon } from "@heroicons/react/24/outline";

import { Region } from "../types";
import moment from "moment";
import CustomButton from "../components/CustomButton";
import { AddOutlined } from "@mui/icons-material";
import CreateRegion from "../components/Regions/CreateRegion";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import EditRegion from "../components/Regions/EditRegion";

const Regions = () => {
  //   const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [regions, setRegions] = useState<Region[]>([]);
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();

  const handleClosed = () => {
    setOpenCreate(false);
  };

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const columns = useMemo<MRT_ColumnDef<Region>[]>(
    () => [
      //   {
      //     id: 'icons',
      //     header: '',
      //     Cell: () => (
      //       <Box>
      //         <LaunchOutlined
      //           sx={{
      //             width: 20,
      //             height: 20,
      //             color: '#7C0000',
      //           }}
      //         />
      //       </Box>
      //     ),
      //     size: 50,
      //   },
      {
        accessorFn: (row) => `${row?.code}`,

        header: "Region code",
      },
      {
        accessorFn: (row) => `${row?.name}`,

        header: "Region name",
      },
      {
        accessorFn: (row) => `${row?.supported}`,
        header: "Supported",
        size: 80,
        Cell: ({ cell }: any) => (
          <Box>{cell.getValue() === "true" ? "Yes" : "No"}</Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Date created",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell.getValue()).format("MMM DD, YYYY")}</Box>
        ),
      },
    ],
    []
  );

  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  const getRegions = async () => {
    setLoading(true);

    try {
      const res = await base_api_request.get(
        `${RouteConstants.ALL_REGIONS}?limit=${
          pagination.pageSize
        }&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&sorting=created_at:desc`
      );
      setRegions(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getRegions();
    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, reFetch]);

  return (
    <>
      <PageLayout>
        <div className="w-full overflow-auto mb-8 relative">
          <div className="col-span-3 mx-4 my-6 h-full">
            <div className="flex justify-between items-center mb-5">
              <h3 className="font-bold py-2 text-2xl">Regions</h3>
              <CustomButton
                data-testid="createRegion"
                onClick={() => setOpenCreate(true)}
              >
                <div className="flex gap-1 text-white items-center">
                  <AddOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <div>Create Region</div>
                </div>
              </CustomButton>
            </div>
            <div className="grid grid-cols-1">
              <div className="w-full h-full mb-8">
                <MaterialReactTable
                  muiTablePaperProps={{
                    sx: {
                      borderRadius: "0",
                    },
                  }}
                  enableRowActions={true}
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                      key={1}
                      onClick={() => {
                        //navigate(`/schedule/${row.original?.id}/details`);
                        setSelectedItem(row.original);
                        setShowEdit(true);
                        closeMenu();
                      }}
                      sx={{ m: 0 }}
                    >
                      <ListItemIcon>
                        <PencilIcon className="w-5 h-5 text-oya-ghana-red" />
                      </ListItemIcon>
                      Edit Region
                    </MenuItem>,
                  ]}
                  data={regions}
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableMultiRowSelection={false}
                  enableRowSelection={false}
                  manualPagination
                  enableSorting={false}
                  columns={columns}
                  rowCount={meta?.total}
                  state={{ pagination, isLoading: loading }}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                    columnPinning: {
                      left: ["mrt-row-expand", "mrt-row-select"],
                      right: ["mrt-row-actions"],
                    },
                  }}
                  enableColumnResizing
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      console.log(row.original);
                      //redirect(`/branch/${row.original?.id}`);
                    },
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  onPaginationChange={setPagination}
                />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>

      <EditRegion
        handleClosed={() => setShowEdit(false)}
        handleReFetch={() => getRegions()}
        dataToEdit={selectedItem}
        isEditOpened={showEdit}
      />

      {/* add modal */}
      {openCreate ? (
        <CreateRegion
          isOpened={openCreate}
          handleClosed={handleClosed}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Regions;
