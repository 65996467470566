import { useState, useEffect, useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { Box, ListItemIcon, MenuItem } from "@mui/material";
import { LoadingPoint } from "../../types";
import CustomButton from "../../components/CustomButton";
import { AddOutlined } from "@mui/icons-material";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { base_api_request } from "../../utils/api";
import PageLayout from "../../components/layouts/PageLayout";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import AddLoadingPointPriceModal from "./AddLoadingPointPriceModal";
import LoadingPointFilter from "../../components/Branches/LoadingPointPriceFilter";
import { PencilIcon } from "@heroicons/react/24/outline";
import EditLoadingPointPrice from "../../components/Branches/EditLoadingPointPrice";

interface Filter {
  f: string;
  o: string;
  p: any[];
  c?: string;
}

const LoadingPointPrices = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading_point, setLoadingPoint] = useState<LoadingPoint[]>([]);
  const [reFetch, setReFetch] = useState(false);
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const loadingPointColumn = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorFn: (row) =>
          `${row?.route?.from?.name} - ${row?.route?.to?.name}`,
        header: "Route",
      },
      {
        accessorFn: (row) =>
          `${row?.loading_point?.from?.name} - ${
            row?.loading_point?.to?.name
          } ${
            row?.loading_point?.label ? `(${row?.loading_point?.label})` : ""
          }`,
        Cell: ({ cell }: { cell: any }) => (
          <Box>{cell.getValue() === "null" ? "N/A" : cell.getValue()}</Box>
        ),
        header: "Loading Point",
        minSize: 300,
      },
      {
        accessorFn: (row) => `GHS${row?.price}`,
        header: "Price",
      },
      {
        accessorFn: (row) => `${row?.branch?.name}`,
        header: "Branch Name",
      },
      {
        accessorFn: (row) => `${row?.created_at}`,
        header: "Date Added",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell.getValue()).format("LL")}</Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.updated_at}`,
        header: "Date Modified",
        Cell: ({ cell }: { cell: any }) => (
          <Box>{moment(cell.getValue()).format("LL")}</Box>
        ),
      },
    ],
    []
  );

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  const getLoadingPoints = async () => {
    setLoading(true);

    let filters: Filter[] = [];

    if (selectedBranch) {
      filters.push({
        f: "branch.id",
        o: "=",
        p: [Number(selectedBranch)],
      });
    }

    try {
      const res = await base_api_request.get(
        `${
          RouteConstants.ALL_LOADING_POINT_PRICES
        }?sorting=created_at:desc&limit=${pagination.pageSize}&offset=${Number(
          pagination.pageIndex * pagination.pageSize
        )}&filters=${JSON.stringify(filters)}`
      );
      setLoadingPoint(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    // getDetails();
    getLoadingPoints();

    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize, reFetch, selectedBranch]);

  return (
    <PageLayout>
      <AddLoadingPointPriceModal
        isOpen={open}
        handleClosed={() => setOpen(false)}
        handleReFetch={handleReFetch}
      />
      <EditLoadingPointPrice
        dataToEdit={selectedItem}
        handleClosed={() => setShowEdit(false)}
        handleReFetch={() => {
          getLoadingPoints();
        }}
        isEditOpened={showEdit}
      />
      <div className="overflow-hidden h-screen">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4"
          }  h-full overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className=" my-4">
              <div className="flex justify-between items-center mb-3">
                <h3 className="font-bold py-2 text-2xl">
                  Loading Point Prices
                </h3>
                <div className="flex gap-3 items-center">
                  <CustomButton
                    data-testid="addNewPrice"
                    onClick={() => setOpen(true)}
                  >
                    <div className="flex gap-1 text-white items-center">
                      <AddOutlined
                        sx={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      <div>Add a New Price</div>
                    </div>
                  </CustomButton>
                  <ChevronDoubleLeftIcon
                    className={`${
                      hideFilter ? "block" : "block lg:hidden"
                    } h-5 w-5 cursor-pointer`}
                    onClick={() => handleFilterHide()}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 mb-[100px]">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "0",
                  },
                }}
                enableRowActions={true}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <MenuItem
                    key={1}
                    onClick={() => {
                      //navigate(`/schedule/${row.original?.id}/details`);
                      setSelectedItem(row.original);
                      setShowEdit(true);
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <PencilIcon className="w-5 h-5 text-oya-ghana-red" />
                    </ListItemIcon>
                    Edit LP Price
                  </MenuItem>,
                ]}
                data={loading_point}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                enableTopToolbar={false}
                manualPagination
                enableSorting={false}
                columns={loadingPointColumn}
                rowCount={meta?.total}
                state={{ pagination, isLoading: loading }}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                  columnPinning: {
                    left: ["mrt-row-expand", "mrt-row-select"],
                    right: ["mrt-row-actions"],
                  },
                }}
                enableColumnResizing
                onPaginationChange={setPagination}
              />
            </div>
          </div>
          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full overflow-y-auto`}
          >
            <LoadingPointFilter
              onHide={handleFilterHide}
              handleBranchChange={(value) => {
                setSelectedBranch(value);
              }}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default LoadingPointPrices;
