import { useState, useEffect } from "react";
import { Modal, Spin, notification } from "antd";
import debounce from "lodash/debounce";
import { auth_api_call, base_api_request } from "../../utils/api";
import { RouteConstants } from "../../utils/helpers/RouteConstants";
import Select from "react-select";
import { useParams } from "react-router-dom";

interface modalProps {
    isOpened: boolean;
    handleClose: () => void;
    handleReFetch: () => void;
}

const AddBranchStaff = ({
    isOpened,
    handleClose,
    handleReFetch,
}: modalProps) => {
    const { id } = useParams();
    const [isBusy, setIsBusy] = useState(false);

    const [fetchingStaff, setFetchingStaff] = useState(false);
    const [allStaff, setAllStaff] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState<any>(null);

    const [allAccountTypes, setAllAccountTypes] = useState([]);
    const [fetchingAcc, setFetchingAcc] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState<any>(null);

    //fetch staff
    const fetchStaff = debounce((inputValue: string) => {
        if (inputValue) {
            setFetchingStaff(true);

            const defaultFilter = [
                {
                    f: "user.name",
                    o: "contains",
                    p: [inputValue],
                },
            ];

            base_api_request
                .get(
                    `${RouteConstants.ALL_STAFF}?filters=${JSON.stringify(
                        defaultFilter
                    )}`
                )
                .then((res) => {
                    // console.log(res?.data?.payload?.items);
                    const rawData = res?.data?.payload?.items;
                    const arranged = rawData.map((staff: any) => ({
                        label: staff?.user?.name,
                        value: staff?.user?.id,
                    }));
                    setAllStaff(arranged);
                    setFetchingStaff(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, 500);

    //fetch staff
    const fetchAccountTypes = async () => {
        setFetchingAcc(true);

        try {
            const res = await auth_api_call.get(
                `${RouteConstants.ACCOUNT_TYPES}`
            );
            // console.log(res?.data?.payload?.items);
            const rawData = res?.data?.payload?.items;
            const arranged = rawData.map((item: any) => ({
                label: item?.name,
                value: item?.id,
            }));
            setAllAccountTypes(arranged);
            setFetchingAcc(false);
        } catch (error) {
            console.log(error);
        }
    };

    //onload
    useEffect(() => {
        fetchAccountTypes();
    }, []);

    //handleSubmit
    const handleSubmit = () => {
        if (!selectedStaff) {
            notification.warning({ message: "Please select a user" });
        } else if (!selectedAccount) {
            notification.warning({ message: "Please select a role" });
        } else {
            setIsBusy(true);
            let data = {
                user_id: selectedStaff,
                account_type_id: selectedAccount,
            };
            base_api_request
                .post(`${RouteConstants.ALL_BRANCHES}/${id}/staff`, data)
                .then((response) => {
                    // console.log(response)
                    handleClose();
                    notification.success({
                        message: "Staff was added successfully",
                    });
                    handleReFetch();
                })
                .catch((error: any) => {
                    console.log(error);
                    setIsBusy(false);
                    notification.error({
                        message: error?.response?.data?.message,
                    });
                });
        }
    };

    return (
        <>
            <Modal
                title="Add staff member to branch"
                open={isOpened}
                onCancel={handleClose}
                footer={null}
                maskClosable={false}
            >
                <Spin spinning={isBusy}>
                    <div>
                        <small>Search staff by name</small>
                        <Select
                            className="basic-single"
                            placeholder="Search"
                            isLoading={fetchingStaff}
                            isClearable={true}
                            isSearchable={true}
                            options={allStaff}
                            onInputChange={(val: string) => fetchStaff(val)}
                            onChange={(selected: any) =>
                                setSelectedStaff(selected?.value)
                            }
                        />
                    </div>

                    <div className="mt-4">
                        <small>Select user role</small>
                        <Select
                            className="basic-single"
                            placeholder="Select"
                            isLoading={fetchingAcc}
                            isClearable={true}
                            isSearchable={true}
                            options={allAccountTypes}
                            onChange={(selected: any) =>
                                setSelectedAccount(selected?.value)
                            }
                        />
                    </div>

                    <hr className="my-4" />
                    <div className="flex justify-end gap-4">
                        <button
                            data-testid="cancelAddBranchStaff"
                            className=""
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            data-testid="addBranchStaffSubmit"
                            className="px-4 py-2 bg-oya-ghana-red text-white rounded-[5px]"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </Spin>
            </Modal>
        </>
    );
};

export default AddBranchStaff;
