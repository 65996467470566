import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import PageNotFound from "./pages/PageNotFound";
import Main from "./pages/Main";
import Branches from "./pages/Branches";
import Companies from "./pages/Companies";
import Incidents from "./pages/Incidents";
import TripRoutes from "./pages/TripRoutes";
import BranchDetails from "./pages/BranchDetails";
import Schedules from "./pages/branches/Schedules";
import StaffList from "./pages/branches/Staff";
import ProtectedRoute from "./routes/router";
import Towns from "./pages/Towns";
import { ConfigProvider } from "antd";
import LoadingPointsList from "./pages/branches/LoadingPointsList";
import Accounts from "./pages/Accounts";
import "react-phone-number-input/style.css";
import AllBookingsList from "./pages/bookings/bookingsList";
import Tickets from "./pages/Tickets";
import Regions from "./pages/Regions";
import AttendanceList from "./pages/branches/AttendanceList";
import Buses from "./pages/Buses";
import Midroutes from "./pages/Midroutes";
import PickupPoints from "./pages/branches/PickupPoints";
import Campaign from "./components/Adverts/Campaign";
import AddCampaign from "./components/Adverts/AddCampaign";
import "./styles/styles.scss";
import LoadingPointPrices from "./pages/branches/LoadingPointPrices";
import LuggageSizes from "./pages/LuggageSizes";
import LoadingPointTime from "./pages/branches/LoadingPointTime";
import LoadingPointBookings from "./pages/branches/LoadingPointBookings";
import ScheduleDetails from "./pages/branches/ScheduleDetails";
import BusTypes from "./pages/BusTypes";
import BusFeatures from "./pages/BusFeatures";
import Features from "./pages/Features";

function App() {
  return (
    <ConfigProvider>
      <ProSidebarProvider>
        <Router>
          <Routes>
            <Route path="/*" element={<PageNotFound />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Main />
                </ProtectedRoute>
              }
            />
            <Route
              path="/branches"
              element={
                <ProtectedRoute>
                  <Branches />
                </ProtectedRoute>
              }
            />
            <Route
              path="/towns"
              element={
                <ProtectedRoute>
                  <Towns />
                </ProtectedRoute>
              }
            />
            <Route
              path="/accounts"
              element={
                <ProtectedRoute>
                  <Accounts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/schedule/:id/details"
              element={
                <ProtectedRoute>
                  <ScheduleDetails />
                </ProtectedRoute>
              }
            />
            <Route path="/branch/">
              <Route
                path=":id"
                element={
                  <ProtectedRoute>
                    <BranchDetails />
                  </ProtectedRoute>
                }
              />

              <Route
                path="staff/:id"
                element={
                  <ProtectedRoute>
                    <StaffList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="loading-points/:id"
                element={
                  <ProtectedRoute>
                    <LoadingPointsList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="attendance/:id"
                element={
                  <ProtectedRoute>
                    <AttendanceList />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="/companies"
              element={
                <ProtectedRoute>
                  <Companies />
                </ProtectedRoute>
              }
            />
            <Route
              path="/buses"
              element={
                <ProtectedRoute>
                  <Buses />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bus-types"
              element={
                <ProtectedRoute>
                  <BusTypes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bus-features"
              element={
                <ProtectedRoute>
                  <BusFeatures />
                </ProtectedRoute>
              }
            />
            <Route
              path="/features"
              element={
                <ProtectedRoute>
                  <Features />
                </ProtectedRoute>
              }
            />
            <Route
              path="/incidents"
              element={
                <ProtectedRoute>
                  <Incidents />
                </ProtectedRoute>
              }
            />
            <Route
              path="/schedules"
              element={
                <ProtectedRoute>
                  <Schedules />
                </ProtectedRoute>
              }
            />
            <Route
              path="/staff"
              element={
                <ProtectedRoute>
                  <StaffList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/loading-points"
              element={
                <ProtectedRoute>
                  <LoadingPointsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/loading-point-bookings"
              element={
                <ProtectedRoute>
                  <LoadingPointBookings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/loading-point-time"
              element={
                <ProtectedRoute>
                  <LoadingPointTime />
                </ProtectedRoute>
              }
            />
            <Route
              path="/luggage-sizes"
              element={
                <ProtectedRoute>
                  <LuggageSizes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/loading-point-prices"
              element={
                <ProtectedRoute>
                  <LoadingPointPrices />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pickup-points"
              element={
                <ProtectedRoute>
                  <PickupPoints />
                </ProtectedRoute>
              }
            />
            <Route
              path="/midroutes"
              element={
                <ProtectedRoute>
                  <Midroutes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/routes"
              element={
                <ProtectedRoute>
                  <TripRoutes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/advanced-bookings"
              element={
                <ProtectedRoute>
                  <AllBookingsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tickets"
              element={
                <ProtectedRoute>
                  <Tickets />
                </ProtectedRoute>
              }
            />
            <Route
              path="/regions"
              element={
                <ProtectedRoute>
                  <Regions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/campaign"
              element={
                <ProtectedRoute>
                  <Campaign />
                </ProtectedRoute>
              }
            />
            <Route
              path="/AddCampaign"
              element={
                <ProtectedRoute>
                  <AddCampaign />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </ProSidebarProvider>
    </ConfigProvider>
  );
}

export default App;
