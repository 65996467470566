import { ApiResponse } from "../../types";
import { AxiosResponse } from "axios";
import { auth_api_call, base_api_request } from "../api";
import { RouteConstants } from "./RouteConstants";

export const getBranches = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await base_api_request.get(
      RouteConstants.ALL_BRANCHES
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};

export const getCompanies = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await base_api_request.get(
      RouteConstants.ALL_COMPANIES
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};

export const getRegions = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await base_api_request.get(
      RouteConstants.ALL_REGIONS
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};

export const getBuses = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await base_api_request.get(
      RouteConstants.BUSES
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};

export const getRoutes = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await base_api_request.get(
      RouteConstants.ROUTES
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};

export const getCompanyTypes = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await base_api_request.get(
      RouteConstants.COMPANY_TYPES
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};

export const getLoadingPoints = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await base_api_request.get(
      RouteConstants.ALL_LOADING_POINTS
    );
    //console.log(response.data);

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};

export const getPickupPoints = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await base_api_request.get(
      RouteConstants.ALL_PICKUP_POINTS
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};

export const getAllUsers = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await auth_api_call.get(
      RouteConstants.ACCOUNTS
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};

export const getPorters = async (): Promise<ApiResponse> => {
  const defaultFilter: any = [
    {
      f: "account_type.code",
      o: "=",
      p: ["PORTER"],
    },
  ];
  try {
    const response: AxiosResponse<ApiResponse> = await base_api_request.get(
      `${RouteConstants.ALL_STAFF}?filters=${JSON.stringify(defaultFilter)}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data);
  }
};
