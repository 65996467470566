import { useState } from "react";
import { base_api_request } from "../../utils/api";
import Select from "react-select";
import debounce from "lodash/debounce";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

interface selectProps {
  onChange?: (data: any) => void;
}

const FieldOfficerSelector = ({ onChange }: selectProps) => {
  const [loading, setLoading] = useState(false);
  const [officerData, setOfficerData] = useState([]);

  //getAllStaff
  const getAllStaff = debounce((searchVal: string) => {
    if (searchVal) {
      setLoading(true);
      const defaultFilter = [
        {
          f: "user.name",
          o: "contains",
          p: [searchVal],
        },
      ];

      base_api_request
        .get(
          `${RouteConstants.ALL_STAFF}?filters=${JSON.stringify(defaultFilter)}`
        )
        .then((res: any) => {
          const rawData = res?.data?.payload?.items;
          const filteredResults = rawData.filter(
            (item: any) => item.account_type?.code === "FIELD_OFFICER"
          );

          const arranged = filteredResults.map((staff: any) => ({
            label: staff?.user?.name,
            value: staff?.user?.id,
          }));
          setOfficerData(arranged);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, 800);

  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Enter name"
        isLoading={loading}
        isClearable={true}
        options={officerData}
        onChange={onChange}
        onInputChange={(val: string) => getAllStaff(val)}
      />
    </>
  );
};

export default FieldOfficerSelector;
